import React from 'react';

const Wrapper = ({children}) => {
  return (
    <>
      {children}
    </>
  );
};

export default Wrapper;