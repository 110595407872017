import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Grid, List } from "../../../svg";
export default function CourseDisplayOptions({
  list,
  showList,
  totalPages,
  currentPage,
  hideFilter,
}) {
  const short_filter = ["Default", "Featured", "Free", "Paid", "Trending"];

  const handleChange = (e) => {};

  const handleDisplayer = (value) => {
    showList(value);
  };
  return (
    <div className="course__tab-inner grey-bg-2 mb-50">
      <Row className="align-items-center">
        <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
          <div className="course__tab-wrapper d-flex align-items-center">
            <div className="course__tab-btn">
              <ul className="nav nav-tabs" id="courseTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <Button
                    className={`nav-link ${!list && "active"}`}
                    id="grid-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#grid"
                    type="button"
                    role="tab"
                    aria-controls="grid"
                    aria-selected="true"
                    onClick={() => handleDisplayer(false)}
                  >
                    <Grid />
                  </Button>
                </li>
                <li className="nav-item" role="presentation">
                  <Button
                    className={`nav-link list ${list && "active"}`}
                    id="list-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#list"
                    type="button"
                    role="tab"
                    aria-controls="list"
                    aria-selected="false"
                    onClick={() => handleDisplayer(true)}
                  >
                    <List />
                  </Button>
                </li>
              </ul>
            </div>
            <div className="course__view">
              <h4>
                Showing {currentPage} - {totalPages}
              </h4>
            </div>
          </div>
        </Col>
        {!hideFilter && (
          <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
            <div className="course__sort d-flex justify-content-sm-end">
              <div className="course__sort-inner">
                <select onChange={handleChange}>
                  {short_filter.map((category, index) => (
                    <option key={index}>{category}</option>
                  ))}
                </select>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </div>
  );
}
