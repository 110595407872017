import React from "react";
// import styled from "styled-components";
// // import { Link } from "react-router-dom";
// const H2 = styled.div``;

import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";

import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import ContentDashboard from "../../../layout/layer/Layer";
// import AvatarUploader from "../../../components/common/avatar/upload.avatar";
import AccountForm from "../../../components/common/forms/account-form";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import ResetPassword from "../../../components/common/forms/reset-form";

const SettingsUser = () => {
  return (
    <Wrapper>
      <Header />
      <section className=" po-rel-z1 pt-130 pb-145">
        <SidebarTeacher />
        <ContentDashboard>
          <h2>Settings</h2>
          <div style={{ width: "100%", margin: "auto", padding: "30px" }}>
            <Tabs
              defaultActiveKey="account"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="account" title="Account">
                <Row>
                  <Col md="7">
                    <AccountForm />
                  </Col>
                  <Col md="5">
                    <img
                      src="/assets/img/accounts.svg"
                      style={{ width: "100%", padding: "100px" }}
                    />
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="password" title="Password">
                <Row>
                  <Col md="7">
                    <ResetPassword />
                  </Col>
                  <Col md="5">
                    <img
                      src="/assets/img/password.svg"
                      style={{ width: "100%", padding: "100px" }}
                    />
                  </Col>{" "}
                </Row>
              </Tab>
            </Tabs>
          </div>
        </ContentDashboard>
      </section>
    </Wrapper>
  );
};
export default SettingsUser;
