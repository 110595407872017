import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Button,
  FormCheck,
  FormControl,
} from "react-bootstrap";

import wilia from "./wiliya.json";

import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

const validationSchema = Yup.object().shape({
  method: Yup.string().required("Please choose a method"),
  phone: Yup.string().required("Phone number is required"),
  address: Yup.string().required("Address is required"),
  wilaya: Yup.string().required("Wilaya is required"),
});

const initialValues = {
  payment_method: "CASH",
};
const CoursePayment = () => {
  return (
    <Wrapper>
      {/* <SEO pageTitle={"CoursePayment"} /> */}
      <Header />
      <div style={{ marginTop: "200px" }}>
        <FormPayment />
      </div>
    </Wrapper>
  );
};

export default CoursePayment;

const FormPayment = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [currentUser, setCurrentUser] = React.useState(null);
  // Get a specific query parameter

  function getUserAccount() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/account`, config)
      .then((res) => {
        if (
          !res.data.user.phone ||
          !res.data.user.address ||
          !res.data.user.wilaya
        ) {
          // Swal.fire({
          //   title: "Missing Information",
          //   text: "You need to add your phone number, address, or wilaya to proceed to settings.",
          //   icon: "warning",
          //   confirmButtonText: "OK",
          // }).then(() => {
          //   navigate("/dashboard/settings");
          // });
        } else {
          setCurrentUser({
            name: res.data.user.fullname,
            phone: res.data.user.phone,
            address: res.data.user.address,
            wilaya: res.data.user.wilaya,
          });
        }
        setCurrentUser({
          name: res.data.user.fullname,
          phone: res.data.user.phone,
          address: res.data.user.address,
          wilaya: res.data.user.wilaya,
        });
      });
  }

  React.useEffect(() => {
    getUserAccount();
  }, []);

  const handleFormSubmit = async (values) => {
    const courseId = searchParams.get("courseId");
    const classId = searchParams.get("classId");
    const data = {
      courseId,
      classId,
      values,
    };
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/enrolled-payment`, data, config)
      .then((res) => {
        Swal.fire({
          title: "Proof of Payment Required",
          text: "You have successfully registered for the course. Please upload proof of payment to complete the enrollment process.",
          icon: "info",
          confirmButtonText: "OK",
        }).then(() => {
          navigate(`/dashboard/payment`);
        });
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response && error.response.status === 400) {
          // Handle class member limit reached error
          Swal.fire({
            title: "Error",
            text: "Class member limit has been reached. You cannot register for this class.",
            icon: "error",
            confirmButtonText: "OK",
          }).then(() => {
            navigate(`/`);
          });
        } else {
          // Handle other errors
          //console.error("An error occurred:", error);
        }
      });
  };

  return (
    <Container>
      <h4>Fullname: {currentUser?.name}</h4>
      <h4>Phone: {currentUser?.phone}</h4>
      <h4>Address: {currentUser?.address}</h4>
      <h4>wiliya: {currentUser?.wiliya}</h4>
      <hr />
      Please Choose method of Payment:
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({ errors, touched }) => (
          <Form>
            <FormGroup
              style={{ marginTop: "10px" }}
              as={Row}
              controlId="method"
            >
              <FormLabel column sm={2}>
                Method
              </FormLabel>
              <Col sm={10}>
                <Field name="method">
                  {({ field }) => (
                    <>
                      <FormCheck
                        inline
                        type="radio"
                        id="methodCash"
                        label="Cash"
                        value="cash"
                        name="payment_method"
                        style={{
                          padding: "10px 20px",
                          borderRadius: "10px",
                          marginRight: "20px",
                        }}
                        {...field}
                      />
                      <FormCheck
                        inline
                        type="radio"
                        id="methodBaridiMob"
                        label="Baridi Mob Payment"
                        value="baridi_mob"
                        {...field}
                        style={{
                          padding: "10px 20px",
                          borderRadius: "10px",
                          marginRight: "20px",
                        }}
                      />
                      <FormCheck
                        inline
                        type="radio"
                        id="methodCIBPayment"
                        label="CIB Payment"
                        value="cib_payment"
                        {...field}
                        style={{
                          padding: "10px 20px",
                          borderRadius: "10px",
                          marginRight: "20px",
                        }}
                      />
                    </>
                  )}
                </Field>
                <FormControl.Feedback type="invalid">
                  {errors.method}
                </FormControl.Feedback>
              </Col>
            </FormGroup>

            <Button type="submit">Submit</Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
