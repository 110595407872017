import React from "react";
import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";

import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";

import { Row, Col } from "react-bootstrap";
import ContentDashboard from "../../../layout/layer/Layer";
import axios from "axios";
// import { FaUser, FaUsers, FaBook, FaChartBar } from "react-icons/fa"; // Import the icons you want to use
// import Chart from "react-apexcharts";
import Typography, {
  H1,
  H5,
  Paragraph,
} from "../../../components/common/Typography";
import { useTranslation } from "react-i18next";
import Grid from "../../../components/grid/Grid";
import Card from "../../../components/Card";
import MyLearningContent from "../../../components/common/course/MyLearningList";

export default function TeacherDasboard() {
  const [dashboardData, setdashboardData] = React.useState({});
  async function fetchStatics() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/status`,
      config
    );
    setdashboardData(res.data);
  }

  React.useEffect(() => {
    fetchStatics();
  }, []);

  return (
    <Wrapper>
      <Header />
      <section className=" po-rel-z1 pt-20 pb-145">
        <SidebarTeacher />
        <ContentDashboard>
          <DashboardContent data={dashboardData} />

          <h3 className="pt-20">Recently Enrolled Courses</h3>
          <MyLearningContent smallDisplay={true} />
        </ContentDashboard>
      </section>
    </Wrapper>
  );
}

const DashboardContent = ({ data }) => {
  const {
    earnings,
    totalCoursesCreated,
    totalCoursesSubscribed,
    totalEnrolledStudents,
  } = data;
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={6}>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Typography
            as={Card}
            py="1.5rem"
            height="100%"
            borderRadius={8}
            textAlign="center"
            style={{
              boxShadow:
                "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.1)", // Shadow on all sides
            }}
          >
            <H5 color="text.muted" mb="8px">
              {t("total totalCoursesCreated")}
            </H5>

            <H1 color="gray.700" mb="4px" lineHeight="1.3">
              {earnings}
            </H1>

            <Paragraph color="text.muted">Small text</Paragraph>
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Typography
            as={Card}
            py="1.5rem"
            height="100%"
            borderRadius={8}
            textAlign="center"
            style={{
              boxShadow:
                "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.1)", // Shadow on all sides
            }}
          >
            <H5 color="text.muted" mb="8px">
              {t("total courses")}
            </H5>

            <H1 color="gray.700" mb="4px" lineHeight="1.3">
              {totalCoursesCreated}
            </H1>

            <Paragraph color="text.muted">Small text</Paragraph>
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Typography
            as={Card}
            py="1.5rem"
            height="100%"
            borderRadius={8}
            textAlign="center"
            style={{
              boxShadow:
                "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.1)", // Shadow on all sides
            }}
          >
            <H5 color="text.muted" mb="8px">
              {t("total courses")}
            </H5>

            <H1 color="gray.700" mb="4px" lineHeight="1.3">
              {totalCoursesSubscribed}
            </H1>

            <Paragraph color="text.muted">Small text</Paragraph>
          </Typography>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
          <Typography
            as={Card}
            py="1.5rem"
            height="100%"
            borderRadius={8}
            textAlign="center"
            style={{
              boxShadow:
                "0px 4px 8px rgba(0, 0, 0, 0.1), 0px 4px 16px rgba(0, 0, 0, 0.1)", // Shadow on all sides
            }}
          >
            <H5 color="text.muted" mb="8px">
              {t("total courses")}
            </H5>

            <H1 color="gray.700" mb="4px" lineHeight="1.3">
              {totalEnrolledStudents}
            </H1>

            <Paragraph color="text.muted">Small text</Paragraph>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
