import React from "react";

import { Button, Card, Container } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";

import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const logo = "/assets/img/logo/logo-text-2.png";
const StyledCard = styled(Card)`
  /* Add your card styles here */
`;

const ContentWrapper = styled.div`
  text-align: center;
  font-weight: bold;
  max-width: 980px;
  margin: auto;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
`;

const Paragraph = styled.p`
  margin-top: 30px;
  margin-bottom: 50px;
  font-size: 18px;
`;

export default function SupportNotLogged() {
  const { t } = useTranslation();
  return (
    <Container>
      <Card.Body style={{ marginTop: "100px", marginBottom: "100px" }}>
        <ContentWrapper>
          <img src={logo} alt="logo nafidh" placeholder="blur" />
          <Title>{t("welcome_message")}</Title>
          <Paragraph>{t("communication_message")}</Paragraph>
          <Paragraph>{t("ticket_message")}</Paragraph>
          <div>
            <Link to="/sign-in">
              <Button variant="primary">
                <FiPlus size={24} />
                إنشاء تذكرة
              </Button>
            </Link>
          </div>
        </ContentWrapper>
      </Card.Body>
    </Container>
  );
}
