import { useEffect } from "react";
import Breadcrumb from "../../../components/common/breadcrumb/breadcrumb";
import CourseList from "../../../components/common/course/CourseList";

import SEO from "../../../components/seo";

import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import { useParams } from "react-router-dom";
import CourseDetails from "../../../components/course-details/CourseDetails";
import Footer from "../../../layout/footers/footer";
const CoursePage = () => {
  const { name } = useParams();

  return (
    <Wrapper>
      <SEO pageTitle={"Courses"} />
      <Header header_white={false} />
      <Breadcrumb
        pages={[
          { name: "Home", path: "" },
          { name: "Courses", path: "/page/courses" },
        ]}
        withoutImage={true}
        subtitle={name || "Course not found"}
      />
      <div className="container" style={{ marginTop: "100px" }}>
        <CourseDetails />
      </div>
      <Footer pt="pt-90" />
    </Wrapper>
  );
};

export default CoursePage;
