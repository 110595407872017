import {
  border,
  color,
  compose,
  flex,
  flexbox,
  layout,
  position,
  space,
  typography,
} from "styled-system";
import styled from "styled-components";

const Box = styled.div(
  ({ shadow = 0, cursor = "unset", transition, theme }) => ({
    cursor,
    transition,
    boxShadow: theme.shadows[shadow],
  }),
  compose(layout, space, color, position, flexbox, flex, border, typography)
);

export default Box;
