import { useEffect, useState } from "react";
import SEO from "../../../components/seo";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import axios from "axios";
import ErrorMsg from "../../../components/common/forms/error-msg";
import { useFormik } from "formik";
import * as Yup from "yup";

import LuctersTable from "../../../components/costomComponents/LuctersTable";
const Chapiter = () => {
  const { id, chapiterId } = useParams();
  const [chapiter, setChapiter] = useState();
  const navigate = useNavigate();
  const fetchMyCourse = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/chapiter/${chapiterId}`, config)
        .then((res) => {
          setChapiter(res.data.chapiter);
          //   if (!res.data.course) {
          //     navigate("/404");
          //   } else {
          //     console.log(res.data);
          //     setCourse(res.data.course);
          //   }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMyCourse();
  }, []);
  return (
    <Wrapper>
      <SEO pageTitle={"Course"} />
      <Header />
      <CourseInformation chapiter={chapiter} />
      <LuctersTable />
    </Wrapper>
  );
};

const CourseInformation = ({ chapiter }) => {
  const { id } = useParams();
  const [course, setCourse] = useState();
  const navigate = useNavigate();
  const fetchMyCourse = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/course/my-course/${id}`, config)
        .then((res) => {
          if (!res.data.course) {
            navigate("/404");
          } else {
            console.log(res.data);
            setCourse(res.data.course);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMyCourse();
  }, []);

  return (
    <div>
      <section className="page__title-area pt-120 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12">
              <div className="course__wrapper">
                <div className="page__title-content mb-25">
                  <span className="page__title-pre">
                    {course?.category?.name}
                  </span>
                  <h5 className="page__title-3">{course?.name}</h5>
                </div>
                {/* <div className="course__meta-2 d-sm-flex mb-30">
                  <div className="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                    <div className="course__teacher-thumb-3 mr-15">
                      <img
                        src="/assets/img/course/teacher/teacher-1.jpg"
                        alt=""
                      />
                    </div>
                    <div className="course__teacher-info-3">
                      <h5>Teacher</h5>
                      <p>
                        <a href="#">{course?.instructor?.fullname}</a>
                      </p>
                    </div>
                  </div>
                  <div className="course__update mr-80 mb-30">
                    <h5>Last Update:</h5>
                    <p>{course?.updatedAt}</p>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <h5 className="page__title-3">{chapiter?.name}</h5>
        </div>
      </section>
    </div>
  );
};

export default Chapiter;
