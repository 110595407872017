import React, { useRef } from "react";
import Wrapper from "../../../layout/wrapper";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { ListGroup, Form, ProgressBar, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Header from "../../../layout/headers/header";
import {
  useCourseDetails,
  Details,
} from "../../../components/course-details/CourseDetails";
import Footer from "../../../layout/footers/footer";
import { JaaSMeeting, JitsiMeeting } from "@jitsi/react-sdk";
import "./live.css";

export default function ClassPage() {
  const course = useCourseDetails();
  const [isLive, setIsLive] = React.useState(false);
  return (
    <>
      {!isLive && <Header header_shadow={true} />}
      <div style={{ marginTop: isLive ? "0" : "40px" }}>
        <div className={isLive ? "" : "container"}>
          <MyLearning isLive={(e) => setIsLive(e)} />

          {!isLive && (
            <Col md="12">
              <Details course={course?.data?.course || null} />{" "}
            </Col>
          )}
        </div>
      </div>
      {!isLive && <Footer pt="pt-90" />}
    </>
  );
}

function getFileType(fileName) {
  const extension = fileName.split(".").pop().toLowerCase();
  const videoExtensions = ["mp4", "avi", "mov", "mkv", "wmv"];
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
  const pdfExtensions = ["pdf"];

  if (videoExtensions.includes(extension)) {
    return "video";
  } else if (imageExtensions.includes(extension)) {
    return "image";
  } else if (pdfExtensions.includes(extension)) {
    return "pdf";
  } else {
    return "file";
  }
}

function MyLearning({ isLive }) {
  const [joinLive, setJoinLive] = React.useState(false);
  const queryClient = useQueryClient();
  const { id } = useParams();
  const {
    data: courseList,
    isLoading,
    isError,
  } = useQuery(["courseLectures", id], async () => {
    let config = {
      headers: {},
    };
    if (localStorage.getItem("jwt")) {
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      };
    }

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/enrolled-course/${id}`,
      config
    );
    return response.data;
  });

  const [lecture, setLecture] = React.useState({});
  const [lectures, setLectures] = React.useState([]);
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    if (courseList) {
      setLecture(courseList?.chapiters[0]?.lectures[0]);
      let arrayOfObjects = [];
      courseList?.chapiters.map((chapiter) => {
        arrayOfObjects.push(...chapiter.lectures);
      });
      setLectures(arrayOfObjects);

      //   console.log(courseList?.chapiters[0]?.lecture[0]?.id);
      //   setLecture(courseList.chapiters[0].lecture[0]);
    }
  }, [courseList]);

  React.useEffect(() => {
    if (lectures) {
      const result =
        lectures.filter((item) => item.isComplited).length / lectures.length;

      setProgress(((result / lectures.length) * 100).toFixed(0));
    }
  }, [lectures]);

  const handleVideoEnded = () => {
    // Find the index of the object with the given ID
    const index = lectures.findIndex((obj) => obj?.id === lecture?.id);
    setLecture(lectures[index + 1]);
  };
  const hndleLectureSelected = (value) => {
    postProgress({ value, courseId: id });
    setTimeout(() => {
      setLecture(value);
    }, 0);
  };

  async function postProgress({ value, courseId }) {
    if (getFileType(lecture?.attachement?.url) !== "video") {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      };
      const data = { lectureId: lecture.id, courseId };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/progress`,
        data,
        config
      );
      if (response.data) {
        const { chapiters } = queryClient.getQueryData([
          "courseLectures",
          courseId,
        ]);
        let updated = chapiters.map((item) => {
          item.lectures = item.lectures.map((el) => {
            if (lecture?.id === el?.id) {
              el.isComplited = true;
            }
            return el;
          });
          return item;
        });
        queryClient.setQueryData(["courseLectures", courseId], {
          chapiters: updated,
        });
      }
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }
  console.log(courseList);
  return (
    <Row>
      <Col md={!joinLive ? "9" : "12"} style={{ position: "relative" }}>
        <Row>
          <Col>
            {joinLive && (
              <Button
                style={{
                  margin: "10px 10px",
                  minWidth: "150px",
                  position: joinLive ? "absolute" : "relative",
                }}
                //className="e-btn e-btn-7 w-100 "
                onClick={(event) => {
                  setJoinLive(false);
                  isLive(false);
                }}
              >
                Courses
              </Button>
            )}
            {!joinLive && (
              <Button
                style={{ margin: "10px 10px", minWidth: "150px" }}
                variant="danger"
                className="blob red"
                onClick={() => {
                  setJoinLive(true);
                  isLive(true);
                }}
              >
                Live
              </Button>
            )}
          </Col>
        </Row>
        {joinLive ? (
          <JitsiMeeting
            //domain={"http://localhost:3000"}
            roomName="Course 123(k8s"
            configOverwrite={{
              startWithAudioMuted: true,
              disableModeratorIndicator: true,
              startScreenSharing: true,
              enableEmailInStats: false,
            }}
            interfaceConfigOverwrite={{
              DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
            }}
            //jwt={`Bearer ${localStorage.getItem("jwt")}`}
            userInfo={{
              displayName: `${localStorage.getItem("fullname")}`,
            }}
            onApiReady={(externalApi) => {
              // here you can attach custom event listeners to the Jitsi Meet External API
              // you can also store it locally to execute commands
            }}
            getIFrameRef={(iframeRef) => {
              iframeRef.style.height = "100%";

              iframeRef.style.minHeight = "calc(100vh)";
            }}
          />
        ) : (
          <>
            {getFileType(lecture?.attachement?.url || "not") === "video" ? (
              <VideoPlayer
                lecture={lecture || {}}
                handleVideoEnded={handleVideoEnded}
              />
            ) : getFileType(lecture?.attachement?.url || "nioth") ===
              "image" ? (
              <div>
                <img
                  src={
                    process.env.REACT_APP_DOMAIN_BACKEND +
                    lecture?.attachement?.url
                  }
                  style={{
                    width: "100%",
                    maxHeight: "89vh",
                    objectFit: "contain",
                  }}
                />
              </div>
            ) : getFileType(lecture?.attachement?.url || "nioth") === "pdf" ? (
              <embed
                src={
                  process.env.REACT_APP_DOMAIN_BACKEND + lecture?.lecture?.url
                }
                width="100%"
                height="500px"
              />
            ) : (
              <div>
                <p>Unknown file type</p>
                <button href={lecture?.attachement?.url} download>
                  Download
                </button>
              </div>
            )}

            {/* {getFileType(lecture?.attachement?.url) === "video" ? (
              <VideoPlayer
                lecture={lecture || {}}
                handleVideoEnded={handleVideoEnded}
              />
            ) : getFileType(lecture?.attachement?.url) === "image" ? (
              <div>
                <img src={lecture?.attachement?.url} />
              </div>
            ) : getFileType(lecture?.attachement?.url) === "pdf" ? (
              <embed src={lecture?.lecture?.url} width="100%" height="500px" />
            ) : (
              <div>
                <p>Unknown file type</p>
                <button href={lecture?.attachement?.url} download>
                  Download
                </button>
              </div>
            )} */}
            {/* {lecture.type === "video" ? (
              <VideoPlayer
                lecture={lecture || {}}
                handleVideoEnded={handleVideoEnded}
              />
            ) : lecture.type === "pdf" ? (
              <embed src={lecture.lecture_url} width="100%" height="500px" />
            ) : (
              <button> Download FIle</button>
            )} */}
          </>
        )}
      </Col>
      {!joinLive && (
        <Col md="3" style={{ padding: "0" }}>
          <div className="course__review-item d-flex align-items-center justify-content-between">
            <div className="course__review-text">
              <span>
                {lectures.filter((item) => item.isComplited).length} /{" "}
                {lectures.length}
              </span>
            </div>
            <div className="course__review-progress">
              <ProgressBar now={Number(progress * 10)} />
            </div>
            <div className="course__review-percent">
              <h5>{progress * 10}%</h5>
            </div>
          </div>
          <DisplayCourseLectures
            courseList={courseList}
            activeId={lecture?.id}
            hndleLectureSelected={hndleLectureSelected}
          />
        </Col>
      )}
    </Row>
  );
}

function VideoPlayer({ lecture, handleVideoEnded }) {
  const { id } = useParams();
  const [isHovered, setIsHovered] = React.useState(false);
  const [postSent, setpostSent] = React.useState(false);
  const videoRef = useRef(null);
  const queryClient = useQueryClient();

  // Update video source when the lecture changes
  React.useEffect(() => {
    setpostSent(false);
    const video = videoRef.current;
    video.load(); // Reload the video to apply the new source
    video.play();
  }, [lecture]);

  const handleTimeUpdate = () => {
    const video = videoRef.current;
    // const currentTime = parseInt(video.currentTime);
    // if (currentTime > 0 && currentTime % 10 === 0) {
    //   // Send an event or perform an action every 10 seconds
    //   console.log(`Reached ${currentTime} seconds`);
    // }

    if (!postSent && video.duration - video.currentTime <= 10) {
      setpostSent(true);
      postProgress();
    }
  };

  async function postProgress() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };
    const data = { lectureId: lecture?.id, courseId: id };
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/progress`,
      data,
      config
    );
    if (response.data) {
      const { chapiters } = queryClient.getQueryData(["courseLectures", id]);

      let updated = chapiters.map((item) => {
        item.lectures = item.lectures.map((el) => {
          if (lecture?.id === el?.id) {
            el.isComplited = true;
          }
          return el;
        });
        return item;
      });

      queryClient.setQueryData(["courseLectures", id], { chapiters: updated });
    }
  }
  return (
    <div>
      <div
        style={{ position: "relative" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <video
          ref={videoRef}
          controls
          controlsList="nodownload"
          style={{ width: "100%", borderRadius: "12px" }}
          onTimeUpdate={handleTimeUpdate}
          onEnded={handleVideoEnded}
        >
          <source
            src={
              process.env.REACT_APP_DOMAIN_BACKEND + lecture.attachement?.url
            }
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <div
          style={{
            position: "absolute",
            background: `linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))`,
            top: "0px",
            left: "0px",
            right: "0px",
            height: "80px",
            display: "flex",
            alignItems: "left",
            paddingLeft: "50px",
            color: "white",
            transition: "0.3s",
            borderTopRightRadius: "12px",
            borderTopLeftRadius: "12px",
            opacity: isHovered ? "1" : "0",
            transform: "0.3s",
            pointerEvents: "none",
          }}
        >
          <h3>{lecture?.name}</h3>
        </div>
      </div>
    </div>
  );
}

function DisplayCourseLectures({ courseList, activeId, hndleLectureSelected }) {
  return (
    <div
      style={{
        height: "41vw",
        maxHeight: "500px",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        margin: 0,
      }}
    >
      {courseList?.chapiters.map((course, index) => (
        <div key={course?.id}>
          <ListGroup>
            <ListGroup.Item
              style={{
                padding: "15px 10px",
                fontSize: "18px",
                backgroundColor: "#F3F4F8",
              }}
            >
              <b>
                #{index} - {course.name}
              </b>
            </ListGroup.Item>
            {course.lectures.map((lecture) => (
              <ListGroup.Item
                as={Link}
                to="#"
                key={lecture?.id}
                style={{
                  padding: "15px 10px",
                  backgroundColor: activeId === lecture?.id ? "#2b4eff1a" : "",
                }}
                onClick={() => hndleLectureSelected(lecture)}
              >
                <Row className="align-items-center">
                  <Col xs={1}>
                    <Form.Check type="checkbox" checked={lecture.isComplited} />
                  </Col>
                  <Col>{lecture.name}</Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      ))}
    </div>
  );
}
