import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import styled from "styled-components";
import { FiX } from "react-icons/fi";

import { useDispatch, useSelector } from "react-redux";
import { onCloseModel } from "../../redux/features/ticket-slice";
import { useTranslation } from "react-i18next";

const StyledModal = styled(Modal)``;

export default function CreateTcicket() {
  const { t } = useTranslation();
  const { isOpen, title } = useSelector((state) => state.tickets);
  const dispatch = useDispatch();
  const [issue, setIssue] = useState({
    subject: null,
    issue: "",
  });

  const handleClose = () => {
    dispatch(onCloseModel());
    setIssue({
      subject: null,
      issue: "",
    });
  };

  const onHandeChange = (event) => {
    const { name, value } = event.target;
    setIssue({ ...issue, [name]: value });
  };
  console.log(issue);
  const onCreateTicket = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      };
      const ticket = {
        subject: issue.subject,
        text: issue.issue,
        page: window.location.href,
        issue: `<p>url:  ${window.location.href} <br />page name:  <br />owner: <b>ismail hocine</b><br /><br />----<br /> ${issue.issue}</p>\n`,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ticket/create`,
        ticket,
        config
      );
      console.log(response);
      setIssue({
        subject: null,
        issue: "",
      });
      dispatch(onCloseModel());
    } catch (error) {
      console.log(error?.message);
    }
  };

  return (
    <>
      <StyledModal
        show={isOpen}
        onHide={handleClose}
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-alert-report modal-border-raduis"
        centered
        backdrop="static"
        keyboard={false}
      >
        {" "}
        <Modal.Body
          //   style={{ textAlign: "right" }}
          className="model-border-raduis"
        >
          <div
            style={{ fontSize: "14px", color: "#9CA3AF", textAlign: "center" }}
          >
            {t("reportTitle")}
            {" " + title}
          </div>
          <hr />
          <div style={{ marginBottom: "30px" }}>
            <Form>
              <div key={`default-${`radio`}`} className="mb-3">
                <Form.Check
                  onChange={(e) => onHandeChange(e)}
                  value={t("contentIsOffensive")}
                  name="subject"
                  type={`radio`}
                  id={`1-${`radio`}`}
                  label={t("contentIsOffensive")}
                />

                <Form.Check
                  onChange={(e) => onHandeChange(e)}
                  name="subject"
                  style={{ paddingTop: "20px" }}
                  type={`radio`}
                  label={t("contentIsSpam")}
                  value={t("contentIsSpam")}
                  id={`2-${`radio`}`}
                />
                <Form.Check
                  onChange={(e) => onHandeChange(e)}
                  name="subject"
                  style={{ paddingTop: "20px" }}
                  type={`radio`}
                  label={t("contentIsAgainstTerms")}
                  value={t("contentIsAgainstTerms")}
                  id={`3-${`radio`}`}
                />
                <Form.Label
                  style={{
                    margin: "20px 0px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {t("additionalInfo")}
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  style={{ minHeight: "150px !important" }}
                  placeholder={t("explainActions")}
                  name="issue"
                  onChange={(e) => onHandeChange(e)}
                  value={issue.issue}
                />
              </div>
            </Form>
          </div>
          <div className="flex-full-center">
            <Button
              variant="gray"
              className="btn-height-meduim  btn-medium-raduis btn-gray"
              style={{ width: "120px", margin: "0px 10px" }}
              onClick={() => handleClose()}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="primary"
              className="btn-height-meduim btn-medium-raduis"
              style={{ width: "120px", margin: "0px 10px" }}
              onClick={() => onCreateTicket()}
              disabled={issue.issue === "" && issue.subject === null}
            >
              {t("submit")}
            </Button>
          </div>
        </Modal.Body>
        <button onClick={handleClose}>
          <FiX />
        </button>
      </StyledModal>
    </>
  );
}
