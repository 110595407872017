import React, { useState } from "react";

import { Badge, Card } from "react-bootstrap";
import Typography, { H3, H5, Paragraph } from "../common/Typography";
import styled from "styled-components";
import Moment from "react-moment";
import axios from "axios";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

export default function InboxCoursehub() {
  const [page, setPage] = useState(1);

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["course-hub-student", page],
    () => fetchCourses(page)
  );

  async function fetchCourses(page) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/chat-course-hub?page=${page || 1}`,
      config
    );
    return response.data;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <Card
      style={{
        minHeight: `${window.innerHeight - 299}px`,
        height: "100%", // Full height of the Card
        overflowY: "auto",
      }}
    >
      <Card.Body>
        <>
          <H3 color="gray.700" mb="4px" lineHeight="1.3">
            Recent Chat
          </H3>
          <hr />

          {data?.chats.map((item) => (
            <CardLink
              to={
                window.innerWidth > 778
                  ? `?course_hub_id=${item.id}`
                  : `/dashboard/chat/${item.id}`
              }
              style={{ margin: "10px 0px" }}
            >
              <Card>
                <Card.Body style={{ backgroundColor: "white" }}>
                  <ItemMessage>
                    <img
                      src={"https://avatars.hsoubcdn.com/default?s=128"}
                      alt=""
                      style={{ width: "100%" }}
                    />
                    <div>
                      <Paragraph color="text.muted">
                        <H5 color="gray.700" mb="4px" lineHeight="1.3">
                          {item?.class?.course?.name} | {item?.class?.name}
                        </H5>
                        <span style={{ float: "right" }}>
                          <Moment format="hh:mm">{new Date()}</Moment>
                        </span>
                        {item?.messages?.length === 0
                          ? "No message"
                          : item.messages[0].message}
                        {item.unread_count === 0 && (
                          <BadgeNewMessage
                            bg="danger"
                            style={{ float: "right" }}
                          >
                            {item.unread_counts > 99
                              ? "+99"
                              : item.unread_counts}
                          </BadgeNewMessage>
                        )}
                      </Paragraph>
                    </div>
                  </ItemMessage>
                </Card.Body>
              </Card>
            </CardLink>
          ))}
        </>
      </Card.Body>
    </Card>
  );
}
const ItemMessage = styled.div`
  psotion: relative;
  display: grid;
  grid-template-columns: 45px auto;
  gap: 20px;
  cursor: pointer;
`;
const BadgeNewMessage = styled(Badge)`
  position: absolute;
  top: 4px;
  right: 4px;
`;
const CardLink = styled(Link)`
  background-color: white;
  &:hover {
    background-color: red;
  }
`;
