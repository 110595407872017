import React, { useCallback, useState } from "react";
import { Button, Card, FormControl } from "react-bootstrap";

import { useSearchParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import Typography, { H3, H5, H6, Paragraph } from "../common/Typography";
import styled from "styled-components";
import Moment from "react-moment";
import ErrorMsg from "../common/forms/error-msg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { CiClock1 } from "react-icons/ci";
import { FiCheck } from "react-icons/fi";
import { FiSend } from "react-icons/fi";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { FiMoreVertical } from "react-icons/fi";
import Recorder from "../common/chat/Recorder";
import WriterMessage from "../common/chat/WriterMessage";
import UploadedFile from "../common/chat/UploadedFile";
import UploadedImage from "../common/chat/UploadedImage";
import { onClearFile, onClearImages } from "../../redux/features/upload-slice";
import ImageViewer from "./ImageViewer";

export default function MessagesCoursehub({ course_hub_id }) {
  const [page, setPage] = useState(1);

  const fetchCourses = (page = 1) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };

    axios
      .get(
        `${
          process.env.REACT_APP_API_URL
        }/chat-course-hub/${course_hub_id}?page=${page || 1}`,
        config
      )
      .then((response) => {
        setData(response.data?.messages || []);
        scrollToBottom();
      });
  };

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    if (course_hub_id) {
      fetchCourses(1);
    }
  }, [course_hub_id]);

  // Create a ref for the scrollable container
  const scrollableContainerRef = React.useRef(null);

  // Function to scroll to the bottom of the scrollable container

  const scrollToBottom = useCallback(() => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollTo({
        top: scrollableContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, []);

  const { socket } = useSelector((state) => state.socket);
  React.useEffect(() => {
    if (data) {
      scrollToBottom();
    }
  }, [data]);
  React.useEffect(() => {
    if (socket) {
      // Create a socket connection
      socket?.on("newMessage", ({ message }) => {
        // formik.setFieldTouched("message", false); // Disable field touched status
        // formik.setFieldValue("message", ""); // Reset field value
        const newMessage = {
          createdAt: new Date(),
          message: message,
          member: {
            fullname: localStorage.getItem("fullname"),
          },
        };
        setData((prev) => [...prev, newMessage]);
      });
      return () => {
        socket?.off("newMessage");
      };
    }
  }, [socket]);
  const dispatch = useDispatch();
  const handleSubmit = async (body) => {
    // Handle form submission here

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };
    if (body.type === "TEXT") {
      const form = {
        message: body.message,
        type: body.type,
      };
      const uuid = uuidv4();
      const newMessages = {
        id: uuid,
        type: body.type,
        message: body.message,
        member: {
          id: localStorage.getItem("id"),
          fullname: localStorage.getItem("fullname"),
          avatar: localStorage.getItem("avatar"),
        },
        createdAt: new Date(),
        status: "pending",
      };
      setData((prev) => [...prev, newMessages]);

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/course-hub/message?course_hubId=${course_hub_id}`,
          form,
          config
        )
        .then((response) => {
          setTimeout(() => {
            setData((currentData) =>
              currentData.map((item) => {
                if (item.id === uuid) {
                  item.status = "success";
                }
                return item;
              })
            );
          }, 1000);
        })
        .catch((error) => {
          const update = data.map((item) => {
            if (item.id === uuid) {
              item.status = "error";
            }
            return item;
          });
          setData(update);
        });
    } else if (body.type === "VOICE") {
      const newMessages = {
        id: uuidv4(),
        type: body.type,
        message: null,
        file: body.message,
        member: {
          id: localStorage.getItem("id"),
          fullname: localStorage.getItem("fullname"),
          avatar: localStorage.getItem("avatar"),
        },
        createdAt: new Date(),
        status: "pending",
      };

      const updated = [...data, newMessages];
      setData(updated);
    } else if (body.type === "IMAGES" || body.type === "FILES") {
      dispatch(onClearImages());
      dispatch(onClearFile());
      const newMessages = body.message.map((file) => ({
        id: uuidv4(),
        type: body.type,
        message: null,
        file: file.file,
        blobURL: body.type === "IMAGES" ? file.blobURL : null,
        member: {
          id: localStorage.getItem("id"),
          fullname: localStorage.getItem("fullname"),
          avatar: localStorage.getItem("avatar"),
        },
        createdAt: new Date(),
        status: "pending",
      }));

      setData((prev) => [...prev, ...newMessages]);

      // const result = await uploadBlobUrlToStrapi(body.message);
      // axios
      //   .post(
      //     `${process.env.REACT_APP_API_URL}/course-hub/message?course_hubId=${course_hub_id}`,
      //     {
      //       files: result.data,
      //       type: body.type,
      //     },
      //     config
      //   )
      //   .then((response) => {
      //     setTimeout(() => {
      //       setData((currentData) =>
      //         currentData.map((item) => {
      //           if (item.id === uuid) {
      //             item.status = "success";
      //             item.files = result.data;
      //             item.type = body.type;
      //           }
      //           return item;
      //         })
      //       );
      //     }, 1000);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     const update = data.map((item) => {
      //       if (item.id === uuid) {
      //         item.status = "error";
      //       }
      //       return item;
      //     });
      //     setData(update);
      //   });
    } else {
      alert("something wrong");
    }
  };
  const setUploaded = useCallback((targetItem) => {
    console.log(targetItem);
    setData((currentData) =>
      currentData.map((item) => {
        if (item.id === targetItem.id) {
          item.status = "success";
          item.file = targetItem.file;
        }
        return item;
      })
    );
  }, []);
  return (
    <Card>
      <div>
        <Card.Body style={{ backgroundColor: "transparent" }}>
          <ChatHeader>
            <H3 color="gray.700" mb="4px" lineHeight="1.3">
              Chat
            </H3>
            {/* <Dropdown>
              <StyledDropdownButton
                title={<FiMoreVertical />}
                variant="link"
                style={{ padding: "0px auto" }}
              >
       
                <Dropdown.ItemText>Notifications</Dropdown.ItemText>
              </StyledDropdownButton>
            </Dropdown> */}
          </ChatHeader>
          <hr />
          <div
            ref={scrollableContainerRef}
            style={{
              height: "100%", // Full height of the Card
              minHeight: "350px",
              overflowY: "auto", // Allow scrolling
              height: `${window.innerHeight - 450}px`,
              maxHeight: `400px`,
            }}
          >
            {data?.map((item) => (
              <>
                <ItemMessage>
                  <img
                    src={"https://avatars.hsoubcdn.com/default?s=128"}
                    alt=""
                    style={{ width: "100%" }}
                  />
                  <div>
                    <Paragraph
                      color="text.muted"
                      style={{ padding: "0px 0px 10px 0" }}
                    >
                      <H6 color="gray.700" mb="10px" lineHeight="1.3">
                        {item?.member?.fullname}
                      </H6>

                      <PreUploadItem
                        item={item}
                        course_hub_id={course_hub_id}
                        setUploaded={setUploaded}
                      />
                      {/* {item.file && item?.file?.url && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            backgroundColor: "#dee1e6",
                            borderRadius: 15,
                            padding: "0px 20px",
                          }}
                        >
                          <audio
                            className="audio-player audio-player-message "
                            controls
                            src={
                              process.env.REACT_APP_DOMAIN_BACKEND +
                              item?.file?.url
                            }
                            type="audio/ogg"
                            style={{ marginLeft: 10 }}
                          />
                        </div>
                      )}
                      {item?.message && <ItemText>{item?.message}</ItemText>} */}
                    </Paragraph>
                  </div>
                </ItemMessage>
                <div style={{ textAlign: "right" }}>
                  {item.status === "pending" ? <CiClock1 /> : <FiCheck />}{" "}
                  <Moment format="hh:mm">{new Date(item.createdAt)}</Moment>
                </div>
              </>
            ))}
          </div>
        </Card.Body>
      </div>
      <TextAreaStylled>
        <Card.Body>
          <WriterMessage createMessage={handleSubmit} />
        </Card.Body>
      </TextAreaStylled>
    </Card>
  );
}

async function blobToFile(blobUrl, filename) {
  // Fetch the Blob from the Blob URL
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  // Create a File object from the Blob
  const file = new File([blob], filename, { type: blob.type });

  return file;
}

async function uploadToStrapi(file) {
  // Create a FormData object and append the file to it
  const formData = new FormData();
  formData.append("files", file);
  const options = {
    headers: {
      //"Content-Type": selectedFile.type,
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_API_URL}/upload/`,
    formData,
    options
  );
  // The result should contain the uploaded file's information
  console.log("File uploaded successfully:", response);

  // Return the result containing information about the uploaded file
  return response;
}

// Usage example:
async function uploadBlobUrlToStrapi(blobUrl) {
  // Convert the Blob URL to a File object
  const file = await blobToFile(blobUrl, "audio.ogg");

  // Upload the File object to Strapi
  const uploadResult = await uploadToStrapi(file);

  // Handle the upload result as needed
  return uploadResult;
}

function PreUploadItem({ item, setUploaded, course_hub_id }) {
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState(null);
  const [source, setsource] = useState();
  React.useEffect(async () => {
    if (
      item.status === "pending" &&
      (item.type === "FILES" || item.type === "VOICE" || item.type === "IMAGES")
    ) {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      };
      if (item.type === "VOICE") {
        const result = await uploadBlobUrlToStrapi(item.file);
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/course-hub/message?course_hubId=${course_hub_id}`,
            {
              file: result.data,
              type: item.type,
            },
            config
          )
          .then((response) => {
            setUploaded({
              id: item.id,
              status: "success",
              file: result.data[0],
              type: item.type,
            });
          })
          .catch((error) => {});
      } else {
        const controller = new AbortController();
        console.log(item);
        setStatus("UPLOADING");
        setsource(controller);

        const formData = new FormData();
        formData.append("files", item.file);
        const options = {
          headers: {
            //"Content-Type": selectedFile.type,
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
          },
          signal: controller.signal,
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentageProgress = Math.floor((loaded / total) * 100);
            console.log(percentageProgress);
            setProgress(percentageProgress);
            //setUploaded(false);
          },
        };
        const result = await axios.post(
          `${process.env.REACT_APP_API_URL}/upload/`,
          formData,
          options
        );
        console.log(result.data[0]);
        axios
          .post(
            `${process.env.REACT_APP_API_URL}/course-hub/message?course_hubId=${course_hub_id}`,
            {
              file: result.data[0],
              type: item.type,
            },
            config
          )
          .then((response) => {
            setStatus("UPLOADED");
            setUploaded({
              ...item,
              status: "success",
              file: result.data[0],
              type: item.type,
            });
          })
          .catch((error) => {});
      }
    }
  }, [item.type]);
  if (item.type === "TEXT") return <ItemText>{item?.message}</ItemText>;
  if (item.type === "FILES")
    return (
      <UploadedFile
        file={item.file}
        progress={progress}
        status={status}
        source={source}
      />
    );
  if (item.type === "IMAGES")
    return (
      <UploadedImage
        url={
          item.status === "pending"
            ? item?.blobURL
            : process.env.REACT_APP_DOMAIN_BACKEND + item?.file?.url
        }
      />
    );
  if (item.type === "VOICE")
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            backgroundColor: "#dee1e6",
            borderRadius: 15,
            padding: "0px 20px",
          }}
        >
          <audio
            className="audio-player audio-player-message "
            controls
            src={
              item.status === "pending"
                ? item?.file
                : process.env.REACT_APP_DOMAIN_BACKEND + item?.file?.url
            }
            type="audio/ogg"
            style={{ marginLeft: 10 }}
          />
        </div>
      </>
    );
  return null;
}

// Create a styled version of DropdownButton to hide the arrow
const StyledDropdownButton = styled(DropdownButton)`
  .dropdown-toggle {
    color: black;
  }
  .dropdown-toggle::after {
    display: none !important;
  }
`;

const ChatHeader = styled.div`
  display: grid;
  grid-template-columns: auto 45px;
  gap: 10px;
  padding: 0px 0px 0px 0px;
`;
const InputMessageGrid = styled.div`
  display: grid;
  grid-template-columns: auto 45px;
  gap: 10px;
  padding: 0px 0px 0px 0px;
`;
const ItemText = styled.div`
  background-color: #2386c8;
  padding: 10px;
  color: white;
  width: 96%;
  border-radius: 12px;
`;
const TextAreaStylled = styled.div`
  /* On small screens, make the element fixed at the bottom */
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  /* On larger screens, revert the position to static (normal positioning) */
  @media (min-width: 778px) {
    position: static;
  }
`;
const ItemMessage = styled.div`
  display: grid;
  grid-template-columns: 45px auto;
  gap: 20px;
  // background-color: white;
  margin: 20px 0px 0px 0px;
  padding: 10px;
`;
