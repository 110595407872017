import React from "react";

import { Container, Row, Col, Button } from "react-bootstrap";

// import VertifyAccount from "components/alert/VertifyAccount";
import { useDispatch } from "react-redux";
import Wrapper from "../../../layout/wrapper";
import Header from "../../../layout/headers/header";
import ContentDashboard from "../../../layout/layer/Layer";
import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";
import VertifyAccount from "../../../components/VertifyAccount";
// import { onShowModel } from "features/modal/modalSlice";

let image1 = "/assets/img/image 4.svg";
let image2 = "/assets/img/image 5.svg";
let image3 = "/assets/img/image 6.svg";
let image4 = "/assets/img/download.png";

export default function AccountVerification() {
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  return (
    <>
      <Wrapper>
        <Header />
        <section
          className=" po-rel-z1 pt-20 pb-145"
          style={{ fontFamily: "Tajawal, sans-serif" }}
        >
          {/* <NavbarSection /> */}
          {/* <VertifyAccount /> */}
          <VertifyAccount
            display={show}
            handleCloseModal={(e) => setShow(false)}
          />
          <SidebarTeacher />
          <ContentDashboard>
            <Row>
              <Col md={12}>
                <Col
                  //className="flex-full-left text-left"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "50px",
                    marginBottom: "50px",
                    textAlign: "center",
                  }}
                >
                  <div>
                    <h3
                      style={{
                        fontSize: "1.5rem",
                        fontWeight: "bold",
                        color: "#374151",
                        margin: "26px 0px",
                        fontFamily: "Tajawal",
                      }}
                    >
                      {" "}
                      توثيق الحساب
                    </h3>

                    <p
                      className="text-left"
                      style={{
                        fontSize: "1.125rem",
                        maxWidth: "677px",
                        width: "100%",
                        padding: "10px",
                        marginBottom: "26px",
                        color: "#4B5563",
                        fontFamily: "Tajawal",
                      }}
                    >
                      قم الان بتوثيق الهوية وستحصل على وصول كامل لجميع خدمات
                      منصة نفذلي و اثبات ملكية الحساب و زيادة الموثوقية اثناء
                      تعامل المستخدمين معك
                    </p>
                    <Button
                      className="e-btn btn-height-meduim  btn-small-raduis"
                      variant="primary"
                      style={{
                        maxWidth: "200px",
                        width: "100%",
                        fontFamily: "Tajawal",
                      }}
                      onClick={(e) => setShow(true)}
                      // onClick={() => dispatch(onShowModel({ title: null }))}
                    >
                      توثيق الان
                    </Button>
                  </div>
                </Col>
              </Col>
              <Col
                md={12}
                style={{ margin: " 10px auto", textAlign: "center" }}
              >
                <Row>
                  <Col sm={6} className="guide-item-av">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50px",
                        marginBottom: "50px",
                      }}
                    >
                      <img
                        style={{ margin: "auto" }}
                        className="svg-av text-av"
                        src={image2}
                      />
                    </div>
                    <p className="text-av">
                      برجاء ارفاق صورة الوجه الأمامي من بطاقة تعريف الهوية
                      الوطنية
                    </p>
                  </Col>

                  <Col sm={6} className="guide-item-av">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50px",
                        marginBottom: "50px",
                        fontFamily: "Tajawal",
                      }}
                    >
                      <img
                        style={{ margin: "auto" }}
                        className="svg-av "
                        src={image1}
                      />
                    </div>
                    <p className="text-av">
                      برجاء ارفاق صورة الوجه الأمامي من بطاقة تعريف الهوية
                      الوطنية
                    </p>
                  </Col>

                  <Col sm={6} className="guide-item-av">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50px",
                        marginBottom: "50px",
                        fontFamily: "Tajawal",
                      }}
                    >
                      <img
                        style={{ margin: "auto" }}
                        className="svg-av"
                        src={image4}
                      />
                    </div>
                    <p className="text-av">
                      برجاء ارفاق صورة الوجه الأمامي من بطاقة تعريف الهوية
                      الوطنية
                    </p>
                  </Col>

                  <Col sm={6} className="guide-item-av">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "50px",
                        marginBottom: "50px",
                        fontFamily: "Tajawal",
                      }}
                    >
                      <img
                        style={{ margin: "auto" }}
                        className="svg-av"
                        src={image3}
                      />
                    </div>
                    <p className="text-av">
                      برجاء ارفاق صورة الوجه الأمامي من بطاقة تعريف الهوية
                      الوطنية
                    </p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </ContentDashboard>
        </section>
      </Wrapper>
    </>
  );
}
