import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ScrollToTop from "../components/scroll-to-top";
import Notification from "../components/Notification";
import Socket from "../components/Socket";
import About from "../pages/about";
// import Blog from "../pages/blog";
// import BlogDetails from "../pages/blog-details";
// import Cart from "../pages/cart";
// import Checkout from "../pages/checkout";
import Contact from "../pages/contact";
import Course from "../pages/course";
// import CourseDetails from "../pages/course-details";
// import CourseList from "../pages/course-list";
// import CourseSidebar from "../pages/course-sidebar";
// import DynamicBlogDetails from "../pages/d-blog-details";
import DynamicCourseDetails from "../pages/d-course-details";
// import DynamicEventDetails from "../pages/d-event-details";
import DynamicInstructorDetails from "../pages/d-instructor-details";
import ErrorPage from "../pages/error-page";
// import EventDetails from "../pages/event-details";
import Home from "../pages/home";
// import HomeTwo from "../pages/home-2";
// import HomeThree from "../pages/home-3";
// import Instructor from "../pages/instructor";
// import InstructorDetails from "../pages/instructor-details";
import SignIn from "../pages/sign-in";
import SignUp from "../pages/sign-up";
// import Wishlist from "../pages/wishlist";
// import Organization from "../pages/dashboard/organization/organization";

// import MyCourse from "../pages/dashboard/user/course";
import Chapiter from "../pages/dashboard/user/chapier";
import MyCourses from "../pages/dashboard/user/courses";
import CoursesPage from "../pages/dashboard/courses/Courses.page";
import CoursePage from "../pages/dashboard/courses/CourseDetails.page";
import ClassPage from "../pages/dashboard/courses/Course.Page";
import FormationPage from "../pages/dashboard/organization/Course.Page";

import MyEnrolledCourse from "../pages/dashboard/courses/MyEnrolledCourse";
import Payment from "../pages/dashboard/payment/Payment.page";
// import DashboardTeacher from "../components/common/Sidebar/SidebarTeacher";
import TeacherDasboard from "../pages/dashboard/teatcher/Teatcher.dashboard";
import CreateCourse from "../pages/dashboard/teatcher/CreateCourse.dashboard";
import ClassesDahboard from "../pages/dashboard/teatcher/Classes.dahboard";
import CoursesDashboard from "../pages/dashboard/teatcher/Courses.dashboard";
import CourseDashboard from "../pages/dashboard/teatcher/course.dashboard";
import ClassDahboard from "../pages/dashboard/teatcher/Class.dashboard";
import InstructorsDashboard from "../pages/dashboard/organization/dashboard/instructors";
import SettingsUser from "../pages/dashboard/teatcher/settings.dashboard";
import MyLearning from "../pages/dashboard/my-learning/MyLearning";
import AccountVerification from "../pages/dashboard/account-verification/AccountVerification";
import CoursesPayment from "../pages/dashboard/teatcher/coursesPayment.dashboard";
import InstructorProfile from "../pages/instructor/instructor";
import { Navigate } from "react-router-dom";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { initSocket } from "../redux/features/socket-slice";
import { cleanup } from "@testing-library/react";
import sound from "../sounds/beep.mp3";
import Messages from "../pages/dashboard/messages/messages";
import ChattDashboard from "../pages/dashboard/messages/chat";
import NotificationDashboard from "../pages/dashboard/notifications/Notification";
import Support from "../pages/dashboard/support";
import Ticket from "../pages/dashboard/support/ticket/Ticket";

const ProtectedRoute = ({ children, home }) => {
  if (!localStorage.getItem("jwt")) {
    // user is not authenticated

    if (home) return <Home />;
    return <Navigate to="/sign-in" />;
  }
  return children;
};

const AppNavigation = () => {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Socket />
        <Notification />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <ProtectedRoute home={true}>
                <CoursesPage />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/courses"
            element={
              <ProtectedRoute>
                <CoursesDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/notifications"
            element={
              <ProtectedRoute>
                <NotificationDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/messages"
            element={
              <ProtectedRoute>
                <Messages />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/chat/:course_hub_id"
            element={
              <ProtectedRoute>
                <ChattDashboard />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/support"
            element={
              <ProtectedRoute>
                <Support />
              </ProtectedRoute>
            }
          />

          <Route
            exact
            path="/dashboard/course"
            element={
              <ProtectedRoute>
                <CreateCourse />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/dashboard/classes"
            element={
              <ProtectedRoute>
                <ClassesDahboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/class/:id"
            element={
              <ProtectedRoute>
                <ClassDahboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/payment"
            element={
              <ProtectedRoute>
                <CoursesPayment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <TeacherDasboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/my-learning"
            element={
              <ProtectedRoute>
                <MyLearning />
              </ProtectedRoute>
            }
          />
          {/* ORGANIZATION SECTION  */}
          <Route
            path="/payment/checkout"
            element={
              <ProtectedRoute>
                <Payment />
              </ProtectedRoute>
            }
          />
          <Route
            path="/page/course/:id/:lectureId"
            element={
              <ProtectedRoute>
                <ClassPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/page/course/formation/:id/:lectureId"
            element={
              <ProtectedRoute>
                <FormationPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/my-course/:id"
            element={
              <ProtectedRoute>
                <CourseDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/support/ticket"
            element={
              <ProtectedRoute>
                <Ticket />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/account-verification"
            element={
              <ProtectedRoute>
                <AccountVerification />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard/settings"
            element={
              <ProtectedRoute>
                <SettingsUser />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-course/:id/:chapiterId"
            element={
              <ProtectedRoute>
                <Chapiter />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-courses"
            element={
              <ProtectedRoute>
                <MyCourses />
              </ProtectedRoute>
            }
          />
          <Route
            path="/page/my-learning"
            element={
              <ProtectedRoute>
                <MyEnrolledCourse />
              </ProtectedRoute>
            }
          />
          <Route path="/page/courses" element={<CoursesPage />} />
          <Route
            path="/page/course-details/:name/:id"
            element={<CoursePage />}
          />
          <Route
            path="/organization/dashboard/:name"
            element={<InstructorsDashboard />}
          />
          {/* <Route path="/organization" element={<Organization />} /> */}
          {/* <Route path="/home-two" element={<HomeTwo />} />
          <Route path="/home-three" element={<HomeThree />} /> */}
          <Route path="/courses" element={<Course />} />
          {/* <Route path="/course-list" element={<CourseList />} /> */}
          {/* <Route path="/course-sidebar" element={<CourseSidebar />} />
          <Route path="/course-details" element={<CourseDetails />} /> */}
          <Route
            path="/course-details/:id"
            element={<DynamicCourseDetails />}
          />
          {/* <Route path="/blog" element={<Blog />} />
          <Route path="/blog-details" element={<BlogDetails />} />
          <Route path="/blog-details/:id" element={<DynamicBlogDetails />} /> */}
          <Route path="/about" element={<About />} />
          {/* <Route path="/instructors" element={<Instructor />} />
          <Route path="/instructor-details" element={<InstructorDetails />} /> */}
          <Route path="/profile/:username" element={<InstructorProfile />} />
          <Route
            path="/instructor-details/:id"
            element={<DynamicInstructorDetails />}
          />
          {/* <Route path="/event-details" element={<EventDetails />} />
          <Route path="/event-details/:id" element={<DynamicEventDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/checkout" element={<Checkout />} /> */}
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/error" element={<ErrorPage />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </Router>
    </>
  );
};

export default AppNavigation;
