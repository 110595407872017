import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { menu_bar, selectMenuBar } from "../../redux/features/header-slice";
// import { Search } from "../../svg";
import { FiBookOpen } from "react-icons/fi";
import { FiArchive } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { AiOutlineSafety } from "react-icons/ai";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import styled from "styled-components";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { IoWalletOutline } from "react-icons/io5";
import { TbWorldQuestion } from "react-icons/tb";
import { FiLogIn } from "react-icons/fi";
import { FiLogOut } from "react-icons/fi";
import { RiGraduationCapLine } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FiHeadphones } from "react-icons/fi";
const NavLink = styled(Link)`
  display: block;
  margin-bottom: 10px;
  background-color: white;
  padding: 15px;
  text-decoration: none;
  background: 0 0;
  border-radius: 4px;
  border: 0;
  color: white;
  font-weight: bold;
  background-color: ${({ active }) => (active ? "#41acf3" : "transparent")};
  transi &:hover {
    color: white !important;
  }
`;

const Sidebar = () => {
  const menuOpen = useSelector(selectMenuBar);
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  // Extracting the part you need from the URL
  const parts = path.split("/");
  const currentPage = parts[parts.length - 1];
  // const [home, setHome] = useState(false);
  // const [courses, setcourses] = useState(false);
  // const [blog, setBlog] = useState(false);
  // const [pages, setPages] = useState(false);

  // const openMobileMenu = (menu) => {
  //   if (menu === "home") {
  //     setHome(!home);
  //     setcourses(false);
  //     setBlog(false);
  //     setPages(false);
  //   } else if (menu === "courses") {
  //     setHome(false);
  //     setcourses(!courses);
  //     setBlog(false);
  //     setPages(false);
  //   } else if (menu === "blog") {
  //     setHome(false);
  //     setcourses(false);
  //     setBlog(!blog);
  //     setPages(false);
  //   } else if (menu === "pages") {
  //     setHome(false);
  //     setcourses(false);
  //     setBlog(false);
  //     setPages(!pages);
  //   }
  // };
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <>
      <div
        className={menuOpen ? "sidebar__area open" : "sidebar__area"}
        style={{ backgroundColor: " #2386c8" }}
      >
        <div className="sidebar__wrapper">
          <div className="sidebar__close">
            <button
              className="sidebar__close-btn"
              id="sidebar__close-btn"
              onClick={() => dispatch(menu_bar(false))}
            >
              <span>
                <i className="far fa-times"></i>
              </span>
              <span>{t("close")}</span>
            </button>
          </div>

          <div className="sidebar__content">
            {/* <div className="logo mb-40">
              <Link to="/">
                <img src="/assets/img/logo/logo.png" alt="logo" />
              </Link>
            </div> */}
            <div className="mm-menu" style={{ paddingTop: "50px" }}>
              {user.isSignedIn ? (
                <Nav variant="pills" className="flex-column">
                  {/* Your Nav links here */}
                  {/* Example: */}
                  <Nav.Item>
                    <NavLink
                      to="/page/courses"
                      active={currentPage === "page/courses"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <RiGraduationCapLine style={{ marginRight: "10px" }} />{" "}
                      {t("courses")}
                    </NavLink>
                  </Nav.Item>

                  <hr />
                  <Nav.Item>
                    <Nav.Item>
                      <NavLink
                        to="/dashboard/"
                        active={currentPage === ""}
                        //className={`nav-link ${currentPage === "" ? "active" : ""}`}
                        style={{ marginBottom: "10px", paddingTop: "15px" }}
                      >
                        <TbBrandGoogleAnalytics
                          style={{ marginRight: "10px" }}
                        />
                        {t("dashboard")}
                      </NavLink>
                    </Nav.Item>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      to="/dashboard/courses"
                      active={
                        currentPage === "courses" || currentPage === "course"
                      }
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <FiArchive style={{ marginRight: "10px" }} />{" "}
                      {t("my courses")}
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      to="/dashboard/my-learning"
                      active={currentPage === "my-learning"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <FiBookOpen style={{ marginRight: "10px" }} />{" "}
                      {t("my learning")}
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item>
                    <NavLink
                      to="/dashboard/settings"
                      active={currentPage === "settings"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <FiUser style={{ marginRight: "10px" }} /> {t("account")}
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      to="/dashboard/account-verification"
                      active={currentPage === "verify-account"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <AiOutlineSafety style={{ marginRight: "10px" }} />
                      {t("verfiy account")}
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      to="/dashboard/payment"
                      active={currentPage === "verify-account"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <FaMoneyBillTransfer style={{ marginRight: "10px" }} />
                      {t("payment")}
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      to="#"
                      className={`nav-link ${
                        currentPage === "icome" ? "active" : ""
                      }`}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <IoWalletOutline style={{ marginRight: "10px" }} />{" "}
                      {t("income")}
                    </NavLink>
                  </Nav.Item>
                  <hr />

                  <Nav.Item>
                    <NavLink
                      to="/about"
                      active={currentPage === "about"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <TbWorldQuestion style={{ marginRight: "10px" }} />
                      About
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item>
                    <NavLink
                      to="/support"
                      active={currentPage === "support"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <FiHeadphones style={{ marginRight: "10px" }} />
                      Support
                    </NavLink>
                  </Nav.Item>
                  {/* Other NavLinks */}
                </Nav>
              ) : (
                <Nav
                  variant="pills"
                  className="flex-column"
                  style={{ paddingTop: "10px" }}
                >
                  <Nav.Item>
                    <NavLink
                      to="/"
                      active={currentPage === ""}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <FiUser style={{ marginRight: "10px" }} /> Home
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      to="/page/courses"
                      active={currentPage === "page/courses"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <RiGraduationCapLine style={{ marginRight: "10px" }} />{" "}
                      {t("courses")}
                    </NavLink>
                  </Nav.Item>

                  <Nav.Item>
                    <NavLink
                      to="/about"
                      active={currentPage === "about"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <TbWorldQuestion style={{ marginRight: "10px" }} />
                      {t("about")}
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      to="/support"
                      active={currentPage === "support"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <FiHeadphones style={{ marginRight: "10px" }} />
                      {t("Support")}
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      to="/sign-in"
                      active={currentPage === "sign-in"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <FiLogIn style={{ marginRight: "10px" }} />
                      Sign in
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink
                      to="/sign-up"
                      active={currentPage === "sign-up"}
                      style={{ marginBottom: "10px", paddingTop: "15px" }}
                    >
                      <FiLogOut style={{ marginRight: "10px" }} />
                      Sign Up
                    </NavLink>
                  </Nav.Item>
                </Nav>
              )}
            </div>
          </div>
          {/* <div className="sidebar__content">
            <div className="logo mb-40">
              <Link to="/"><img src="/assets/img/logo/logo.png" alt="logo" /></Link>
            </div>
            <div className="mm-menu">
              <ul>
                <li className={home ? "has-droupdown active" : "has-droupdown"}>
                  <a href='#!' onClick={() => { openMobileMenu('home'); }}>Home </a>
                  <ul className={home ? "sub-menu active" : "sub-menu"} onClick={() => dispatch(menu_bar(false))}>
                    <li><Link to="/">Home 1</Link></li>
                    <li><Link to="/home-two">Home 2</Link></li>
                    <li><Link to="/home-three">Home 3</Link></li>
                  </ul>
                </li>
                <li className={courses ? "has-droupdown active" : "has-droupdown"}>
                  <a href='#!' onClick={() => { openMobileMenu('courses'); }}>Courses </a>
                  <ul className={courses ? "sub-menu active" : "sub-menu"} onClick={() => dispatch(menu_bar(false))}>
                    <li><Link to="/courses">Courses </Link></li>
                    <li><Link to="/course-list">Course List</Link></li>
                    <li><Link to="/course-sidebar">Course Sidebar</Link></li>
                    <li><Link to="/course-details">Course Details</Link></li>
                  </ul>
                </li>
                <li className={blog ? "has-droupdown active" : "has-droupdown"}>
                  <a href='#!' onClick={() => { openMobileMenu('blog'); }}>Blog </a>
                  <ul className={blog ? "sub-menu active" : "sub-menu"} onClick={() => dispatch(menu_bar(false))}>
                    <li><Link to="/blog">Blog</Link></li>
                    <li><Link to="/blog-details">Blog Details</Link></li>
                  </ul>
                </li>
                <li className={pages ? "has-droupdown active" : "has-droupdown"}>
                  <a href='#!' onClick={() => { openMobileMenu('pages'); }}>Pages </a>
                  <ul className={pages ? "sub-menu active" : "sub-menu"} onClick={() => dispatch(menu_bar(false))}>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/instructor">Instructor</Link></li>
                    <li><Link to="/instructor-details">Instructor Details</Link></li>
                    <li><Link to="/event-details">Event Details</Link></li>
                    <li><Link to="/cart">My Cart</Link></li>
                    <li><Link to="/wishlist">My Wishlist</Link></li>
                    <li><Link to="/checkout">Checkout</Link></li>
                    <li><Link to="/sign-in">Sign In</Link></li>
                    <li><Link to="/sign-up">Sign Up</Link></li>
                    <li><Link to="/error">Error</Link></li>
                  </ul>
                </li>
                <li onClick={() => dispatch(menu_bar(false))}><Link to="/contact">Contact</Link></li>
              </ul>
            </div>

            <div className="sidebar__search p-relative mt-40 ">
              <form onSubmit={e => e.preventDefault()}>
                <input type="text" placeholder="Search..." />
                <button type="submit"><Search /></button>
              </form>
            </div>

          </div> */}
        </div>
      </div>

      {/* <!-- sidebar overlay end --> */}
      <div
        onClick={() => dispatch(menu_bar(false))}
        className={`body-overlay ${menuOpen ? "opened" : ""}`}
      ></div>
      {/* <!-- sidebar overlay end --> */}
    </>
  );
};

export default Sidebar;
