import { useTranslation } from "react-i18next";
import Breadcrumb from "../../../components/common/breadcrumb/breadcrumb";
import CourseList from "../../../components/common/course/CourseList";

import SEO from "../../../components/seo";
import Footer from "../../../layout/footers/footer";

import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";

const CoursesPage = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SEO pageTitle={"Courses"} />
      <Header header_white={true} />
      <Breadcrumb
        pages={[{ name: t("home"), path: "" }]}
        title={t("courses")}
        subtitle={t("courses")}
      />
      <div className="container" style={{ marginTop: "100px" }}>
        <CourseList path="courses" subPage="page" sidebar={true} />
      </div>
      <Footer pt="pt-90" />
    </Wrapper>
  );
};

export default CoursesPage;
