"use client";
import styled from "styled-components";
import Box from "./Box";
import { border, color, flexbox, layout, space } from "styled-system";

const FlexBox = styled(Box)`
  display: flex;
  flex-direction: row;
  ${layout}
  ${color}
  ${flexbox}
  ${space}
  ${border}
`;

export default FlexBox;
