import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import { HelmetProvider } from "react-helmet-async";
import AppNavigation from "./navigation/app-navigation";
import { store } from "./redux/store";
import "./style.css";
import "./i18n";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <HelmetProvider>
          <ThemeProvider theme={theme()}>
            <AppNavigation />
            <ToastContainer />
          </ThemeProvider>
        </HelmetProvider>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
