import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { selectCourses } from "../../../redux/features/course-slice";
import {
  add_to_wishlist,
  wishlistItems,
} from "../../../redux/features/wishlist-slice";
import CourseList from "./CourseList";

const course_info = {
  title: (
    <>
      Find the Right
      <br />
      Online
    </>
  ),
  shape_text: "Course",
  title_2: "for you",
  subtitle:
    "You don't have to struggle alone, you've got our assistance and help.",
};

const Courses = () => {
  const allCourses = useSelector(selectCourses);
  const [courses, setCourses] = useState(allCourses.slice(0, 6));
  const [active, setActive] = useState("all");
  const wishlists = useSelector(wishlistItems);
  console.log(wishlists);
  const dispatch = useDispatch();

  const handleTrendingCourse = (category) => {
    setActive(category);
    if (category === "all") {
      setCourses(allCourses.slice(0, 6));
    }
    if (category === "trending") {
      setCourses(allCourses.filter((course) => course.trending_1));
    }
    if (category === "popularity") {
      setCourses(allCourses.filter((course) => course.popularity_1));
    }
    if (category === "featured") {
      setCourses(allCourses.filter((course) => course.featured_1));
    }
    if (category === "art_design") {
      setCourses(allCourses.filter((course) => course.art_design_1));
    }
  };

  // handle wishlist
  const handleWishList = (course) => {
    dispatch(add_to_wishlist(course));
  };

  return (
    <>
      <section className="course__area pt-115 pb-120 grey-bg">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-xxl-5 col-xl-6 col-lg-6">
              <div className="section__title-wrapper mb-60">
                <h2 className="section__title">
                  {course_info.title}{" "}
                  <span className="yellow-bg yellow-bg-big">
                    {course_info.shape_text}
                    <img src="/assets/img/shape/yellow-bg.png" alt="" />
                  </span>{" "}
                  {course_info.title_2}
                </h2>
                <p>{course_info.subtitle}</p>
              </div>
            </div>
            {/* <div className="col-xxl-7 col-xl-6 col-lg-6">
              <div className="course__menu d-flex justify-content-lg-end mb-60">
                <div className="masonary-menu filter-button-group">
                  <button
                    onClick={() => handleTrendingCourse("all")}
                    className={active === "all" ? "active" : ""}
                  >
                    See All <span className="tag">new</span>
                  </button>
                  <button
                    className={active === "trending" ? "active" : ""}
                    onClick={() => handleTrendingCourse("trending")}
                  >
                    Trending
                  </button>
                  <button
                    className={active === "popularity" ? "active" : ""}
                    onClick={() => handleTrendingCourse("popularity")}
                  >
                    Popularity
                  </button>
                  <button
                    className={active === "featured" ? "active" : ""}
                    onClick={() => handleTrendingCourse("featured")}
                  >
                    Featured
                  </button>
                  <button
                    className={active === "art_design" ? "active" : ""}
                    onClick={() => handleTrendingCourse("art_design")}
                  >
                    Art & Design
                  </button>
                </div>
              </div>
            </div> */}
          </div>
          <div className="row grid">
            <CourseList
              path="courses"
              sidebar={false}
              withoutPagination={true}
              pageSize={7}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Courses;
