import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import StayledLayer from "./StaylledLayer";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMsg from "../common/forms/error-msg";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { FaEdit, FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";
import { useQuery, useQueryClient } from "react-query";
import Swal from "sweetalert2";
import FileUploader from "../common/FileUploader/FileUploader";
import { getFileType } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { driver } from "driver.js";

const StyledAccordionItem = styled(Accordion.Item)`
  position: relative;
  width: 100%;

  .accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    b {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 300px); // Adjust this value as per your layout
      @media (max-width: 576px) {
        max-width: calc(100% - 150px);
      }
    }

    .actions {
      z-index: 130;
      display: flex;
      gap: 10px;
      position: absolute;
      right: 60px;
      button {
        height: 35px;
        width: auto;
        @media (max-width: 576px) {
          width: 40px;

          span {
            display: none;
          }
        }
      }
    }
  }
`;

export const ActionButton = styled.div`
  display: flex;
  gap: 10px;
  float: right;
  right: 60px;
  button {
    height: 35px;
    width: 120px;
    @media (max-width: 576px) {
      width: 40px;
      span {
        display: none;
      }
    }
  }
`;
const fetchChapiter = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/chapiters/${id}`,
    config
  );
  return response.data.chapiter;
};
export default function ChapiterTable({ courseId }) {
  const { t } = useTranslation();
  const [deleteResource, setDeleteResource] = React.useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [chapiterId, setchapiterId] = React.useState(null);
  const [modalLectureShow, setModalLectureShow] = React.useState(false);
  const [disabledItem, setDisabledItem] = React.useState(new Map());
  const [itemToEdit, setitemToEdit] = React.useState(null);
  const { id } = useParams();
  const token = localStorage.getItem("jwt");
  const queryClient = useQueryClient();
  const {
    data: chapiters,
    isLoading,
    isError,
    //!NOTE SHOULD REMOVE REFETCH AND UPDATE CACHE LOCALLY
    refetch,
  } = useQuery(["chapiters", id], () => fetchChapiter(id, token));

  useEffect(() => {
    fetchChapiter();
  }, []);
  const handleEdit = (item) => {
    console.log(item);
    setitemToEdit(item);
    setModalLectureShow(true);
  };
  const handleEditChapiter = (item) => {
    setitemToEdit(item);
    setModalShow(true);
  };
  const handleUploadFile = ({ chapiterId, lecture_id, data }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };
    console.log(data);
    const file = data[0];
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/lecture/upload/${lecture_id}`,
        file,
        config
      )
      .then((res) => {
        console.log(res);
        // Get the current cached data
        let currentChapiters = queryClient.getQueryData(["chapiters", id]);
        if (currentChapiters) {
          let updated = currentChapiters.map((item) => {
            if (chapiterId === item.id) {
              item.lectures.map((lecture) => {
                if (lecture.id === lecture_id) {
                  lecture = res.data.updatedLecture;
                  lecture.attachement = file;
                  removeItemId(lecture.id);
                }
              });
            }
            return item;
          });

          queryClient.setQueryData(["chapiters", id], [...updated]);

          // Invalidate the query to refetch the updated data
          queryClient.invalidateQueries(["chapiters", id]);
        }
        //   navigate(`/my-course/${id}/${res.data.id}`);
        // we will demand the link to upload the file
      })
      .catch((error) => {
        // Handle error
        removeItemId(lecture_id);
      });
  };
  const handleDelete = (item) => {
    console.log(item);
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this Lecture!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // User clicked the confirm button
        // Perform the deletion action here
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          };

          const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/lecture/${item.id}?courseId=${courseId}`,
            config
          );

          if (response.status === 200) {
            // Successful deletion
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Your lecture has been deleted.",
            });
            refetch();
          } else {
            // Handle other response statuses if needed
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Failed to delete lecture. Please try again later.",
            });
          }
        } catch (error) {
          // Handle errors
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while deleting the lecture. Please try again later.",
          });
          console.error("Delete class error:", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked the cancel button or closed the modal
        Swal.fire({
          icon: "error",
          title: "Cancelled",
          text: "Your class is safe :)",
        });
      }
    });

    // setDeleteResource(true);
  };
  const handleDeleteChapiter = (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this Lecture!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // User clicked the confirm button
        // Perform the deletion action here
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          };

          const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/chapiter/${item.id}?courseId=${id}`,
            config
          );

          if (response.status === 200) {
            // Successful deletion
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Your Chapiter has been deleted.",
              showConfirmButton: false,
              timer: 1000,
              showCancelButton: false,
              showConfirmButton: false, // Time in milliseconds (1 second = 1000 milliseconds)
            });
            refetch();
          } else {
            // Handle other response statuses if needed
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Failed to delete Chapiter. Please try again later.",
            });
          }
        } catch (error) {
          // Handle errors
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while deleting the Chapiter. Please try again later.",
          });
          console.error("Delete class error:", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked the cancel button or closed the modal
        Swal.fire({
          icon: "error",
          title: "Cancelled",
          text: "Your class is safe :)",
        });
      }
    });

    // setDeleteResource(true);
  };

  // !FIX: here medthod to disable button
  // Function to add an item ID to the Map and avoid duplication
  const addItemId = (id) => {
    if (!disabledItem.has(id)) {
      // If the item ID does not already exist in the Map, add it with a false disabled state
      setDisabledItem((prevMap) => new Map(prevMap).set(id, true));
    }
  };

  // Function to remove an item ID from the Map
  const removeItemId = (id) => {
    try {
      const map = disabledItem;
      map.delete(id);
      setDisabledItem(map);
    } catch (error) {
      console.log(error);
    }
  };
  React.useEffect(() => {
    if (!localStorage.getItem("chapiters-table-guide")) {
      const driverObj = driver({
        showProgress: true,
        steps: [
          {
            element: "#chapiter-actions",
            popover: {
              title: "Chapter Actions",
              description:
                "Edit the chapter name or delete the chapter entirely from here.",
            },
          },
          {
            element: "#new-lecture",
            popover: {
              title: "Add Lecture",
              description:
                "Add a lecture to the chapter, including files, videos, or images.",
            },
          },
        ],
      });

      driverObj.drive();
      localStorage.setItem("chapiters-table-guide", true);
    }
  }, []);

  if (isLoading) {
    return <StayledLayer>Loading...</StayledLayer>;
  }

  if (isError) {
    return <StayledLayer>Error fetching data</StayledLayer>;
  }
  return (
    <StayledLayer>
      <Button
        variant="primary"
        className="e-btn"
        style={{ marginBottom: "20px" }}
        onClick={() => setModalShow(true)}
        id="new-chapiter"
      >
        {t("addChapiter")}
      </Button>

      <CreateChapiterModal
        show={modalShow}
        itemToEdit={itemToEdit}
        onHide={() => {
          setitemToEdit(null);
          setModalShow(false);
        }}
      />
      <Accordion defaultActiveKey={chapiters.length - 1}>
        {chapiters.map((item, index) => (
          <StyledAccordionItem eventKey={index} key={item.id}>
            <Accordion.Header className="accordion-header">
              <b>
                {t("chapiter")} {index + 1}: {item.name}
              </b>
              <div className="actions" id="chapiter-actions">
                <Button
                  onClick={() => handleEditChapiter(item)}
                  variant="warning"
                >
                  <MdOutlineEdit />
                  <span>{t("edit")}</span>
                </Button>
                <Button
                  onClick={() => handleDeleteChapiter(item)}
                  variant="danger"
                >
                  <FaRegTrashAlt />
                  <span>{t("delete")}</span>
                </Button>
              </div>
            </Accordion.Header>

            <Accordion.Body>
              <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{t("lectureName")}</th>
                    <th>{t("fileType")}</th>
                    <th style={{ textAlign: "right" }}>{t("status")}</th>
                    <th style={{ textAlign: "right" }}>
                      <div style={{ marginRight: "20px" }}>Actions</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {item.lectures.map((itemLecture, index) => (
                    <tr key={itemLecture.id}>
                      <td>{index + 1}</td>
                      <td>{itemLecture.name}</td>
                      <td>
                        {itemLecture?.attachement?.url
                          ? getFileType(itemLecture.attachement.url)
                          : "No File"}
                      </td>
                      <td>
                        <div
                          style={
                            {
                              // display: "grid",
                              // gridTemplateColumns: "1fr 1fr",
                              // width: "300px",
                              // gap: "10px",
                            }
                          }
                        >
                          <ActionButton>
                            {itemLecture.attachement && (
                              <Button
                                disabled={disabledItem?.has(itemLecture.id)}
                                onClick={() => {
                                  window.open(
                                    `${
                                      process.env.REACT_APP_DOMAIN_BACKEND +
                                      itemLecture.attachement.url
                                    }`,
                                    "_blank",
                                    "width=600,height=400"
                                  );
                                }}
                              >
                                {t("attachment")}
                              </Button>
                            )}
                            <FileUploader
                              getUploadedFile={(data) =>
                                handleUploadFile({
                                  chapiterId: item.id,
                                  lecture_id: itemLecture.id,
                                  data,
                                })
                              }
                              setDisabledItem={() => {
                                addItemId(itemLecture.id);
                              }}
                              emptyLecture={true}
                            />{" "}
                          </ActionButton>
                        </div>
                      </td>
                      <td>
                        <ActionButton>
                          <Button
                            onClick={() => handleEdit(itemLecture)}
                            variant="warning"
                            style={{ margin: "0px 5px" }}
                            disabled={disabledItem?.has(itemLecture.id)}
                          >
                            <MdOutlineEdit />
                            <span>{t("edit")}</span>
                          </Button>
                          <Button
                            onClick={() => handleDelete(itemLecture)}
                            variant="danger"
                            disabled={disabledItem?.has(itemLecture.id)}
                          >
                            <FaRegTrashAlt />
                            <span>{t("delete")}</span>
                          </Button>
                        </ActionButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Button
                variant="primary"
                className="e-btn"
                id="new-lecture"
                style={{ marginBottom: "20px" }}
                onClick={() => {
                  setchapiterId(item.id);
                  setModalLectureShow(true);
                }}
              >
                {t("addLecture")}
              </Button>
            </Accordion.Body>
          </StyledAccordionItem>
        ))}
      </Accordion>

      <CreateNewLecture
        show={modalLectureShow}
        itemToEdit={itemToEdit}
        onHide={() => {
          setitemToEdit(null);
          setchapiterId(null);
          setModalLectureShow(false);
        }}
        chapiterId={chapiterId}
      />
      <UpdateResourceModal
        show={deleteResource}
        onHide={() => setDeleteResource(false)}
      />
    </StayledLayer>
  );
}

function CreateNewLecture(props) {
  const { t } = useTranslation();
  const { itemToEdit, show } = props;
  const queryClient = useQueryClient();
  const { id } = useParams();
  React.useEffect(() => {
    if (itemToEdit && show) {
      formik.setFieldValue("name", itemToEdit.name);
    }
  }, [itemToEdit]);
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
    }),

    onSubmit: async (values) => {
      try {
        // Handle form submission here
        console.log(values);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
          },
        };
        const data = { ...values };

        if (itemToEdit) {
          await axios.put(
            `${process.env.REACT_APP_API_URL}/lecture/${itemToEdit.id}?chapiterId=${props.chapiterId}&courseId=${id}`,
            { name: values.name },
            config
          );

          // Get the current cached data
          let currentChapiters = queryClient.getQueryData(["chapiters", id]);
          if (currentChapiters) {
            let updated = currentChapiters.map((item) => {
              if (props.chapiterId === item.id) {
                item.lectures.map((lecture) => {
                  if (lecture.id === itemToEdit.id) {
                    lecture.name = values.name;
                  }
                });
              }
              return item;
            });

            queryClient.setQueryData(["chapiters", id], [...updated]);

            // Invalidate the query to refetch the updated data
            queryClient.invalidateQueries(["chapiters", id]);
          }

          // currentChapiters = currentChapiters.map((item) => {
          //   if (item.id === itemToEdit.id) {
          //     item.name = values.name;
          //   }
          //   return item;
          // });
          props.onHide();
          formik.resetForm();
          // Show success alert
          Swal.fire({
            title: t("success"),
            text: t("updateChapiterSuccess"),
            icon: "success",
            timer: 1000,
            showCancelButton: false,
            showConfirmButton: false,
            timerProgressBar: true,
          });
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/lecture?chapiterId=${props.chapiterId}&courseId=${id}`,
            { name: values.name },
            config
          );
          const currentChapiters = queryClient.getQueryData(["chapiters", id]);

          // Update the cache by appending the new article
          if (currentChapiters) {
            let updated = currentChapiters.map((item) => {
              if (props.chapiterId === item.id) {
                item.lectures.push(response.data.lecture);
              }
              return item;
            });

            queryClient.setQueryData(["chapiters", id], [...updated]);

            // Invalidate the query to refetch the updated data
            queryClient.invalidateQueries(["chapiters", id]);

            // Show success alert
            Swal.fire({
              title: t("success"),
              text: t("successAddLecture"),
              icon: "success",
              timer: 1000,
              showCancelButton: false,
              showConfirmButton: false,
              timerProgressBar: true,
            });
          }

          props.onHide();
          formik.resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
        // Show error alert
        Swal.fire({
          title: t("error"),
          text: "Something went wrong. Please try again later.",
          icon: "error",
          timer: 1000,
          showCancelButton: false,
          showConfirmButton: false,
          timerProgressBar: true,
        });
      }
    },
  });

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {itemToEdit ? "Edit " : "New "} {t("lecture")} : {props.chapiterId}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-xxl-12 col-xl-12 col-md-12">
            <div>
              <label htmlFor="name">{t("name")} :</label>
              <input
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className="course__form-input"
              />
              {formik.touched.name && formik.errors.name ? (
                <div>{formik.errors.name}</div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="e-btn">
            {itemToEdit ? t("update") : t("create")}
          </Button>
          <Button onClick={props.onHide} className="e-btn btn-gray">
            {t("close")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}

function CreateChapiterModal(props) {
  const { itemToEdit, show } = props;

  const queryClient = useQueryClient();
  const { id } = useParams();
  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      try {
        // Handle form submission here
        console.log(values);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
          },
        };
        const data = { ...values };

        if (itemToEdit) {
          await axios.put(
            `${process.env.REACT_APP_API_URL}/chapiter/${itemToEdit.id}?courseId=${id}`,
            data,
            config
          );
          // Get the current cached data
          let currentChapiters = queryClient.getQueryData(["chapiters", id]);
          currentChapiters = currentChapiters.map((item) => {
            if (item.id === itemToEdit.id) {
              item.name = values.name;
            }
            return item;
          });

          // Update the cache by appending the new article
          queryClient.setQueryData(["chapiters", id], currentChapiters);

          // Invalidate the query to refetch the updated data
          queryClient.invalidateQueries(["chapiters", id]);

          // Show success alert
          Swal.fire({
            title: "Success!",
            text: "Chapiter updated successfully.",
            icon: "success",
            timer: 1000,
            showCancelButton: false,
            showConfirmButton: false,
            timerProgressBar: true,
          });
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/chapiter?id=${id}`,
            data,
            config
          );
          // Get the current cached data
          const currentChapiters = queryClient.getQueryData(["chapiters", id]);
          console.log(response.data.chapiter);
          console.log(currentChapiters);
          // Update the cache by appending the new article
          queryClient.setQueryData(
            ["chapiters", id],
            [...currentChapiters, { ...response.data.chapiter, lectures: [] }]
          );

          // Invalidate the query to refetch the updated data
          queryClient.invalidateQueries(["chapiters", id]);

          // Show success alert
          Swal.fire({
            title: "Success!",
            text: "Chapiter added successfully.",
            icon: "success",
            timer: 1000,
            showCancelButton: false,
            showConfirmButton: false,
            timerProgressBar: true,
          });
        }

        props.onHide();
        formik.resetForm();
      } catch (error) {
        console.error("Error:", error);
        // Show error alert
        Swal.fire({
          title: "Error!",
          text: "Something went wrong. Please try again later.",
          icon: "error",
          timer: 1000,
          showCancelButton: false,
          showConfirmButton: false,
          timerProgressBar: true,
        });
      }
    },
  });

  React.useEffect(() => {
    if (itemToEdit && show) {
      formik.setFieldValue("name", itemToEdit.name);
    }
  }, [itemToEdit]);
  const { t } = useTranslation();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {" "}
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {itemToEdit ? "Edit " : "New "} {t("chapiter")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <h4>Centered Modal</h4> */}

          <div className="col-xxl-12 col-xl-12 col-md-12">
            <div>
              <label htmlFor="name"> {t("name")}:</label>
              <input
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className="course__form-input"
              />
              {formik.touched.name && formik.errors.name ? (
                <ErrorMsg error={formik.errors.name} />
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="e-btn">
            {itemToEdit ? t("update") : t("create")}
          </Button>
          <Button onClick={props.onHide} className="e-btn btn-gray">
            {t("close")}
          </Button>
        </Modal.Footer>{" "}
      </form>
    </Modal>
  );
}

function UpdateResourceModal(props) {
  const queryClient = useQueryClient();
  const { id } = useParams();
  const handleDeleteLecture = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this class!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // User clicked the confirm button
        // Perform the deletion action here
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          };

          // const response = await axios.delete(
          //   `${process.env.REACT_APP_API_URL}/class/${item.id}/${item.course.id}`,
          //   config
          // );

          // if (response.status === 200) {
          //   // Successful deletion
          //   Swal.fire({
          //     icon: "success",
          //     title: "Deleted!",
          //     text: "Your class has been deleted.",
          //   });
          //   refetch();
          // } else {
          //   // Handle other response statuses if needed
          //   Swal.fire({
          //     icon: "error",
          //     title: "Error",
          //     text: "Failed to delete class. Please try again later.",
          //   });
          // }
        } catch (error) {
          // Handle errors
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while deleting the class. Please try again later.",
          });
          console.error("Delete class error:", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked the cancel button or closed the modal
        Swal.fire({
          icon: "error",
          title: "Cancelled",
          text: "Your class is safe :)",
        });
      }
    });
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>Centered Modal</h4>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="e-btn btn-danger"
          style={{ backgroundColor: "red" }}
          onClick={handleDeleteLecture}
        >
          Delete
        </Button>
        <Button
          onClick={props.onHide}
          className="e-btn btn-default"
          style={{ backgroundColor: "gray" }}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
