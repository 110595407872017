import React, { ReactElement, useEffect } from "react";

import { Button, Card, Container, Table } from "react-bootstrap";
import Moment from "react-moment";
// import "moment-timezone";

import axios from "axios";

import { useDispatch } from "react-redux";

import styled from "styled-components";

import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { onOpenTicket } from "../../redux/features/ticket-slice";
import { FiPlus } from "react-icons/fi";
import CreateTcicket from "./CreateTcicket";
import { useTranslation } from "react-i18next";
import Pagination from "../common/pagination/pagination";

const logo = "/assets/img/logo/logo-text-2.png";
const StyledCard = styled(Card)`
  margin-top: 150px;
  margin-bottom: 100px;
`;

const StyledTitle = styled.h2`
  font-size: 24px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const SupportLogged = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = React.useState(1);
  const dispatch = useDispatch();
  const {
    data: tickets,
    isLoading,
    error,
    isPreviousData,
  } = useQuery(
    ["tickets", currentPage],
    async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      };

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/tickets?search=&page=${currentPage}`,
        config
      );
      return response.data;
    },
    {
      keepPreviousData: true,
    }
  );

  const handlePreviousPage = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => prev + 1);
  };

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <Container>
      <StyledCard>
        <Card.Body>
          <div className="text-center">
            <img src={logo} alt="logo nafidh" placeholder="blur" />
            <StyledTitle>نحن دائما هنا لمساعدتك</StyledTitle>
            <div style={{ margin: "30px 0" }}>
              <Button
                variant="primary"
                onClick={() => dispatch(onOpenTicket())}
              >
                <FiPlus size={24} />
                إنشاء تذكرة
              </Button>
            </div>
            <Table responsive="sm" className="table-support">
              <thead>
                <tr>
                  <Th className="ticker-date">{t("date creation")}</Th>
                  <Th className="ticker-status">{t("status")}</Th>
                  <Th className="ticker-subject">{t("subject")}</Th>
                  <Th className="ticker-number">{t("ticket number")} </Th>

                  <Th style={{ width: "70px !important" }}>#</Th>
                </tr>
              </thead>
              <tbody>
                {tickets?.map((item, index) => (
                  <tr key={index}>
                    <Td style={{ paddingTop: "20px" }}>
                      <Moment format="YYYY/MM/DD">{item?.createdAt}</Moment>
                    </Td>
                    <Td>
                      <div className="flex-full-center">
                        <div
                          className={`support-badge-open ${
                            item?.status === "open"
                              ? "open"
                              : item?.status === "closed"
                              ? "closed"
                              : "cancled"
                          }`}
                        >
                          {item?.status}
                        </div>
                      </div>
                    </Td>
                    <Td style={{ textAlign: "center", paddingTop: "20px" }}>
                      <Link to={`/support/ticket?id=${item?.id}`}>
                        <a>{item?.subject}</a>
                      </Link>
                    </Td>
                    <Td style={{ paddingTop: "20px" }}> #{item?.id}</Td>

                    <Td style={{ paddingTop: "20px" }}>{index + 1}</Td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              handlePageClick={handlePreviousPage}
              pageCount={1}
              currentPage={currentPage}
            />
          </div>
        </Card.Body>
      </StyledCard>
    </Container>
  );
};

export default SupportLogged;

const Th = styled.th``;
const Td = styled.td`
  margin-top: 10px;
`;
