import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMsg from "./error-msg";
// Import the CSS file with your form styles
import styled from "styled-components";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import { FaSpinner, FaRegTrashAlt } from "react-icons/fa";
import DropZoneFile from "../DropZone/Dropzone";
import FlexBox from "../FlexBox";

import Box from "../Box";
import Image from "../Image";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";

const UploadImageBox = styled(Box)({
  width: 200,
  height: 200,
  display: "flex",
  overflow: "hidden",
  borderRadius: "8px",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "gray",
});

const DeleteButton = styled.div`
  position: absolute;
  top: 90px;
  left: 90px;
  background-color: red;
  color: white;
  width: 30px;
  padding: 3px;
  border-radius: 12px;
  cursor: pointer;
`;

const CreateCourseForm = () => {
  const [search] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [skills, setSkills] = useState([]);
  const fetchCategories = () => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/client/categories`)
        .then((res) => {
          setCategories(res.data.data);
        });
    } catch (error) {}
  };
  const fetchCourse = (id) => {
    try {
      const options = {
        headers: {
          //"Content-Type": selectedFile.type,

          Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
        },
      };
      axios
        .get(`${process.env.REACT_APP_API_URL}/my-course/${id}`, options)
        .then((res) => {
          const courseData = res.data.course;
          formik.setFieldValue("name", courseData.name);
          formik.setFieldValue("description", courseData.description);
          formik.setFieldValue("overview", courseData.overview);
          setImage(courseData.image);
          formik.setFieldValue("videoUrl", courseData.videoUrl || "");

          //need to get id of categories and skills, and languagaes
          formik.setFieldValue("category", courseData.category.id);
          formik.setFieldValue("skillLevel", courseData.skill_level.id);
          formik.setFieldValue("language", courseData.language.id);
          formik.setFieldValue("duration", courseData.duration);
          formik.setFieldValue("price", courseData.price);
        });
    } catch (error) {}
  };
  const fetchLanguage = () => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/client/languages`)
        .then((res) => {
          setLanguages(res.data.data);
        });
    } catch (error) {}
  };
  const fetchSkills = () => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/client/skill-levels`)
        .then((res) => {
          setSkills(res.data.data);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchCategories();
    fetchLanguage();
    fetchSkills();
    if (search.get("course-id")) {
      fetchCourse(search.get("course-id"));
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      image: "",
      videoUrl: "",
      category: "",
      duration: "",
      skillLevel: "",
      overview: "",
      language: "",
      certificate: false,
      price: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      category: Yup.string().required("Required"),
      duration: Yup.number()
        .required("Required")
        .positive("Must be a positive number"),
      overview: Yup.string().required("Required"),
      description: Yup.string().required("Required"),
      skillLevel: Yup.string().required("Required"),
      language: Yup.string().required("Required"),
      price: Yup.number()
        .required("Required")
        .positive("Must be a positive number"),
    }),
    onSubmit: async (values) => {
      // Handle form submission here

      try {
        if (!image) {
          Swal.fire({
            icon: "error",
            title: "Error!",
            text: "You have to upload a cover",
            showConfirmButton: false,
            timer: 1000, // Time in milliseconds (1 second = 1000 milliseconds)
          });
          return;
        }
        setloading(true);
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
          },
        };
        let data = {
          ...values,
        };
        //if course id exit should update course,
        if (search.get("course-id")) {
          // need to check if image is updated of not
          // if yes we should upload it and post url with Data to course,
          if (imageFile) {
            console.log(imageFile);
            // upload image first
            const formData = new FormData();
            formData.append("files", imageFile); // Ensure blob is a valid Blob object
            const options = {
              headers: {
                //"Content-Type": selectedFile.type,
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
              },
            };
            const result = await axios
              .post(
                `${process.env.REACT_APP_API_URL}/upload/`,
                formData,
                options
              )
              .catch((error) => {
                alert("Something wrong");
                setloading(false);
                return;
              });
            data = {
              ...data,
              image: result?.data[0].url,
            };
          }

          await axios
            .put(
              `${process.env.REACT_APP_API_URL}/course/${search.get(
                "course-id"
              )}`,
              data,
              config
            )
            .then((res) => {
              setloading(false);
              Swal.fire({
                icon: "success",
                title: "success!",
                text: "Your course has been updated",
                showConfirmButton: false,
                timer: 1000, // Time in milliseconds (1 second = 1000 milliseconds)
              }).then(() => {
                navigate(`/dashboard/my-course/${search.get("course-id")}`);
              });

              // navigate(`/dashboard/my-course/${res.data.id}`);
            });
        } else {
          if (imageFile) {
            console.log(imageFile);
            // upload image first
            const formData = new FormData();
            formData.append("files", imageFile); // Ensure blob is a valid Blob object
            const options = {
              headers: {
                //"Content-Type": selectedFile.type,
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
              },
            };
            const result = await axios.post(
              `${process.env.REACT_APP_API_URL}/upload/`,
              formData,
              options
            );
            data = {
              ...data,
              image: result?.data[0].url,
            };

            axios
              .post(`${process.env.REACT_APP_API_URL}/course`, data, config)
              .then((res) => {
                setloading(false);
                Swal.fire({
                  icon: "success",
                  title: "success!",
                  text: "Your compte has been created.",
                  showConfirmButton: false,
                  timer: 1000, // Time in milliseconds (1 second = 1000 milliseconds)
                }).then(() => {
                  navigate(`/dashboard/my-course/${res.data.id}`);
                });
              });
          } else {
            alert("moucghkel");
          }
          setloading(false);
        }
      } catch (error) {
        setloading(false);
        console.log(error);
        alert(error);
      }
    },
  });
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = async (files) => {
    const selectedFile = files[0];
    setImageFile(selectedFile);
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
      setIsLoading(true);
    };

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          {/* Add your form class here */}
          {/* <GridSection> */}
          <div>
            <div className="col-xxl-12 col-xl-12 col-md-12">
              <div>
                <label htmlFor="name">{t("name")}:</label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className="course__form-input"
                />
                {formik.touched.name && formik.errors.name ? (
                  <ErrorMsg error={formik.errors.name} />
                ) : null}
              </div>
            </div>
            <div className="col-xxl-12 col-xl-12 col-md-12">
              <div className="course__form-input">
                <label htmlFor="overview">{t("overview")} :</label>
                <textarea
                  id="overview"
                  name="overview"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.overview}
                  className="blog__comment"
                />
                {formik.touched.overview && formik.errors.overview ? (
                  <ErrorMsg error={formik.errors.overview} />
                ) : null}
              </div>
            </div>
          </div>
          {/* </GridSection> */}
          {image ? (
            <FlexBox flexDirection="row" mt={2} flexWrap="wrap">
              <UploadImageBox mr=".5rem" style={{ textAlign: "center" }}>
                <DeleteButton
                  onClick={() => {
                    setImageFile(null);
                    setImage(null);
                  }}
                >
                  <FaRegTrashAlt />
                </DeleteButton>
                <Image
                  src={
                    imageFile
                      ? image
                      : process.env.REACT_APP_DOMAIN_BACKEND + image
                  }
                  width="100%"
                />
              </UploadImageBox>
            </FlexBox>
          ) : (
            <DropZoneFile onChange={(e) => handleFileChange(e)} />
          )}
          {/* <Grid item xs={12}>
          {image <= 10 && (
            <DropZone onChange={(files) => handleImageUpload(files)} />
          )}
        </Grid> */}

          {/* Similar fields for videoUrl, category, duration, skillLevel, language, certificate, and price */}
          <div className="col-xxl-12 col-xl-12 col-md-12">
            <div className="course__form-input">
              <label htmlFor="description">{t("full description")} :</label>
              <textarea
                id="description"
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                className="blog__comment"
              />
              {formik.touched.description && formik.errors.description ? (
                <ErrorMsg error={formik.errors.description} />
              ) : null}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4">
            <div className="cod__form-input">
              <label htmlFor="category">{t("category")}:</label>
              <select
                id="category"
                name="category"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.category}
              >
                <option value="">{t("select a category")}</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
              </select>
              {formik.touched.category && formik.errors.category ? (
                <ErrorMsg error={formik.errors.category} />
              ) : null}
            </div>
          </div>

          <div className="col-xxl-4 col-xl-4 col-md-4">
            <div className="cod__form-input">
              <label htmlFor="language">{t("language's course")}:</label>
              <select
                id="language"
                name="language"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.language}
              >
                <option value="">{t("select a language")} </option>
                {languages.map((language) => (
                  <option key={language.id} value={language.id}>
                    {language.name}
                  </option>
                ))}
              </select>
              {formik.touched.language && formik.errors.language ? (
                <ErrorMsg error={formik.errors.language} />
              ) : null}
            </div>
          </div>
          <div className="col-xxl-4 col-xl-4 col-md-4">
            <div className="cod__form-input">
              <label htmlFor="duration">{t("duration")}:</label>
              <input
                id="duration"
                name="duration"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.duration}
                type="number"
                className="contact__form-input"
              />
              {formik.touched.duration && formik.errors.duration ? (
                <ErrorMsg error={formik.errors.duration} />
              ) : null}
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-md-6">
            <div className="cod__form-input">
              <label htmlFor="language">{t("skill")}:</label>
              <select
                id="skill"
                name="skillLevel"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.skillLevel}
              >
                <option value="">{t("select a skill")}</option>
                {skills.map((skill) => (
                  <option key={skill.id} value={skill.id}>
                    {skill.name}
                  </option>
                ))}
              </select>
              {formik.touched.skillLevel && formik.errors.skillLevel ? (
                <ErrorMsg error={formik.errors.skillLevel} />
              ) : null}
            </div>
          </div>
          <div className="col-xxl-6 col-xl-6 col-md-6">
            <div>
              <div>
                <label htmlFor="price">{t("price")}:</label>
                <div
                  style={{ display: "grid", gridTemplateColumns: "60px auto" }}
                >
                  <div style={{ padding: "16px" }}>{t("price-dz-symbole")}</div>
                  <input
                    id="price"
                    name="price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.price}
                    type="number"
                    className="contact__form-input"
                  />
                </div>
                {formik.touched.price && formik.errors.price ? (
                  <ErrorMsg error={formik.errors.price} />
                ) : null}
              </div>
            </div>
          </div>
          {/* Similar inputs for duration, skillLevel, language, certificate, and price */}
          {/* Submit button */}
          <Button
            type="submit"
            disabled={loading}
            style={{ maxWidth: "200px", width: "100%", margin: "5px 10px" }}
          >
            {loading ? (
              <span class="loader"></span>
            ) : search.get("course-id") ? (
              t("update")
            ) : (
              t("submit")
            )}
          </Button>

          {/* <button
          type="submit"
          className="e-btn w-100"
          style={{ marginTop: "30px", maxWidth: "200px" }}
        >
          <span></span>
        </button> */}
        </div>
      </form>
    </>
  );
};
const FileUploadContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 16vw;
  padding: 10px;
  

  @media (min-width: 768px) {
    width: 16vw;  Adjust for larger screens */
  }
`;

const UploadInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;

  opacity: 0;
  z-index: 2;
  cursor: pointer;
`;

const UploadLabel = styled.label`
  display: block;
  width: 100%;
  height: 100%;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  line-height: 15vw;
  font-size: 14px;
  color: #555;
  cursor: pointer;

  &:hover {
    border-color: #999;
  }
`;

const UploadContent = styled.div`
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px;
`;

const GridSection = styled.div`
  display: grid;
  grid-template-columns: 100%; /* Initially takes full width */

  @media (min-width: 768px) {
    grid-template-columns: 18vw auto; /* Adjust for larger screens */
  }
`;

const LoadingIcon = styled(FaSpinner)`
  font-size: 24px;
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  color: #555;
  z-index: 3;
`;
const DeleteDiv = styled.div`
  position: absolute;

  top: calc(50% - 24px);
  left: calc(50% - 24px);
  cursor: pointer;
  background-color: white;
  color: white;
  padding: 10px;
  z-index: 6;
  cursor: pointer;
  border-radius: 12px;
`;
const DeleteIcon = styled(FaRegTrashAlt)`
  font-size: 36px;

  cursor: pointer;

  color: #ff0000;
`;
const SelectedImage = styled.div`
  background-color: black;
  position: absolute;
  top: 10px;
  left: 10px;
  bottom: 10px;
  right: 10px;
  z-index: 5;
  opacity: 0.6;
`;

export default CreateCourseForm;
