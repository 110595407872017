import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useQuery } from "react-query";
import axios from "axios";
// import Pagination from "../pagination/pagination";
import {
  add_to_wishlist,
  wishlistItems,
} from "../../../redux/features/wishlist-slice";
import CourseItem from "./CourseItem";
import CourseDisplayOptions from "./CourseDisplayOptions";
import Pagination from "../pagination/pagination";
import { useSearchParams, useNavigate } from "react-router-dom";
import FilterSidebar from "./FilterSidebar";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const CourseList = ({
  path,
  sidebar,
  subPage,
  withoutPagination,
  pageSize = 10,
}) => {
  const { t } = useTranslation();
  const [list, setList] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  const { isLoading, isError, data, error, refetch } = useQuery(
    [path, page],
    () => fetchCourses(page),
    {
      enabled: !!path, // Ensures the query is only enabled when 'page' is truthy
    }
  );

  async function fetchCourses(page) {
    let config = {
      headers: {},
    };
    if (localStorage.getItem("jwt")) {
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      };
    }
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/${path}?page=${page}&pageSize=${pageSize}`,
      config
    );
    return response.data;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  }

  const handlePageClick = (event) => {};

  return (
    <>
      {data?.courses.length === 0 ? (
        <Alert variant="info">{t("public.noCoursesAvailable")}</Alert>
      ) : (
        // Code to display available courses goes here

        <Row>
          {sidebar && (
            <Col md="3">
              <FilterSidebar
                title="Categories"
                url={`${process.env.REACT_APP_API_URL}/client/categories`}
              />
              <FilterSidebar
                title="Languages"
                url={`${process.env.REACT_APP_API_URL}/client/languages`}
              />
            </Col>
          )}
          <Col md={sidebar ? "9" : "12"}>
            <Row md="12">
              {!withoutPagination && (
                <CourseDisplayOptions
                  list={list}
                  showList={setList}
                  totalPages={data?.totalPages}
                  currentPage={page || 0}
                />
              )}

              {data?.courses?.map((course, index) => (
                <CourseItem
                  course={course}
                  key={course.id}
                  list={list}
                  sidebar={sidebar}
                />
              ))}
              {!withoutPagination && data && (
                <Pagination
                  handlePageClick={(page) =>
                    navigate(
                      `${subPage ? `/${subPage}` : ""}/${path}?page=${
                        page.selected + 1
                      }`
                    )
                  }
                  pageCount={data?.totalPages}
                  currentPage={page > 0 ? page - 1 : 0}
                />
              )}
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CourseList;
