import styled from "styled-components";
import { border, layout, space } from "styled-system";

const Image = styled.img`
  ${space}
  ${border}
  ${layout}
`;

export default Image;
