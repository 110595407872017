import React from "react";
import axios from "axios";
import { useQuery } from "react-query";
import { Spinner, Alert } from "react-bootstrap";

export default function FilterSidebar({ title, url }) {
  const fetchItems = async () => {
    const response = await axios.get(url); // Fetch data using axios from the provided URL
    return response.data;
  };

  const { data, isLoading, isError } = useQuery(title, fetchItems, {
    cacheTime: 20 * 24 * 60 * 60 * 1000, // Cache for 20 days
  });

  if (isLoading) return <Spinner animation="border" />;
  if (isError) return <Alert variant="danger">Error fetching data</Alert>;

  return (
    <div className="course__sidebar-widget grey-bg">
      <div className="course__sidebar-info">
        <h3 className="course__sidebar-title">{title}</h3>
        <ul>
          {data?.data.map((item, index) => (
            <li key={index} onClick={() => console.log(item)}>
              <div className="course__sidebar-check mb-10 d-flex align-items-center">
                <input
                  onChange={() => console.log(item)}
                  className="m-check-input"
                  type="checkbox"
                  id={`m-${index}`}
                />
                <label className="m-check-label" htmlFor={`m-${index}`}>
                  {item.name}
                </label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
