export function getFileType(fileName) {
  const extension = fileName.split(".").pop().toLowerCase();
  const videoExtensions = ["mp4", "avi", "mov", "mkv", "wmv"];
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
  const pdfExtensions = ["pdf"];

  if (videoExtensions.includes(extension)) {
    return "video";
  } else if (imageExtensions.includes(extension)) {
    return "image";
  } else if (pdfExtensions.includes(extension)) {
    return "pdf";
  } else {
    return "file";
  }
}
