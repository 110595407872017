import React from "react";
// import styled from "styled-components";
// // import { Link } from "react-router-dom";
// const H2 = styled.div``;
import { Row, Col, Container } from "react-bootstrap";
import SidebarOrganization from "../../../../components/common/Sidebar/SidebarOrganization";
import CreateCourseForm from "../../../../components/common/forms/course-form";
import Header from "../../../../layout/headers/header";
import Wrapper from "../../../../layout/wrapper";
import ContentDashboard from "../../../../layout/layer/Layer";

const InstructorsDashboard = () => {
  return (
    <Wrapper>
      <Header />
      <section className=" po-rel-z1 pt-130 pb-145">
        <SidebarOrganization />
        <ContentDashboard>
          <h2>InstructorsDashboard</h2>
          <CreateCourseForm />
        </ContentDashboard>
      </section>
    </Wrapper>
  );
};
export default InstructorsDashboard;
