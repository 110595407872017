import React from "react";
import { useQuery } from "react-query";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import {
  Tabs,
  Tab,
  Accordion,
  ListGroup,
  Card,
  Badge,
  Button,
  Nav,
} from "react-bootstrap";
import { LiaComments } from "react-icons/lia";
import ProgressBar from "react-bootstrap/ProgressBar";
import { RiRibbonAltLine } from "react-icons/ri";
import Teacher from "../common/teacher/Teacher";
import TeachersSection from "../common/teacher/Teacher";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PiChalkboardTeacherLight } from "react-icons/pi";
import { IoMdBook } from "react-icons/io";
import { CiTimer } from "react-icons/ci";
import { CiUser } from "react-icons/ci";
import { IoLanguageOutline } from "react-icons/io5";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import ErrorMsg from "../common/forms/error-msg";
import { io } from "socket.io-client";

export default function CourseDetails() {
  const navigate = useNavigate();
  const { data, isLoading, isError } = useCourseDetails();
  console.log(data);
  React.useEffect(() => {
    // if (data?.course.isAllowedToAccess) {
    //   navigate(`/page/course/${data?.course?.id}/first`);
    // }
  }, [data]);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching course details</div>;
  }
  return (
    <>
      <Container>
        <Row>
          <Col md="8">
            {data && (
              <>
                <Row>
                  <Col>
                    <span className="page__title-pre">
                      {data.course?.category?.name}
                    </span>
                    <h5 className="page__title-3">{data.course?.name}</h5>
                  </Col>
                </Row>
                <Row className="course__meta-2 d-sm-flex mb-30">
                  <Col className="course__teacher-3 d-flex align-items-center mr-70 mb-30">
                    <div className="course__teacher-thumb-3 mr-15">
                      <Image
                        src={
                          data?.course?.instructor?.avatar?.url
                            ? process.env.REACT_APP_DOMAIN_BACKEND +
                              data?.course?.instructor?.avatar?.url
                            : "https://avatars.hsoubcdn.com/default?s=128"
                        }
                        alt=""
                      />
                    </div>
                    <div className="course__teacher-info-3">
                      <h5>Teacher</h5>
                      <p>
                        <Link
                          to={"/profile/" + data.course?.instructor.username}
                        >
                          {data.course?.instructor.fullname}
                        </Link>
                      </p>
                    </div>
                  </Col>
                  <Col className="course__update mr-80 mb-30">
                    <h5>Last Update:</h5>
                    <p>
                      <Moment format="YYYY/MM/DD  HH:mm">
                        {data.course?.updatedAt}
                      </Moment>
                    </p>
                  </Col>
                  <Col className="course__rating-2 mb-30">
                    <h5>Review:</h5>
                    <div className="course__rating-inner d-flex align-items-center">
                      <ul>
                        {[...Array(5)].map((_, index) => (
                          <li key={index}>
                            <a href="#">
                              <i
                                style={{
                                  color:
                                    index < data.course.total_rating
                                      ? "yallow"
                                      : "gray",
                                }}
                                className={"icon_star"}
                              ></i>
                            </a>
                          </li>
                        ))}
                      </ul>
                      {/* <ul>
                        {[...Array(data.course?.total_rating)].map(
                          (_, index) => (
                            <li key={index}>
                              <a href="#">
                                <i className="icon_star"></i>
                              </a>
                            </li>
                          )
                        )}
                      </ul> */}
                      <br />
                      <p>{data.course?.rating || 0}</p>
                    </div>
                  </Col>
                </Row>
                <Row className="course__img w-img mb-30">
                  <Col>
                    <Image
                      src={
                        process.env.REACT_APP_DOMAIN_BACKEND +
                        data.course?.image
                      }
                      alt=""
                    />
                  </Col>
                </Row>
              </>
            )}
            {data?.course && <Details course={data?.course} />}
          </Col>
          <Col md="4">
            <>
              <div className="course__sidebar pl-70 p-relative">
                <div className="course__shape">
                  <img
                    className="course-dot"
                    src="/assets/img/course/course-dot.png"
                    alt=""
                  />
                </div>
                <div className="course__sidebar-widget-2 white-bg mb-20">
                  <div className="course__video">
                    {/* <div className="course__video-thumb w-img mb-25">
                      <img
                        src="/assets/img/course/video/course-video.jpg"
                        alt=""
                      />
                      <div className="course__video-play">
                        <button
                          //   onClick={() => setOpen(true)}
                          className="play-btn"
                        >
                          <i className="fas fa-play"></i>{" "}
                        </button>
                      </div>
                    </div> */}

                    <div className="course__video-meta mb-25 d-flex align-items-center justify-content-between">
                      <div className="course__video-price">
                        <h5>
                          {data?.course.price !== " free" && " DA "}
                          {data?.course.price.toFixed(2)}
                          <span></span>{" "}
                        </h5>
                        {data?.course.discount_price && (
                          <h5 className="old-price">
                            {data?.course.discount_price.toFixed(2)} {" DA"}
                          </h5>
                        )}
                      </div>
                      {data?.course.discount_price && (
                        <div className="course__video-discount">
                          <span>68% OFF</span>
                        </div>
                      )}
                    </div>

                    <ListGroup variant="flush">
                      <ListGroup.Item>
                        <b className="mr-5">
                          {" "}
                          <PiChalkboardTeacherLight className="mr-5" />
                          Instructor:
                        </b>
                        {data?.course.instructor.fullname}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b className="mr-5">
                          {" "}
                          <IoMdBook className="mr-5" />
                          Lectures:
                        </b>
                        {data?.course.total_lectures}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b className="mr-5">
                          {" "}
                          <CiTimer className="mr-5" />
                          Duration:
                        </b>
                        {data?.course.duration}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b className="mr-5">
                          <CiUser className="mr-5" />
                          Enrolled:
                        </b>{" "}
                        {data?.course.enrolledStudentSCount}
                      </ListGroup.Item>
                      <ListGroup.Item>
                        <b className="mr-5">
                          <IoLanguageOutline className="mr-5" />
                          Language:
                        </b>
                        {data?.course.language}
                      </ListGroup.Item>
                    </ListGroup>
                    {!data?.course.isAllowedToAccess && (
                      <div className="course__payment mb-35 mt-35">
                        <h3>Payment:</h3>
                        <a href="#">
                          <img
                            style={{ margin: "5px", width: "60px" }}
                            src="https://scontent.fblj1-2.fna.fbcdn.net/v/t39.30808-6/302246584_752039645882842_6174621250866333023_n.png?_nc_cat=109&ccb=1-7&_nc_sid=efb6e6&_nc_eui2=AeEHOrz9pKhBnfZs8nditKu5kePYlGDXjRiR49iUYNeNGD-xQ5Qoka-WU85cEJdmQSE&_nc_ohc=evq9ny58XVAAX-kXEI7&_nc_ht=scontent.fblj1-2.fna&oh=00_AfAi8onOfoj1tyRs1urMCXqtL9UK6TULhnH0l40CLh3wkQ&oe=65787093"
                            alt=""
                          />
                          <img
                            style={{
                              margin: "5px",
                              width: "40px",
                              backgroundColor: "white",
                            }}
                            src="https://upload.wikimedia.org/wikipedia/commons/7/72/AlgeriePoste.svg"
                          />
                        </a>
                      </div>
                    )}
                    <div className="course__payment mb-35 mt-35">
                      {data?.course.isUserEnrolled &&
                      !data?.course.isAllowedToAccess ? (
                        <Button
                          disabled
                          // onClick={() => dispatch(cart_course(course))}
                          className="e-btn e-btn-7 w-100"
                        >
                          Request Sent
                        </Button>
                      ) : data?.course.isAllowedToAccess ? (
                        <Button
                          onClick={() =>
                            navigate(`/page/course/${data?.course?.id}/first`)
                          }
                          className="e-btn e-btn-7 w-100"
                        >
                          My Course
                        </Button>
                      ) : (
                        data?.course.classes.map((item) => (
                          <Button
                            onClick={() =>
                              navigate(
                                `/payment/checkout?classId=${item.id}&courseId=${data?.course.id}`
                              )
                            }
                            style={{ margin: "10px 0px" }}
                            className="e-btn e-btn-7 w-100 "
                          >
                            ({item.name}) join now
                            <i className="far fa-arrow-right"></i>
                          </Button>
                        ))
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export function Details({ course }) {
  console.log(course);

  if (!course) return <div>sdsd</div>;
  return (
    <>
      <Tabs
        defaultActiveKey="discription"
        id="justify-tab-example"
        className="course__tab-2 mb-3"
        variant="pills"
        justify
      >
        <Tab
          eventKey="discription"
          title={
            <div>
              <i className="icon_ribbon_alt"></i> <span>Discription</span>
            </div>
          }
        >
          <h3>Course Overview</h3>
          {course.description}
          <TeachersSection teachers={[]} />
        </Tab>
        <Tab
          eventKey="curriculum"
          title={
            <div>
              <i className="icon_book_alt"></i> <span>Curriculum</span>{" "}
            </div>
          }
        >
          <Accordion defaultActiveKey="0">
            {course.chapiters.map((chapiter, index) => (
              <Accordion.Item eventKey={index} style={{ marginBottom: "20px" }}>
                <Accordion.Header style={{ backgroundColor: "#F3F4F8" }}>
                  <b> {chapiter.name}</b>
                </Accordion.Header>
                <Accordion.Body style={{ padding: 0 }}>
                  <ListGroup>
                    {chapiter.lectures.map((lecture, index) => (
                      <ListGroup.Item
                        as={Link}
                        to="/course/leactures/12"
                        className="d-flex justify-content-between align-items-start"
                      >
                        <div className="ms-2 me-auto">{lecture.name}</div>
                        {/* <Badge bg="primary" pill>
                        14
                      </Badge> */}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Tab>
        <Tab
          eventKey="reviews"
          title={
            <div>
              <i className="icon_star_alt"></i> <span>Reviews</span>
            </div>
          }
        >
          <h3>Reviews</h3>
          <p>
            Gosh william I'm telling crikey burke I don't want no agro A bit of
            how's your father bugger all mate off his nut that, what a plonker
            cuppa owt to do
          </p>
          <RewiewSection />
        </Tab>
        {course.isUserEnrolled && (
          <Tab
            eventKey="descussion"
            title={
              <div>
                <LiaComments /> <span>Descussion</span>
              </div>
            }
          >
            <h3>Descussion</h3>
            <CourseHub />
          </Tab>
        )}
        {/* 
        <Tab
          eventKey="rembers"
          title={
            <div>
              <i className="fal fa-user"></i> <span>Members</span>
            </div>
          }
        >
          Tab content for Contact
        </Tab> */}
      </Tabs>
    </>
  );
}
function RewiewSection() {
  const { data, isLoading, isError } = useReviewsRange();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching course details</div>;
  }

  return (
    <>
      <div className="course__review-rating mb-50">
        <Row className="g-0">
          <Col xxl={4} xl={4} lg={4} md={4} sm={4}>
            <div className="course__review-rating-info grey-bg text-center">
              <h5>{data.average}</h5>
              <ul>
                {[...Array(5)].map((_, index) => (
                  <li key={index}>
                    <a href="#">
                      <i className="icon_star"></i>
                    </a>
                  </li>
                ))}
                <p>{data.total_reviews} Ratings</p>
              </ul>
            </div>
          </Col>
          <Col xxl={8} xl={8} lg={8} md={8} sm={8}>
            <div className="course__review-details grey-bg">
              <h5>Detailed Rating</h5>
              <div className="course__review-content mb-20">
                {data.rates.map((item, index) => (
                  <div className="course__review-item d-flex align-items-center justify-content-between">
                    <div className="course__review-text">
                      <span>{5 - index} stars</span>
                    </div>
                    <div className="course__review-progress">
                      <ProgressBar now={item === 0 ? 0 : 100 / item} />
                    </div>
                    <div className="course__review-percent">
                      <h5>{item === 0 ? 0 : (100 / item).toFixed(0)}%</h5>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col>
        </Row>
        <StudentReviews />
      </div>
    </>
  );
}
export function useCourseDetails() {
  const { id } = useParams();
  const { data, isLoading, isError } = useQuery(
    ["courseDetails", id],
    async () => {
      let config = {
        headers: {},
      };
      if (localStorage.getItem("jwt")) {
        config.headers = {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        };
      }

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/course/${id}`,
        config
      ); // Replace YOUR_API_ENDPOINT with your API URL

      return response.data;
    }
  );

  return { data, isLoading, isError };
}
export function useReviewsRange() {
  const { id } = useParams();
  const { data, isLoading, isError } = useQuery(
    ["review-range", id],
    async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/review?courseId=${id}`
      ); // Replace YOUR_API_ENDPOINT with your API URL
      let result = response.data;
      result.rates.reverse();
      return result;
    }
  );

  return { data, isLoading, isError };
}

function StudentReviews() {
  const { id } = useParams();
  const [page, setPage] = React.useState(1);
  const [reviews, setReviews] = React.useState([]);
  const [totalReviews, setTotalReviews] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isError, setIsError] = React.useState(false);

  const fetchReviews = async (page) => {
    try {
      setIsLoading(true);

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/reviews?courseId=${id}&page=${
          page || 1
        }`
      );
      setReviews([...reviews, ...response.data.reviews]);
      setTotalReviews(response.data.totalReviews);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  React.useEffect(() => {
    fetchReviews(page);
  }, [id, page]);

  const handleShowMore = () => {
    setPage(page + 1); // Increment page on button click to fetch the next page
  };

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      // Handle form submission here
      console.log(values);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
        },
      };
      const data = {
        ...values,
      };

      axios
        .post(`${process.env.REACT_APP_API_URL}/review`, data, config)
        .then((res) => {
          //navigate(`/dashboard/my-course/${res.data.id}`);
        });
    },
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching course details</div>;
  }

  return (
    <div className="course__comment mb-75 mt-75">
      <h3>({totalReviews}) Reviews</h3>
      <form onSubmit={formik.handleSubmit}>
        <div className="col-xxl-12 col-xl-12 col-md-12">
          <div className="course__form-input">
            <label htmlFor="description">description:</label>
            <textarea
              id="description"
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              className="blog__comment"
            />
            {/* {formik.touched.description && formik.errors.description ? (
              <ErrorMsg error={formik.errors.description} />
            ) : null} */}
          </div>
        </div>
        <button
          type="submit"
          className="e-btn w-100"
          style={{ marginTop: "30px", maxWidth: "200px" }}
        >
          <span></span> Submit
        </button>
      </form>
      <ul>
        {reviews.map((item, i) => (
          <li key={i}>
            <div className="course__comment-box ">
              <div className="course__comment-thumb float-start">
                <img
                  src={
                    item.student.avatar ||
                    "https://avatars.hsoubcdn.com/default?s=128"
                  }
                  alt=""
                />
              </div>
              <div className="course__comment-content">
                <div className="course__comment-wrapper ml-70 fix">
                  <div className="course__comment-info float-start">
                    <h4>{item.student.fullname}</h4>
                    <Moment format="YYYY/MM/DD  HH:mm">
                      {item?.updatedAt}
                    </Moment>
                  </div>
                  <div className="course__comment-rating float-start float-sm-end">
                    <ul>
                      <li>
                        <a href="#">
                          {" "}
                          <i className="icon_star"></i>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          <i className="icon_star"></i>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          <i className="icon_star"></i>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          {" "}
                          <i className="icon_star"></i>{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="icon_star"></i>{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="course__comment-text ml-70">
                  <p>{item.review}</p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {reviews.length < totalReviews && ( // Show "Show more" button if there are more reviews to load
        <button className="e-btn justify-center" onClick={handleShowMore}>
          Show more
        </button>
      )}
    </div>
  );
}
function CourseHub() {
  const { id } = useParams();
  const [page, setPage] = React.useState(1);
  const [messages, setMessages] = React.useState([]);
  const [totalReviews, setTotalReviews] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  const [infocurrentUser, setinfocurrentUser] = React.useState(null);
  const [isError, setIsError] = React.useState(false);

  const fetchReviews = async (page) => {
    try {
      setIsLoading(true);
      alert("Tets");
      const config = localStorage.getItem("jwt")
        ? {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        : {
            headers: {},
          };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/course-hub/${id}?page=${page || 1}`,
        config
      );

      setMessages([...messages, ...response.data.messages]);
      setinfocurrentUser({
        currentUser: response.data.currentUser,
        course_hubId: response.data.course_hubId,
      });
      setTotalReviews(response.data.totalReviews);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
    }
  };

  React.useEffect(() => {
    fetchReviews(page);
  }, [id, page]);

  const handleShowMore = () => {
    setPage(page + 1); // Increment page on button click to fetch the next page
  };
  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object({
      message: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      // Handle form submission here
      console.log(values);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
        },
      };
      const data = {
        ...values,
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/course-hub/message?course_hubId=${infocurrentUser.course_hubId}`,
          data,
          config
        )
        .then((res) => {
          //navigate(`/dashboard/my-course/${res.data.id}`);
        });
    },
  });

  React.useEffect(() => {
    // Create a socket connection

    const newSocket = io(process.env.REACT_APP_DOMAIN_BACKEND, {
      transports: ["websocket"],
      query: {
        token: `${localStorage.getItem("jwt")}`,
      },
    });

    newSocket.emit("connection", () => {
      console.log("Connected to the server!");
    });
    newSocket.on("newMessage", (data) => {
      setMessages((prev) => [data, ...prev]);
      formik.setFieldTouched("message", false); // Disable field touched status
      formik.setFieldValue("message", ""); // Reset field value
    });
    // newSocket.on("message", (data) => {
    //  // const currentConversation =
    //    // queryClient.getQueryData["conversation", id];
    //   // setText("");
    //  // const messages = [...currentConversation.messages, data];

    //   // if (currentConversation) {
    //   //   queryClient.setQueryData(["conversation", id], {
    //   //     ...currentConversation,
    //   //     messages: messages,
    //   //   });
    //   // }
    // });

    // Clean up the socket connection on unmount
    return () => {
      // newSocket?.off("message");
      // newSocket?.off("API");
      newSocket.close();
      // Don't set socket to newSocket here as newSocket is out of scope
    };
  }, []); // Ensure the dependency array is empty for a one-time connection setup

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching course details</div>;
  }

  return (
    <div className="course__comment mb-75 mt-75">
      <h3> Descussion: </h3>
      <form onSubmit={formik.handleSubmit}>
        <div className="col-xxl-12 col-xl-12 col-md-12">
          <div className="course__form-input">
            <label htmlFor="message">message:</label>
            <textarea
              id="message"
              name="message"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.message}
              className="blog__comment"
            />
            {formik.touched.message && formik.errors.message ? (
              <ErrorMsg error={formik.errors.message} />
            ) : null}
          </div>
        </div>
        <button
          type="submit"
          className="e-btn w-100"
          style={{ marginTop: "30px", maxWidth: "200px" }}
        >
          <span></span> Submit
        </button>
      </form>
      <ul>
        {messages.map((item, i) => (
          <li key={i}>
            <div className="course__comment-box ">
              <div className="course__comment-thumb float-start">
                <img
                  src={
                    item.member.avatar ||
                    "https://avatars.hsoubcdn.com/default?s=128"
                  }
                  alt=""
                />
              </div>
              <div className="course__comment-content">
                <div className="course__comment-wrapper ml-70 fix">
                  <div className="course__comment-info float-start">
                    <h4>{item.member.fullname}</h4>
                    <Moment format="YYYY/MM/DD  HH:mm">
                      {item?.updatedAt}
                    </Moment>
                  </div>
                </div>
                <div
                  className="course__comment-text ml-70"
                  style={{
                    backgroundColor:
                      infocurrentUser === item.member.id ? "blue" : "white",
                    borderRadius: "12px",
                    padding: "10px",
                  }}
                >
                  <p>{item.message}</p>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
      {messages.length < totalReviews && ( // Show "Show more" button if there are more reviews to load
        <button className="e-btn justify-center" onClick={handleShowMore}>
          Show more
        </button>
      )}
    </div>
  );
}
