import { createSlice } from "@reduxjs/toolkit";
// import { toast } from "react-toastify";

const initialState = {
  user: {
    fullname: localStorage.getItem("fullname"),
    isSignedIn: !!localStorage.getItem("jwt"),
    avatar: localStorage.getItem("avatar"),
    username: localStorage.getItem("username"),
    isTeacher: localStorage.getItem("isTeacher"),
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    auth_user: (state, { payload }) => {
      state.user = payload.user;
    },
  },
});

export const { auth_user } = userSlice.actions;

export const user = (state) => state.user.user;
export default userSlice.reducer;
