import React, { ReactElement } from "react";
import { Button, Modal, Row, Col, Container } from "react-bootstrap";

import { FiX } from "react-icons/fi";
// import { useAppSelector } from "redux/hooks";

import { BsCheckLg } from "react-icons/bs";

// import UploadedFile from "components/DropZone/Upload_file";
import DropZoneFile from "./common/DropZone/Dropzone";

const steps = [
  {
    status: "الامامية",
    icon: <BsCheckLg size={24} />,
  },
  {
    status: "الخلفية",
    icon: <BsCheckLg size={24} />,
  },
  {
    status: "حمل البطاقة",
    icon: <BsCheckLg size={24} />,
  },
  {
    status: "ارسال",
    icon: <BsCheckLg size={24} />,
  },
];
const imageSuccess = "/assets/images/image 10.svg";
export default function VertifyAccount({ display, handleCloseModal }) {
  const [step, setStep] = React.useState(1);

  const handleClose = () => {
    handleCloseModal();
    setStep(1);
    setverfiy({
      cardFront: null,
      selectedCardFront: false,
      cardBack: null,
      selectedcardBack: false,
      face: null,
      selectedface: false,
      cardFaceId: null,
    });
    // router.push(`/`);
  };
  const [verfiy, setverfiy] = React.useState({
    cardFront: null,
    selectedCardFront: false,
    cardBack: null,
    selectedcardBack: false,
    face: null,
    selectedface: false,
    cardFaceId: null,
  });

  function isDesabled(step) {
    console.log(verfiy);
    if (step === 1) {
      return !verfiy.selectedCardFront;
    } else if (step === 2) {
      return !verfiy.selectedcardBack;
    } else if (step === 3) {
      return !verfiy.selectedface;
    } else {
      return verfiy.cardFaceId;
    }
  }
  const onUploadImage = (file) => {
    console.log(file);
    if (step === 1) {
      setverfiy({
        ...verfiy,
        cardFront: file,
        selectedCardFront: true,
      });
    } else if (step === 2) {
      setverfiy({
        ...verfiy,
        cardBack: file,
        selectedcardBack: true,
      });
    } else if (step === 3) {
      setverfiy({
        ...verfiy,
        face: file,
        selectedface: true,
      });
    }
    // if (type === "front") {
    //   cardFront = file;
    // } else if (type === "back") {
    //   cardBack = file;
    // } else if (type === "front") {
    //   face = file;
    // }
  };
  return (
    <>
      <Modal
        show={display}
        onHide={handleClose}
        animation={true}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-account-verification modal-border-raduis"
        centered
        backdrop="static"
        keyboard={false}
      >
        {/* BsCheckLg */}
        <div style={{ position: "relative" }}>
          <Modal.Body style={{ textAlign: "center" }}>
            <div style={{ textAlign: "center" }}>توثيق الهوية</div> <hr />{" "}
            <Container>
              <Row>
                {steps.map((item, index) => {
                  return (
                    <Col>
                      {" "}
                      <div className="card-grid-item" key={index}>
                        <div style={{ textAlign: "center" }}>
                          <div
                            className={
                              index < step - 1
                                ? "step-account-verification-active"
                                : "step-account-verification-inactive"
                            }
                            style={{
                              border:
                                index === step - 1
                                  ? "#258cd7 1px solid"
                                  : "transparent 1px solid",
                            }}
                          >
                            {item.icon}
                          </div>
                        </div>
                        <div
                          className={`step-account-verification-${
                            index < step ? "active-text" : "inactive-text"
                          }`}
                          style={{ margin: "10px 0px", fontWeight: "bold" }}
                        >
                          {item.status}
                        </div>
                      </div>
                    </Col>
                  );
                })}
                <hr />
              </Row>
            </Container>
            {step === 1 ? (
              <>
                {!verfiy.selectedCardFront ? (
                  <DropZoneFile
                    imagesOnly={true}
                    multiFile={false}
                    frontId={true}
                    onChange={onUploadImage}
                    text="أضغط لاضافة الصورة الامامية للبطافة او أفلت المرفق هنا"
                  />
                ) : (
                  <div>FRONTEND AY HENA</div>
                  //   <UploadedFile file={cardFront} cardFront={true} />
                )}
              </>
            ) : step === 2 ? (
              <>
                {" "}
                {!verfiy.selectedcardBack ? (
                  <DropZoneFile
                    imagesOnly={true}
                    multiFile={false}
                    cardBack={true}
                    onChange={onUploadImage}
                    text="أضغط لاضافة الصورة الامامية للبطافة او أفلت المرفق هنا"
                  />
                ) : (
                  <div>BACKEND AY HENA</div>
                  // <UploadedFile file={cardBack} cardBack={true} />
                )}
              </>
            ) : step === 3 ? (
              <>
                {" "}
                {!verfiy.selectedface ? (
                  <DropZoneFile
                    imagesOnly={true}
                    multiFile={false}
                    face={true}
                    onChange={onUploadImage}
                    text="أضغط لاضافة الصورة الامامية للبطافة او أفلت المرفق هنا"
                  />
                ) : (
                  <div>FACE AY HENA</div>
                  //   <UploadedFile file={face} face={true} />
                )}
              </>
            ) : (
              <>
                <img src="/assets/img/proud.svg" width="100%" />
              </>
            )}
          </Modal.Body>
        </div>
        <div style={{ margin: "0px 5px", textAlign: "center" }}>
          <Button
            className="e-btn   "
            style={{ margin: "0px 2px", width: "48%" }}
            onClick={() => setStep(step + 1)}
            disabled={isDesabled(step)}
          >
            {step === 1 ? "OKEy" : "التالي"}
          </Button>
          <Button
            className="btn-height-meduim-large btn-small-raduis"
            variant="blue-light"
            style={{ margin: "0px 6px", width: "47%" }}
            onClick={() => {
              if (step === 1) {
                handleClose();
              } else {
                setStep(step - 1);
              }
            }}
          >
            {step === 1 ? "الغاء" : " الرجول للسابقة"}
          </Button>
        </div>
      </Modal>
    </>
  );
}
