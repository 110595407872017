import React from "react";
import { useTranslation } from "react-i18next";
import Styled from "styled-components";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
const ContentDashboardStyled = Styled.div`
 
 margin-left:  ${({ rtl }) => (rtl ? "20px" : "400px")}  ;
 margin-right: ${({ rtl }) => (rtl ? "400px" : "20px")}  ; 
  @media (max-width: 1199px) {
    margin-right:   10px;
    margin-left:   10px; /* Moves the sidebar off the screen on small screens */
  }`;

export default function ContentDashboard({ children }) {
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!localStorage.getItem("dashboard-guide")) {
      const driverObj = driver({
        showProgress: true,
        steps: [
          {
            element: "#language",
            popover: {
              title: "Language Selection",
              description:
                "Choose your preferred language: Arabic, English, or French.",
            },
          },
          {
            element: "#navbar-menu",
            popover: {
              title: "Main Navigation",
              description:
                "Use the navigation bar to move between different sections of the platform.",
            },
          },
          {
            element: "#menu-desktop",
            popover: {
              title: "Dashboard Menu",
              description:
                "Access dashboard features such as courses, learning resources, account management, payments, students, and classes.",
            },
          },
          {
            element: ".content",
            popover: {
              title: "Content Management",
              description:
                "Manage your courses, payments, accounts, and more in this area.",
            },
          },
        ],
      });

      driverObj.drive();
      localStorage.setItem("dashboard-guide", true);
    }
  }, []);
  return (
    <ContentDashboardStyled rtl={t("lang") === "ar"} className="content">
      {children}
    </ContentDashboardStyled>
  );
}
