import React from "react";

function Teacher({ teacher_img, fullname, domain }) {
  return (
    <div className="course__instructor-item d-flex align-items-center mr-70">
      <div className="course__instructor-thumb mr-20">
        <img src={teacher_img} alt="" />
      </div>
      <div className="course__instructor-content">
        <h3>{fullname}</h3>
        <p>{domain}</p>
      </div>
    </div>
  );
}
export default function TeachersSection({ teachers }) {
  return (
    <div className="course__instructor mb-45">
      <h3>Other Instructors</h3>
      <div className="course__instructor-wrapper d-md-flex align-items-center">
        {teachers.map((item, index) => (
          <Teacher
            teacher_img={item.avatar}
            fullname={item.fullname}
            domain={item.domain}
          />
        ))}
      </div>
    </div>
  );
}
