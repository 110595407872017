import getThemeOptions from "./themeOptions";

function theme() {
  // const { publicRuntimeConfig } = getConfig();

  const theme = getThemeOptions();
  return theme;
}

export default theme;
