import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { menu_bar } from "../redux/features/header-slice";

export default function ScrollToTop() {
  // const dispatch = useSelector(state => state.socket);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => {
      dispatch(menu_bar(false));
    }, 10);
  }, [pathname]);

  return null;
}
