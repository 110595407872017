import Breadcrumb from "../../../components/common/breadcrumb/breadcrumb";
import CourseList from "../../../components/common/course/CourseList";
import CreateCourse from "../../../components/common/course/create-course";
import SEO from "../../../components/seo";

import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import { useSelector } from "react-redux";
import { selectCourses } from "../../../redux/features/course-slice";
const Courses = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Courses"} />
      <Header />
      <Breadcrumb
        withoutImage={true}
        pages={[{ name: "Home", path: "" }]}
        subtitle="My courses"
      />
      <div className="container" style={{ marginTop: "100px" }}>
        <CourseList path="my-courses" />
      </div>
    </Wrapper>
  );
};

export default Courses;
