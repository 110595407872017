import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

// Styled components for customization

// Styled Dropdown Button with white color
const DropdownButton = styled(Dropdown.Toggle)`
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: white; // Change the text color of the button to white
  font-size: 16px;
  padding: 5px;
  outline: none;
  cursor: pointer;

  &:hover {
    border-color: #888;
    background-color: transparent !important;
    color: white;
  }
  &:focus {
    border-color: #888;
    background-color: transparent !important;
    color: white;
  }
`;

// Styled Dropdown Menu
const DropdownMenu = styled(Dropdown.Menu)`
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
`;

// Styled Dropdown Item without hover background color
const DropdownItem = styled(Dropdown.Item)`
  color: black;
  font-size: 16px;
  padding: 8px 12px;

  &:hover {
    background-color: transparent; // Remove the hover background color
  }
`;

const LanguageSelector = () => {
  const { t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    window.localStorage.getItem("lang") || `🇩🇿 ${t("arabic")}`
  );

  const handleSelect = (languageCode) => {
    setSelectedLanguage(languageCode);
    window.localStorage.setItem("lang", languageCode);
    i18n.changeLanguage(languageCode);
    // Add additional functionality here to switch languages, if needed
  };
  const dirValue = selectedLanguage === "ar" ? "rtl" : "ltr";
  return (
    <>
      <Helmet>
        <html dir={dirValue} lang={selectedLanguage} />
        {selectedLanguage === "ar" && (
          <style type="text/css">
            {`body { font-family: "Tajawal ", sans-serif !important; }`}
          </style>
        )}
      </Helmet>

      <Dropdown>
        <DropdownButton
          id="dropdown-basic"
          variant="light"
          style={{ height: "34px", fontFamily: "Tajawal" }}
        >
          {selectedLanguage === "en" && `🇬🇧 ${t("english")}`}
          {selectedLanguage === "fr" && `🇫🇷 ${t("french")}`}
          {selectedLanguage === "ar" && `🇩🇿 ${t("arabic")}`}
          {selectedLanguage === "az" && `ⵣ ${t("amazigh")}`}
        </DropdownButton>

        <DropdownMenu
          style={{ textAlign: selectedLanguage === "ar" ? "right" : "left" }}
        >
          <DropdownItem onClick={() => handleSelect("en")}>
            🇬🇧 {t("english")}
          </DropdownItem>
          <DropdownItem onClick={() => handleSelect("fr")}>
            🇫🇷 {t("french")}
          </DropdownItem>
          <DropdownItem
            onClick={() => handleSelect("ar")}
            style={{ fontFamily: "Tajawal" }}
          >
            🇩🇿 {t("arabic")}
          </DropdownItem>
          <DropdownItem
            onClick={() => handleSelect("az")}
            style={{ fontFamily: "Tajawal" }}
          >
            ⵣ {t("amazigh")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default LanguageSelector;
