import React, { useState } from "react";

import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";

import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { useQuery } from "react-query";

import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";

import ContentDashboard from "../../../layout/layer/Layer";
import { useTranslation } from "react-i18next";
import { ActionButton } from "../../../components/costomComponents/ChapiterTable";
import { driver } from "driver.js";
import InboxCoursehub from "../../../components/messages/InboxCoursehub";
import MessagesCoursehub from "../../../components/messages/MessagesCoursehub";
import { Row, Col } from "react-bootstrap";
import ImageViewer from "../../../components/messages/ImageViewer";

export default function CoursesDashboard() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  return (
    <>
      <Wrapper>
        <Header />
        <section className=" po-rel-z1 pt-30 pb-145">
          <SidebarTeacher />
          <ContentDashboard>
            <h2>{t("Messages")}</h2>
            <Row>
              <Col md={4}>
                <InboxCoursehub />
              </Col>
              {window.innerWidth > 778 && (
                <Col md={8}>
                  <MessagesCoursehub
                    course_hub_id={searchParams.get("course_hub_id")}
                  />
                </Col>
              )}
            </Row>
          </ContentDashboard>
        </section>
      </Wrapper>{" "}
      <ImageViewer />
    </>
  );
}
