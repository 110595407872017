import React, { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useQuery } from "react-query";
import axios from "axios";
// import Pagination from "../pagination/pagination";
import {
  add_to_wishlist,
  wishlistItems,
} from "../../../redux/features/wishlist-slice";
import CourseItem from "./CourseItem";
import CourseDisplayOptions from "./CourseDisplayOptions";
import Pagination from "../pagination/pagination";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { Alert, Button } from "react-bootstrap";

const MyLearningContent = ({ path, smallDisplay }) => {
  const [list, setList] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;

  const { isLoading, isError, data, error, refetch } = useQuery(
    [path, page],
    () => fetchCourses(page)
  );

  async function fetchCourses(page) {
    let config = {
      headers: {},
    };
    if (localStorage.getItem("jwt")) {
      config.headers = {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      };
    }
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/my-enrolled?page=${page}`,
      config
    );
    return response.data;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  }

  const handlePageClick = (event) => {};
  console.log(data.courses);
  return (
    <>
      <Row>
        <Col md={"12"}>
          <Row md="12">
            {!smallDisplay && (
              <CourseDisplayOptions
                list={list}
                showList={setList}
                totalPages={data?.totalPages}
                currentPage={page || 0}
                hideFilter={true}
              />
            )}
            {data?.courses.length === 0 && (
              <Alert variant={"info"}>
                You don't enrolled any cours You can start looking for course
                for you
                <Link to="/page/courses" style={{ fontWeight: "bold" }}>
                  browse courses
                </Link>
              </Alert>
            )}
            {data?.courses?.map((item, index) => (
              <CourseItem
                smallDisplay={smallDisplay}
                course={item?.course}
                key={item?.id}
                list={list}
              />
            ))}
          </Row>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {smallDisplay && data?.totalPages > 1 && (
          <Button style={{ maxWidth: "170px", width: "100%" }}>See All</Button>
        )}
      </div>
      {data && !smallDisplay && (
        <Pagination
          handlePageClick={(page) =>
            navigate(`/dashboard/my-learning?page=${page.selected + 1}`)
          }
          pageCount={data?.totalPages}
          currentPage={page > 0 ? page - 1 : 0}
        />
      )}
    </>
  );
};

export default MyLearningContent;
