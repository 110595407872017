import React, { useState } from "react";
import { Row, Col, Container, Tab, Tabs, Table, Button } from "react-bootstrap";
import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import { FaRegTrashAlt } from "react-icons/fa";
import styled from "styled-components";
// import { FaRegEye } from "react-icons/fa6";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import Pagination from "../../../components/common/pagination/pagination";
import Moment from "react-moment";
import Swal from "sweetalert2";
import Switch from "rc-switch";
import ContentDashboard from "../../../layout/layer/Layer";
import Badge from "react-bootstrap/Badge";
import { FaRegEye } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { ActionButton } from "../../../components/costomComponents/ChapiterTable";

export default function ClassDahboard() {
  const { id } = useParams();
  return (
    <Wrapper>
      <Header />
      <section className=" po-rel-z1 pt-130 pb-145">
        <SidebarTeacher />
        <ContentDashboard>
          <h2>Class N: {id}</h2>
          <Tabs
            defaultActiveKey="students"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="students" title="Students">
              <ClassTable />
            </Tab>
            <Tab eventKey="live" title="Lives"></Tab>
          </Tabs>
        </ContentDashboard>
      </section>
    </Wrapper>
  );
}

function ClassTable() {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { id } = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const notif_id = searchParams.get("notif_id");
  const { isLoading, isError, data, error, refetch } = useQuery(
    ["my-class", page],
    () => fetchClass(page)
  );
  React.useEffect(() => {
    if (notif_id) {
      refetch();
    }
  }, [notif_id]);
  async function fetchClass(page) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };

    const response = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/my-class/${id}?notif_id=${notif_id}&page=${page || 1}`,
      config
    );
    return response.data;
  }

  const updatePayement = (item) => {
    Swal.fire({
      title: "Do you want to save the changes?",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: "Save",
      denyButtonText: `Don't save`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        };
        const data = {
          isPayed: !item.isPayed,
        };
        axios
          .put(
            `${process.env.REACT_APP_API_URL}/enrolled-payment/${item.id}`,
            data,
            config
          )
          .then(() => {
            // Get the current cached data
            let currentClass = queryClient.getQueryData(["my-class", page]);
            // console.log(currentClass);

            let students = currentClass.students.map((element) => {
              if (element.id === item.id) {
                element.isPayed = !element.isPayed;
              }
              return element;
            });
            queryClient.setQueryData(["my-class", page], {
              ...currentClass,
              students,
            });

            // }
            Swal.fire("Saved!", "", "success");
          });

        // Update the cache by appending the new article
        // if (currentChapiters) {

        // }
        Swal.fire("Saved!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  const deleteEnrolledStudent = (id) => {
    Swal.fire({
      title: "Do you want to Delete this student ",
      showCancelButton: true,
      confirmButtonText: "Delete",
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        };

        axios
          .delete(
            `${process.env.REACT_APP_API_URL}/enrolled-payment/${id}`,

            config
          )
          .then(() => {
            // Get the current cached data
            let currentClass = queryClient.getQueryData(["my-class", page]);
            // console.log(currentClass);

            let students = currentClass.students.filter(
              (element) => element.id === id
            );
            queryClient.setQueryData(["my-class", page], {
              ...currentClass,
              students,
            });

            // }
            Swal.fire("Saved!", "", "success");
          });

        // Update the cache by appending the new article
        // if (currentChapiters) {

        // }
        Swal.fire("Saved!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Fullname</th>
            <th>Address</th>
            <th>Wiliya</th>
            <th>Amount</th>
            <th>Payment Method</th>
            <th> Proof</th>

            <th>isPayed</th>
            <th>Certificate</th>
            <th>Joined</th>
            <th style={{ textAlign: "right" }}>
              <div style={{ marginRight: "20px" }}>Actions</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.students?.map((item, index) => (
            <React.Fragment key={item.id}>
              <tr>
                <Td
                  target={item.user.id === data.targetUser?.id}
                  borderLeft={item.user.id === data.targetUser?.id}
                >
                  {index + 1}
                </Td>
                <Td target={item.user.id === data.targetUser?.id}>
                  {item.user.id} {item?.user?.fullname}
                </Td>
                <Td target={item.user.id === data.targetUser?.id}>
                  {item?.user?.address || "-"}
                </Td>
                <Td target={item.user.id === data.targetUser?.id}>
                  {item?.user?.wiliya || "-"}
                </Td>
                <Td target={item.user.id === data.targetUser?.id}>
                  {item.amount_payed}
                </Td>
                <Td target={item.user.id === data.targetUser?.id}>
                  {item.payment_method}
                </Td>
                <Td target={item.user.id === data.targetUser?.id}>
                  {item.proof ? (
                    <ActionButton>
                      <Button
                        variant="success"
                        style={{ margin: "0px 5px" }}
                        onClick={() =>
                          window.open(
                            `${
                              process.env.REACT_APP_DOMAIN_BACKEND +
                              item.proof.url
                            }`,
                            "_blank",
                            "width=600,height=400"
                          )
                        }
                      >
                        <FaRegEye /> <span>{t("view")}</span>
                      </Button>
                    </ActionButton>
                  ) : (
                    <Badge bg="warning">Pending</Badge>
                  )}
                </Td>
                <Td target={item.user.id === data.targetUser?.id}>
                  <Switch
                    checked={item.isPayed}
                    // disabled={item.payment_method !== "Cash"}
                    onChange={() => updatePayement(item)}
                    // checkedChildren="开"
                    // unCheckedChildren="关"
                  />
                </Td>
                <Td target={item.user.id === data.targetUser?.id}>
                  {item?.course?.organization?.name ? (
                    <>
                      {!item.certificate_url ? (
                        <CertificateGenerator />
                      ) : (
                        <Button variant="success" style={{ margin: "0px 5px" }}>
                          <FaRegEye /> <span>{t("view")}</span>
                        </Button>
                      )}
                    </>
                  ) : !item.certificate_url ? (
                    <div>Auto certificate</div>
                  ) : (
                    <Button>Show</Button>
                  )}
                </Td>
                <Td target={item.user.id === data.targetUser?.id}>
                  <Moment format="DD/MM/YYYY hh:mm">{item.createdAt}</Moment>
                </Td>
                <Td target={item.user.id === data.targetUser?.id}>
                  <div style={{ textAlign: "right", marginRight: "20px" }}>
                    {/* <FaRegEye
                    onClick={() => navigate(`/dashboard/class/${item.id}`)} // Replace handleEdit with your edit function
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  /> */}
                    {/* <MdOutlineEdit
                    onClick={() => handleEdit(item.id)} // Replace handleEdit with your edit function
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  /> */}
                    <FaRegTrashAlt
                      onClick={() => deleteEnrolledStudent(item.id)} // Replace handleDelete with your delete function
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </Td>
              </tr>

              {item.user.id === data.targetUser?.id &&
                data?.students[index + 1]?.user?.id !== data.targetUser?.id && (
                  <tr>
                    <td colSpan="12">
                      <hr style={{ borderTop: "1px solid #d3d3d3" }} />
                    </td>
                  </tr>
                )}
            </React.Fragment>
          ))}
        </tbody>
      </Table>
      {data && (
        <Pagination
          handlePageClick={(page) =>
            navigate(`/dashboard/classes?page=${page.selected + 1}`)
          }
          pageCount={data?.totalPages}
          currentPage={page > 0 ? page - 1 : 0}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    padding: 40,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 20,
    marginBottom: 20,
  },
  text: {
    fontSize: 14,
    marginBottom: 10,
  },
});

const CertificateGenerator = () => {
  return (
    <div>
      <Button className="e-btn" style={{ width: "120px" }}>
        Generate
      </Button>
    </div>
  );
};

const Td = styled.td`
  background-color: ${({ target }) =>
    target ? "#2386c821 !important" : "white"};
  border-left: ${({ borderLeft }) =>
    borderLeft ? "10px solid #2386C8" : "auto"};
`;
