import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import StayledLayer from "./StaylledLayer";
import Modal from "react-bootstrap/Modal";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorMsg from "../common/forms/error-msg";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export default function LuctersTable() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <StayledLayer>
      <Button
        variant="primary"
        className="e-btn"
        style={{ marginBottom: "20px" }}
        onClick={() => setModalShow(true)}
      >
        New Lecture
      </Button>
      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>LuctersTable</th>
            <th>Type</th>
            <th>actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>introduction</td>
            <td>10 </td>
            <td>10 </td>
          </tr>
        </tbody>
      </Table>
    </StayledLayer>
  );
}

function MyVerticallyCenteredModal(props) {
  const navigate = useNavigate();
  const { id, chapiterId } = useParams();
  const formik = useFormik({
    initialValues: {
      name: "",
      file: null, // Adding file field to initialValues
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      file: Yup.mixed().required("File is required"), // Validation for the file
    }),
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("file", values.file);

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          "Content-Type": "multipart/form-data", // Set content type for file upload
        },
      };

      axios
        .post(
          `${process.env.REACT_APP_API_URL}/lecture?chapiterId=${chapiterId}&courseId=${id}`,
          { name: values.name },
          config
        )
        .then((res) => {
          //   navigate(`/my-course/${id}/${res.data.id}`);
          // we will demand the link to upload the file
        })
        .catch((error) => {
          // Handle error
        });
    },
  });

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            New Lecture
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-xxl-12 col-xl-12 col-md-12">
            <div>
              <label htmlFor="name">Name:</label>
              <input
                id="name"
                name="name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className="course__form-input"
              />
              {formik.touched.name && formik.errors.name ? (
                <div>{formik.errors.name}</div>
              ) : null}
            </div>

            {/* File Input */}
            <div>
              <label htmlFor="file">Upload File or Video:</label>
              <input
                id="file"
                name="file"
                type="file"
                onChange={(event) => {
                  formik.setFieldValue("file", event.currentTarget.files[0]);
                }}
                className="course__form-input"
              />
              {formik.touched.file && formik.errors.file ? (
                <div>{formik.errors.file}</div>
              ) : null}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="e-btn">
            Create
          </Button>
          <Button onClick={props.onHide} className="e-btn btn-default">
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
