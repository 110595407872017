import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react"; // Import useState hook

import ErrorMsg from "./error-msg";
import { AccountSchema } from "./validation-schema";
import axios from "axios";
import AvatarUploader from "../avatar/upload.avatar";
const ResetPassword = () => {
  const navigate = useNavigate();

  const handleOnSubmit = (values, { resetForm }) => {
    axios
      .post(`${process.env.REACT_APP_DOMAIN_BACKEND}/api/auth/reset-password`, {
        code: "privateCode", // code contained in the reset link of step 3.
        password: "userNewPassword",
        passwordConfirmation: "userNewPassword",
      })
      .then((response) => {
        console.log("Your user's password has been reset.");
      })
      .catch((error) => {
        console.log("An error occurred:", error.response);
      });
    // resetForm()
  };

  const { handleChange, handleSubmit, handleBlur, errors, values, touched } =
    useFormik({
      initialValues: {
        code: "", // code contained in the reset link of step 3.
        password: "",
        passwordConfirmation: "",
        //password: "",
        // organizationName: "", // Add organizationName field to initialValues
      },
      validationSchema: AccountSchema,
      onSubmit: handleOnSubmit,
    });

  // Update the handleChange function to handle fullname changes
  const updatedHandleChange = (event) => {
    // For other fields, handle changes as usual
    handleChange(event);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="sign__input-wrapper mb-25">
        <h5>Code</h5>
        <div className="sign__input">
          <input
            value={values.code}
            onChange={updatedHandleChange}
            onBlur={handleBlur}
            type="text"
            placeholder="code"
            name="code"
          />
          <i className="fal fa-user"></i>
        </div>
        {touched.code && <ErrorMsg error={errors.code} />}
      </div>
      <div className="sign__input-wrapper mb-25">
        <h5>New password</h5>
        <div className="sign__input">
          <input
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            type="text"
            placeholder="password"
            name="password"
          />
          <i className="fal fa-user"></i>
        </div>

        {touched.password && <ErrorMsg error={errors.password} />}
      </div>
      <div className="sign__input-wrapper mb-25">
        <h5>password Confirmation</h5>
        <div className="sign__input">
          <input
            value={values.passwordConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            type="passwordConfirmation"
            placeholder="e-mail address"
            name="passwordConfirmation"
          />
          <i className="fal fa-envelope"></i>
        </div>
        {touched.passwordConfirmation && (
          <ErrorMsg error={errors.passwordConfirmation} />
        )}
      </div>

      <button className="e-btn w-100">
        <span></span> Update
      </button>
    </form>
  );
};

export default ResetPassword;
