import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import CartArea from "../../components/common/cart";
import Sidebar from "../../components/common/sidebar";
import useCart from "../../hooks/use-cart";
import useSticky from "../../hooks/use-sticky";
import { cart_open } from "../../redux/features/cart-slice";
import { selectCourses } from "../../redux/features/course-slice";
import { menu_bar } from "../../redux/features/header-slice";
import { Cart, CatDot } from "../../svg";
import NavMenu from "./nav-menu";
import axios from "axios";
import NavbarWithAvatar from "../../components/dropdown/DropDownMenu";
import { Button, Container } from "react-bootstrap";

import styled from "styled-components";
import Topbar from "../../components/common/Topbar/Topbar";
import { useTranslation } from "react-i18next";

export const cat_submenu = [
  "English Learning",
  "Web Development",
  "Logo Design",
  "Motion Graphics",
  "Video Edition",
];

const Header = ({ header_white, header_shadow }) => {
  const { sticky } = useSticky();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const courses = useSelector(selectCourses);
  const [searchCourses, setSearchCourses] = useState([]);
  const [focus, setFocus] = useState(false);
  const [msg, setMsg] = useState("");

  const [categories, setCategories] = useState([]);
  const fetchCategories = () => {
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/client/categories`)
        .then((res) => {
          setCategories(res.data.data);
        });
    } catch (error) {}
  };
  useEffect(() => {
    fetchCategories();
    // fetchOrganization();
  }, []);
  // const fetchOrganization = () => {
  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
  //     },
  //   };
  //   try {
  //     axios
  //       .get(`${process.env.REACT_APP_API_URL}/client/my-organizations`, config)
  //       .then((res) => {
  //         console.log(res);
  //         //   if (!res.data.course) {
  //         //     navigate("/404");
  //         //   } else {
  //         //     console.log(res.data);
  //         //     setCourse(res.data.course);
  //         //   }
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const location = useLocation();
  const [isDashboard, setIsDashboard] = React.useState(null);
  React.useState(() => {
    if (location) {
      const path = location.pathname;
      // Extracting the part you need from the URL
      const parts = path.split("/");
      const currentPage = parts[1];
      setIsDashboard(currentPage === "dashboard");
    }
  }, [location]);
  return (
    <>
      <NavbarStyle isDashboard={isDashboard} rtl={t("lang") === "ar"}>
        <Topbar isDashboard={isDashboard} />
        <header>
          <div
            id="header-sticky"
            className={`header__area 
        

       ${
         header_shadow
           ? "header__padding-2 header__shadow"
           : !isDashboard
           ? "header__transparent"
           : "header- dashboard header__padding"
       } 
        ${header_white ? "header__white" : ""} ${
              sticky ? "sticky" : "display_tolbar"
            }`}
          >
            <div className={isDashboard ? "container" : "container"}>
              <div
              //className={`${header_shadow ? "container" : "container-fluid"}`}
              >
                <div className="row align-items-center">
                  <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-2 col-sm-4 col-6">
                    <div className="header__left d-flex">
                      <div className="logo">
                        <Link to="/">
                          <img
                            src={
                              header_white && !sticky
                                ? "/assets/img/logo/logo-2.png"
                                : "/assets/img/logo/logo.png"
                            }
                            alt="logo"
                          />
                        </Link>
                      </div>
                      <div className="header__category d-none d-lg-block">
                        <nav>
                          <ul>
                            <li>
                              <Link
                                to="/courses"
                                className="cat-menu d-flex align-items-center"
                              >
                                <div className="cat-dot-icon d-inline-block">
                                  <CatDot />
                                </div>
                                <span>{t("categories")}</span>
                              </Link>
                              <ul className="cat-submenu">
                                {categories?.map((category, index) => (
                                  <li key={index}>
                                    <Link to="/course-details">
                                      {category.name}
                                    </Link>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-xxl-9 col-xl-9 col-lg-8 col-md-10 col-sm-8 col-6"
                    style={{ padding: "0", margin: 0 }}
                  >
                    <div
                      className="header__right d-flex justify-content-end align-items-center"
                      id="navbar-menu"
                    >
                      <div
                        className={`${
                          header_white
                            ? "main-menu main-menu-3 d-none d-xl-block"
                            : header_shadow
                            ? "main-menu main-menu-2 d-none d-xl-block"
                            : "main-menu d-none d-xl-block"
                        }`}
                      >
                        <nav id="mobile-menu">
                          {/* NavMenu start */}
                          <NavMenu />
                          {/* NavMenu end */}
                        </nav>
                      </div>
                      {localStorage.getItem("jwt") ? (
                        <NavbarWithAvatar />
                      ) : (
                        <RegistrationButton>
                          <Button className="e-btn" variant="primary">
                            <Link to="/sign-up">{t("sign up")} </Link>
                          </Button>{" "}
                          <Button variant="outline-primary">
                            <Link to="/sign-in">{t("sign in")} </Link>
                          </Button>
                        </RegistrationButton>
                      )}
                      <SidebarStylled>
                        <div className="sidebar__menu ">
                          <div
                            onClick={() => dispatch(menu_bar(true))}
                            className="sidebar-toggle-btn ml-30"
                            id="sidebar-toggle"
                          >
                            <span className="line"></span>
                            <span className="line"></span>
                            <span className="line"></span>
                          </div>
                        </div>
                      </SidebarStylled>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </NavbarStyle>
      {/* cart start */}
      {/* <CartArea /> */}
      {/* cart end */}

      {/* sidebar start */}
      <SidebarStylled>
        <Sidebar />
      </SidebarStylled>
      {/* sidebar end */}
    </>
  );
};

export default Header;

const NavbarStyle = styled.div`
  margin-left: ${({ rtl, isDashboard }) =>
    rtl && isDashboard ? "0px" : !isDashboard ? "0px" : "360px"};
  margin-right: ${({ rtl, isDashboard }) =>
    rtl && isDashboard ? "360px" : "0px"};
  @media (max-width: 1199px) {
    margin-right: 0px;
    margin-left: 0px; /* Moves the sidebar off the screen on small screens */
  }
`;

const SidebarStylled = styled.div`
  display: none;
  @media (max-width: 1199px) {
    display: block;
  }
`;

const RegistrationButton = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  @media (max-width: 1199px) {
    display: none;
  }
`;
