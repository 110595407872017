import React, { useRef, useState, useEffect } from "react";
import { Dropdown, DropdownButton, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { BsFillGearFill, BsPower } from "react-icons/bs";
import { LuSchool2 } from "react-icons/lu";
import axios from "axios";
import { FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FiBell, FiClock } from "react-icons/fi";
import { Button } from "react-bootstrap";
import Moment from "react-moment";
import { Avatar } from "../common/avatar/avatar";

const AvatarDropdown = ({ user }) => {
  const [organizations, setOrganizations] = useState([]);

  const ref = useRef(null);
  const navigate = useNavigate();
  const handleSettings = () => {
    console.log("Settings clicked");
    // Handle settings action
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    console.log("Logout clicked");
    // Handle logout action
  };

  const handleProfile = () => {
    navigate("/profile/" + localStorage.getItem("username"));
    // Handle logout action
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/client/my-organizations`,
          config
        ); // Replace with your actual API endpoint
        console.log(response);
        setOrganizations(response.data); // Assuming the response is an array of organizations
      } catch (error) {
        console.error("Error fetching organizations:", error.message);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once when the component mounts

  // const avatarImage = localStorage.getItem("avatar")
  //   ? process.env.REACT_APP_DOMAIN_BACKEND + localStorage.getItem("avatar")
  //   : "https://avatars.hsoubcdn.com/default?s=128";

  return (
    <Dropdown align="end" o>
      <Dropdown.Toggle as={CustomToggle} ref={ref}>
        <Image
          src={user.avatar || "https://avatars.hsoubcdn.com/default?s=128"}
          width="30"
          height="30"
          roundedCircle
          alt="Avatar"
          className="mr-2"
        />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={handleProfile}>
          <FaUser className="mr-10" />
          Profile
        </Dropdown.Item>
        <Dropdown.Item onClick={handleSettings}>
          <BsFillGearFill className="mr-10" />
          Settings
        </Dropdown.Item>
        <Dropdown.Divider />
        {/* <Dropdown.ItemText>
          <LuSchool2 className="mr-2" style={{ marginRight: "10px" }} />
          orgnazition
        </Dropdown.ItemText> */}
        {organizations.map((item) => (
          <Dropdown.Item
            style={{ marginLeft: "30px" }}
            onClick={() => navigate(`/organization/dashboard/${item.name}`)}
          >
            {item.name}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Dropdown.Item onClick={handleLogout}>
          <BsPower className="mr-10" />
          Logout
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="/"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    className="text-white"
  >
    {children}
  </a>
));

const NavbarWithAvatar = () => {
  const { user } = useSelector((state) => state.user);
  return (
    <>
      {/* <DropdownButton id="dropdown-item-button" title="Dropdown button">
        <Dropdown.ItemText>Dropdown item text</Dropdown.ItemText>
        <Dropdown.Item as="button">Action</Dropdown.Item>
        <Dropdown.Item as="button">Another action</Dropdown.Item>
        <Dropdown.Item as="button">Something else</Dropdown.Item>
      </DropdownButton> */}

      <NotificationDropdown />

      <AvatarDropdown user={user} />
    </>
  );
};

export default NavbarWithAvatar;

// const notifications = [
//   { id: 1, text: "Notification 1" },
//   { id: 2, text: "Notification 2" },
//   { id: 3, text: "Notification 3" },
//   { id: 4, text: "Notification 4" },
//   // Add more notifications as needed
// ];

const NotificationDropdown = () => {
  const { total_count, notifications } = useSelector(
    (state) => state.notification
  );
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const handleClick = () => {
    const isSmallScreen = window.innerWidth <= 768; // Determine if the screen is small

    if (isSmallScreen) {
      // If the screen is small, navigate to the notifications page
      navigate("/dashboard/notifications");
    } else {
      // Otherwise, toggle the dropdown visibility
      setIsOpen(!isOpen);
    }
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [isOpen]);

  const goToNotificationsPage = () => {
    // Implement your navigation to the notifications page
    console.log("Navigating to notifications page...");
  };

  return (
    <NotificationContainer>
      <NotificationIcon onClick={handleClick} ref={dropdownRef}>
        {total_count > 0 && (
          <TotalCounts>{total_count > 99 ? "99+" : total_count}</TotalCounts>
        )}
        <FiBell />
      </NotificationIcon>
      {isOpen && (
        <NotificationList>
          <div
            className="custmer-card-title"
            style={{ padding: "10px 20px  0px 20px", position: "fixed" }}
          >
            الاشعارات
          </div>
          {notifications.slice(0, 10).map((notification) => (
            <Link to={`${notification.notif_url}?notif_id=${notification.id}`}>
              <NotificationItem
                key={notification.id}
                seen={notification.seen_status}
              >
                <Avatar size={35} avatar={notification?.user?.avatar?.url} />

                <div>
                  <span
                    style={{
                      color: "#374151",
                      fontWeight: "bold",
                      fontSize: "16px",
                    }}
                  >
                    {notification?.user?.fullname}:
                  </span>
                  <span
                    style={{
                      color: "#9CA3AF",
                      fontSize: ".9375rem",
                      lineClamp: "1px",
                    }}
                  >
                    {notification.notif_text}
                  </span>
                  <br />
                  <FiClock size={13} />
                  <span style={{ fontSize: "12px" }}>
                    &nbsp;
                    <Moment format="HH:mm YY-MM-DD ">
                      {notification.createdAt}
                    </Moment>
                  </span>
                </div>
              </NotificationItem>
            </Link>
          ))}
          <Link to={"/dashboard/notifications"}>
            <ShowMoreButton onClick={goToNotificationsPage}>
              Show More
            </ShowMoreButton>
          </Link>
        </NotificationList>
      )}
    </NotificationContainer>
  );
};

//styled
const TotalCounts = styled.div`
  position: absolute;
  font-size: 13px;
  font-weight: bold;
  z-index: 1002;
  top: -10px;
  right: -10px;
  color: white;
  background-color: #dc3545;
  height: 25px;
  min-width: 17px;
  padding: 0px 5px 5px 5px;
  border-radius: 12px;
`;
const NotificationContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const NotificationIcon = styled.div`
  cursor: pointer;
  font-size: 24px;
  margin-right: 15px;
  position: relative;
`;

const NotificationList = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0px;
  font-size: 14px;
  max-height: 300px;
  overflow-y: auto;
  width: 300px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NotificationItem = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  margin: 0px 0;
  padding: 20px;
  color: black;
  font-size: 14px;
  background-color: ${({ seen }) => (seen ? "white" : "#2386c821")};
  border: 1px solid #ccc;
`;

const ShowMoreButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 10px;
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
`;
