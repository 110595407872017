// const { deviceSize } = require("@utils/constants");
// const merge = require("lodash/merge");

import colors from "./colors";
import shadows from "./shadows";

const deviceSize = {
  xs: 425,
  sm: 768,
  md: 1024,
  lg: 1440,
};

const breakpoints = Object.keys(deviceSize).map(
  (key) => deviceSize[key] + "px"
);

breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];

export default function getThemeOptions() {
  return { colors, shadows, breakpoints };
}
