import React, { useRef } from "react";
import Wrapper from "../../../layout/wrapper";
import { Col, Row, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import { useQuery, useQueryClient } from "react-query";
import { ListGroup, Form, ProgressBar } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Header from "../../../layout/headers/header";
import {
  useCourseDetails,
  Details,
} from "../../../components/course-details/CourseDetails";
import { FaMapPin } from "react-icons/fa";
import { GoQuestion } from "react-icons/go";
import { RiLiveLine } from "react-icons/ri";
import { SiFuturelearn } from "react-icons/si";
export default function FormationPage() {
  const course = useCourseDetails();

  return (
    <>
      <div className="container">
        <Header header_shadow={true} />
        <div style={{ marginTop: "40px" }}>
          <Tabs
            defaultActiveKey="discription"
            id="justify-tab-example"
            className="course__tab-2 mb-3"
            variant="pills"
          >
            <Tab
              eventKey="discription"
              title={
                <div style={{ width: "150px" }}>
                  <SiFuturelearn /> <span>Videos</span>
                </div>
              }
            >
              <MyLearning />
            </Tab>
            <Tab
              eventKey="curriculum"
              title={
                <div style={{ width: "150px" }}>
                  <RiLiveLine /> <span>Live</span>
                </div>
              }
            ></Tab>
          </Tabs>

          <Col md="9">
            <Tabs
              defaultActiveKey="discription"
              id="justify-tab-example"
              className="course__tab-2 mb-3"
              variant="pills"
            >
              <Tab
                eventKey="discription"
                title={
                  <div style={{ width: "150px" }}>
                    <FaMapPin /> <span>pin</span>
                  </div>
                }
              >
                asdasd
              </Tab>
              <Tab
                eventKey="curriculum"
                title={
                  <div style={{ width: "150px" }}>
                    <GoQuestion /> <span>Q & A</span>
                  </div>
                }
              >
                asdasd
              </Tab>
            </Tabs>
            {/* <Details course={course?.data?.course || null} /> */}
          </Col>
        </div>
      </div>
    </>
  );
}

function MyLearning() {
  const { id } = useParams();
  const {
    data: courseList,
    isLoading,
    isError,
  } = useQuery(["courseLectures", id], async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/enrolled-course/${id}`,
      config
    );
    return response.data;
  });

  const [lecture, setLecture] = React.useState({});
  const [lectures, setLectures] = React.useState([]);
  const [progress, setProgress] = React.useState(0);
  const hndleLectureSelected = (value) => {
    setLecture(value);
  };
  React.useEffect(() => {
    if (courseList) {
      setLecture(courseList?.chapiters[0]?.lectures[0]);
      let arrayOfObjects = [];
      courseList?.chapiters.map((chapiter) => {
        arrayOfObjects.push(...chapiter.lectures);
      });
      setLectures(arrayOfObjects);

      //   console.log(courseList?.chapiters[0]?.lecture[0]?.id);
      //   setLecture(courseList.chapiters[0].lecture[0]);
    }
  }, [courseList]);

  React.useEffect(() => {
    if (lectures) {
      const result =
        lectures.filter((item) => item.isComplited).length / lectures.length;

      setProgress(((result / lectures.length) * 100).toFixed(0));
    }
  }, [lectures]);
  const handleVideoEnded = () => {
    // Find the index of the object with the given ID
    const index = lectures.findIndex((obj) => obj.id === lecture.id);
    setLecture(lectures[index + 1]);
  };

  // function renderFileViewer() {
  //   if (!lecture) return <div>null</div>;
  //   const { mime, url, ext } = lecture?.attachment;
  //   if (mime?.startsWith("video")) {
  //     return (
  //       <VideoPlayer
  //         lecture={lecture || {}}
  //         handleVideoEnded={handleVideoEnded}
  //       />
  //     );
  //   } else if (mime?.startsWith("image")) {
  //     return <ImageViewer url={url} />;
  //   } else if (mime === "application/pdf" || ext === ".pdf") {
  //     return <PdfViewer url={url} />;
  //   } else {
  //     // For unknown file types, provide a button to download
  //     return (
  //       <div>
  //         <p>Unknown file type</p>
  //         <button href={url} download>
  //           Download
  //         </button>
  //       </div>
  //     );
  //   }
  // }
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  return (
    <Row>
      <Col md="9" style={{ position: "relative" }}>
        {/* {lecture.attachement === "video" && (
          <VideoPlayer
            lecture={lecture || {}}
            handleVideoEnded={handleVideoEnded}
          />
        )} */}
      </Col>
      <Col md="3" style={{ padding: "0" }}>
        <div className="course__review-item d-flex align-items-center justify-content-between">
          <div className="course__review-text">
            <span>
              {lectures.filter((item) => item?.isComplited).length} /{" "}
              {lectures.length}
            </span>
          </div>
          <div className="course__review-progress">
            <ProgressBar now={Number(progress * 10)} />
          </div>
          <div className="course__review-percent">
            <h5>{progress * 10}%</h5>
          </div>
        </div>
        <DisplayCourseLectures
          courseList={courseList}
          activeId={lecture.id}
          hndleLectureSelected={hndleLectureSelected}
        />
      </Col>
    </Row>
  );
}

// function VideoPlayer({ lecture, handleVideoEnded }) {
//   const { id } = useParams();
//   const [isHovered, setIsHovered] = React.useState(false);
//   const [postSent, setpostSent] = React.useState(false);
//   const videoRef = useRef(null);
//   const queryClient = useQueryClient();

//   // Update video source when the lecture changes
//   React.useEffect(() => {
//     setpostSent(false);
//     const video = videoRef.current;
//     video.load(); // Reload the video to apply the new source
//     video.play();
//   }, [lecture]);

//   const handleTimeUpdate = () => {
//     const video = videoRef.current;
//     // const currentTime = parseInt(video.currentTime);
//     // if (currentTime > 0 && currentTime % 10 === 0) {
//     //   // Send an event or perform an action every 10 seconds
//     //   console.log(`Reached ${currentTime} seconds`);
//     // }

//     if (!postSent && video.duration - video.currentTime <= 10) {
//       setpostSent(true);
//       postProgress();
//     }
//   };

//   async function postProgress() {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("jwt")}`,
//       },
//     };
//     const data = { lectureId: lecture.id, courseId: id };
//     const response = await axios.put(
//       `${process.env.REACT_APP_API_URL}/progress`,
//       data,
//       config
//     );
//     if (response.data) {
//       const { chapiters } = queryClient.getQueryData(["courseLectures", id]);

//       let updated = chapiters.map((item) => {
//         item.lectures = item.lectures.map((el) => {
//           if (lecture.id === el.id) {
//             el.isComplited = true;
//           }
//           return el;
//         });
//         return item;
//       });

//       queryClient.setQueryData(["courseLectures", id], { chapiters: updated });
//     }
//   }
//   return (
//     <div>
//       <div
//         style={{ position: "relative" }}
//         onMouseEnter={() => setIsHovered(true)}
//         onMouseLeave={() => setIsHovered(false)}
//       >
//         <video
//           ref={videoRef}
//           controls
//           controlsList="nodownload"
//           style={{ width: "100%", borderRadius: "12px" }}
//           onTimeUpdate={handleTimeUpdate}
//           onEnded={handleVideoEnded}
//         >
//           <source src={lecture.attachement.url} type="video/mp4" />
//           Your browser does not support the video tag.
//         </video>
//         <div
//           style={{
//             position: "absolute",
//             background: `linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))`,
//             top: "0px",
//             left: "0px",
//             right: "0px",
//             height: "80px",
//             display: "flex",
//             alignItems: "left",
//             paddingLeft: "50px",
//             color: "white",
//             transition: "0.3s",
//             borderTopRightRadius: "12px",
//             borderTopLeftRadius: "12px",
//             opacity: isHovered ? "1" : "0",
//             transform: "0.3s",
//             pointerEvents: "none",
//           }}
//         >
//           <h3>{lecture?.name}</h3>
//         </div>
//       </div>
//     </div>
//   );
// }

function DisplayCourseLectures({ courseList, activeId, hndleLectureSelected }) {
  return (
    <div
      style={{
        height: "41vw",
        maxHeight: "500px",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        margin: 0,
      }}
    >
      {courseList?.chapiters.map((course, index) => (
        <div key={course.id}>
          <ListGroup>
            <ListGroup.Item
              style={{
                padding: "15px 10px",
                fontSize: "18px",
                backgroundColor: "#F3F4F8",
              }}
            >
              <b>
                #{index} - {course.name}
              </b>
            </ListGroup.Item>
            {course.lectures.map((lecture) => (
              <ListGroup.Item
                as={Link}
                to="#"
                key={lecture.id}
                style={{
                  padding: "15px 10px",
                  backgroundColor: activeId === lecture.id ? "#2b4eff1a" : "",
                }}
                onClick={() => hndleLectureSelected(lecture)}
              >
                <Row className="align-items-center">
                  <Col xs={1}>
                    <Form.Check type="checkbox" checked={lecture.isComplited} />
                  </Col>
                  <Col>{lecture.name}</Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </div>
      ))}
    </div>
  );
}
