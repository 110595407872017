import React from "react";
import styled from "styled-components";
import LanguageSelector from "../lang-selector/LangSelector";
import { Container } from "react-bootstrap";

export default function Topbar({ isDashboard }) {
  return (
    <TopbarStylled>
      <div
        className={isDashboard ? "" : "container"}
        style={{ padding: isDashboard ? "0px 30px" : "0" }}
      >
        <TopbarGrid>
          <HelpSection>support@algerspace.com</HelpSection>{" "}
          <LangSelection rtl={!!localStorage.getItem("lang")} id="language">
            <LanguageSelector />
          </LangSelection>
        </TopbarGrid>
      </div>
    </TopbarStylled>
  );
}
const TopbarGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 95px;
  color: white;
  padding-bottom: 2px;
`;
const TopbarStylled = styled.div`
  background-color: #2386c8;
  position: sticky;
`;
const HelpSection = styled.div`
  padding-top: 5px;
`;
const LangSelection = styled.div`
  padding-top: 1px;
`;
