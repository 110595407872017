import { Link } from "react-router-dom";
import { useQuery } from "react-query";
import React, { useState } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import Pagination from "../pagination/pagination";
import CourseItem from "../course/CourseItem";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const InstructorDetailsArea = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  console.log(username);
  const { isLoading, isError, data, error, refetch } = useQuery(
    ["profile", username],
    () => fetchProfile()
  );
  async function fetchProfile() {
    const config = {
      // headers: {
      //   Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      // },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/profile/${username}`,
      config
    );
    if (!response.data.instructor) {
      navigate("/404");
    }
    return response.data;
  }
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }
  return (
    <>
      <section className="teacher__area pt-100 pb-110">
        <div className="page__title-shape">
          <img
            className="page-title-shape-5 d-none d-sm-block"
            src="/assets/img/page-title/page-title-shape-1.png"
            alt=""
          />
          <img
            className="page-title-shape-6"
            src="/assets/img/page-title/page-title-shape-6.png"
            alt=""
          />
          <img
            className="page-title-shape-3"
            src="/assets/img/page-title/page-title-shape-3.png"
            alt=""
          />
          <img
            className="page-title-shape-7"
            src="/assets/img/page-title/page-title-shape-4.png"
            alt=""
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
              <div
                style={{
                  margin: "0px auto 90px auto",
                  maxWidth: "150px",
                  padding: "10px",
                }}
              >
                <div className="teacher__details-thumb p-relative w-img pr-30">
                  <img
                    src={
                      data?.instructor?.avatar?.url
                        ? process.env.REACT_APP_DOMAIN_BACKEND +
                          data?.instructor?.avatar?.url
                        : "https://avatars.hsoubcdn.com/default?s=128"
                    }
                    alt=""
                  />
                  <div className="teacher__details-shape">
                    <img
                      className="teacher-details-shape-1"
                      src="/assets/img/teacher/details/shape/shape-1.png"
                      alt=""
                    />
                    <img
                      className="teacher-details-shape-2"
                      src="/assets/img/teacher/details/shape/shape-2.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-12 col-xl-12 col-lg-12">
              <div className="teacher__wrapper">
                <div className="teacher__top d-md-flex align-items-end justify-content-between">
                  <div className="teacher__info">
                    <h4>{data?.instructor?.fullname}</h4>
                    <span>Teaches {data?.instructor?.title || "NOT YET"}</span>
                  </div>
                  <div className="teacher__rating">
                    <h5>Review:</h5>
                    <div className="teacher__rating-inner d-flex align-items-center">
                      <ul>
                        <li>
                          <a href="#">
                            {" "}
                            <i className="icon_star"></i>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            {" "}
                            <i className="icon_star"></i>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            {" "}
                            <i className="icon_star"></i>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            {" "}
                            <i className="icon_star"></i>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            {" "}
                            <i className="icon_star"></i>{" "}
                          </a>
                        </li>
                      </ul>
                      <p>4.5</p>
                    </div>
                  </div>
                  <div className="teacher__social-2">
                    <h4>Follow Us:</h4>
                    <ul>
                      {data?.instructor?.social_media?.x &
                      (
                        <li>
                          <a href="#" className="me-2">
                            <i className="x"></i>
                          </a>
                        </li>
                      )}
                      {data?.instructor?.social_media?.linkedin &
                      (
                        <li>
                          <a href="#" className="me-2">
                            <i className="x"></i>
                          </a>
                        </li>
                      )}{" "}
                      {data?.instructor?.social_media?.facebook &
                      (
                        <li>
                          <a href="#" className="me-2">
                            <i className="x"></i>
                          </a>
                        </li>
                      )}{" "}
                      {data?.instructor?.social_media?.instagram &
                      (
                        <li>
                          <a href="#" className="me-2">
                            <i className="x"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="teacher__follow mb-5">
                    <a href="#" className="teacher__follow-btn">
                      follow <i className="icon_plus"></i>{" "}
                    </a>
                  </div>
                </div>
                <div className="teacher__bio">
                  <h3>Short Bio</h3>
                  <p>{data?.instructor?.short_bio || "No bio"}</p>
                </div>
                <div className="teacher__courses pt-55">
                  <div className="section__title-wrapper mb-30">
                    <h2 className="section__title">
                      Teacher{" "}
                      <span className="yellow-bg yellow-bg-big">
                        Course
                        <img src="/assets/img/shape/yellow-bg.png" alt="" />
                      </span>
                    </h2>
                  </div>
                  <CoursesTeacher />
                  <div className="teacher__course-wrapper">
                    <div className="row">
                      {/* {instructorCourses.map((course, i) => (
                        <div
                          key={i}
                          className="col-xxl-6 col-xl-6 col-lg-6 col-md-6"
                        >
                          <div className="course__item white-bg mb-30 fix">
                            <div className="course__thumb w-img p-relative fix">
                              <Link to={`/course-details/${course.id}`}>
                                <img src={course.courseImage} alt="" />
                              </Link>
                              <div className="course__tag">
                                <a href="#" className={course.color}>
                                  {course.category}
                                </a>
                              </div>
                            </div>
                            <div className="course__content">
                              <div className="course__meta d-flex align-items-center justify-content-between">
                                <div className="course__lesson">
                                  <span>
                                    <i className="far fa-book-alt"></i>
                                    {course.lesson} Lesson
                                  </span>
                                </div>
                                <div className="course__rating">
                                  <span>
                                    <i className="icon_star"></i>
                                    {course.rating} ({course.total_rating})
                                  </span>
                                </div>
                              </div>
                              <h3 className="course__title">
                                <Link to={`/course-details/${course.id}`}>
                                  {course.title}
                                </Link>
                              </h3>
                              <div className="course__teacher d-flex align-items-center">
                                <div className="course__teacher-thumb mr-15">
                                  <img src={course.teacherImg} alt="" />
                                </div>
                                <h6>
                                  <Link to="/instructor-details">
                                    {course.teacherName}
                                  </Link>
                                </h6>
                              </div>
                            </div>
                            <div className="course__more d-flex justify-content-between align-items-center">
                              {course.price === "free" && (
                                <div className="course__status">
                                  <span>Free</span>
                                </div>
                              )}
                              {course.price !== "free" && (
                                <div className="course__status d-flex align-items-center">
                                  <span className={course.color}>
                                    ${course.price}
                                  </span>
                                  {course.oldPrice && (
                                    <span className="old-price">
                                      ${course.oldPrice}
                                    </span>
                                  )}
                                </div>
                              )}
                              <div className="course__btn">
                                <Link
                                  to={`/course-details/${course.id}`}
                                  className="link-btn"
                                >
                                  Know Details
                                  <i className="far fa-arrow-right"></i>
                                  <i className="far fa-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default InstructorDetailsArea;
function CoursesTeacher() {
  // /courses-by-teacher/:id
  const { username } = useParams();
  const [page, setPage] = useState(1);

  const { isLoading, isError, data, error, refetch } = useQuery(
    [`courses-${username}`, page],
    () => fetchCourses(page)
  );

  async function fetchCourses(page) {
    let config = {
      headers: {},
    };
    // if (localStorage.getItem("jwt")) {
    //   config.headers = {
    //     Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    //   };
    // }
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/courses-by-teacher/${username}?page=${page}`,
      config
    );
    return response.data;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  }

  console.log(data);
  return (
    <>
      <Row>
        <Col md={"12"}>
          <Row md="12">
            {data?.courses?.map((item, index) => (
              <CourseItem course={item} key={item?.id} list={false} />
            ))}
            {data?.courses.length === 0 && "No course"}
          </Row>
        </Col>
      </Row>
      {data && (
        <Pagination
          handlePageClick={(page) => setPage(page.selected + 1)}
          pageCount={data?.totalPages}
          currentPage={page > 0 ? page - 1 : 0}
        />
      )}
    </>
  );
}
