import React, { useState, useRef } from "react";
import axios from "axios";

import ProgressBar from "react-bootstrap/ProgressBar";
import { MdOutlineCancel } from "react-icons/md";
import { FiUpload } from "react-icons/fi";
import { Button } from "react-bootstrap";
const FileUploader = ({
  getUploadedFile,
  emptyLecture,
  getFullMedia,
  setDisabledItem,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState("");
  const fileInputRef = useRef(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setTimeout(() => {
      handleUpload(event.target.files[0]);
    }, 0);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleUpload = async (selectedFile) => {
    if (selectedFile) {
      try {
        if (setDisabledItem) {
          setDisabledItem();
        }
        // let i = 0;
        // const interval = setInterval(() => {
        //   if (i <= 10) {
        //     setUploadProgress(i * 10);
        //     i++;
        //   } else {
        //     clearInterval(interval);
        //   }
        // }, 1000); // Updates every 3 seconds

        // const signedUrlResponse = await axios.get("/getSignedUrl"); // Replace with your server endpoint to get signed URL
        //const { signedUrl } = signedUrlResponse.data;

        const options = {
          onUploadProgress: (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(progress);
          },
          cancelToken: new axios.CancelToken((cancel) => {
            // To cancel the upload if needed
            // cancel('Upload canceled by user.');
          }),
          headers: {
            //"Content-Type": selectedFile.type,
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
          },
        };
        console.log(selectedFile);
        const formData = new FormData();
        formData.append("files", selectedFile); // Ensure blob is a valid Blob object

        const result = await axios.post(
          `${process.env.REACT_APP_API_URL}/upload/`,
          formData,
          options
        );
        getUploadedFile(result?.data);
        setUploadStatus("File uploaded successfully!");
        setSelectedFile(null);
        setUploadProgress(0);
        // Handle success
      } catch (error) {
        if (axios.isCancel(error)) {
          setUploadStatus("Upload canceled.");
          setSelectedFile(null);
        } else {
          setUploadStatus("Error uploading file.");
          setSelectedFile(null);
          // Handle error
        }
      }
    } else {
      setUploadStatus("Please select a file.");
    }
  };

  return (
    <div>
      {!selectedFile && emptyLecture ? (
        <>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          <Button variant="danger" onClick={handleButtonClick}>
            <FiUpload /> <span> Upload</span>
          </Button>
        </>
      ) : (
        <div style={{ display: "grid", gridTemplateColumns: "auto 30px" }}>
          <ProgressBar
            now={uploadProgress || 0}
            label={`${uploadProgress || 0}%`}
            variant="success"
            style={{ marginTop: "5px", marginRight: "10px" }}
          />
          {!uploadStatus && (
            <MdOutlineCancel
              size={26}
              onClick={() => console.log()}
              style={{ cursor: "pointer", color: "red" }}
            />
          )}
        </div>
      )}

      {/* {uploadStatus && <p>{uploadStatus}</p>} */}
    </div>
  );
};

export default FileUploader;
