import React, { useState } from "react";
import { Row, Col, Container, Table, Button } from "react-bootstrap";
import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
import ReactPDF from "@react-pdf/renderer";
import { FaRegEye, FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";
// import { FaRegEye } from "react-icons/fa6";
import {
  useSearchParams,
  useNavigate,
  useParams,
  Link,
} from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import axios from "axios";
import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import Pagination from "../../../components/common/pagination/pagination";
import Moment from "react-moment";
import Swal from "sweetalert2";
import Switch from "rc-switch";
import ContentDashboard from "../../../layout/layer/Layer";
import FileUploader from "../../../components/common/FileUploader/FileUploader";
import Badge from "react-bootstrap/Badge";
import { ActionButton } from "../../../components/costomComponents/ChapiterTable";

export default function CoursesPayment() {
  const { id } = useParams();
  return (
    <Wrapper>
      <Header />
      <section className=" po-rel-z1 pt-130 pb-145">
        <SidebarTeacher />
        <ContentDashboard>
          <h2>My Payments</h2>
          <ClassTable />
        </ContentDashboard>
      </section>
    </Wrapper>
  );
}

function ClassTable() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["my-payment", page],
    () => fetchClass(page)
  );

  async function fetchClass(page) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/payment?page=${page || 1}`,
      config
    );
    return response.data;
  }
  async function updateProof(file, id) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };

    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/enrolled-proof/${id}`,
      {
        file: file,
      },
      config
    );
  }
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Course</th>
            <th>Phone</th>
            <th>Fullname</th>
            <th>Address</th>
            <th>Wiliya</th>
            <th>Amount</th>

            <th>Payment Method</th>
            <th>Proof</th>
            <th>Status</th>
            <th>Certificate</th>
            <th>Joined</th>
          </tr>
        </thead>
        <tbody>
          {data?.payments?.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td style={{ color: "#2386C8", fontWeight: "bold" }}>
                <Link to={"/page/course/" + item?.course?.id + "/first"}>
                  {item?.course?.name}
                </Link>
              </td>
              <td>{item?.user?.fullname}</td>
              <td>{item?.user?.phone || "Not found"}</td>
              <td>{item?.address || "-"}</td>
              <td>{item?.wiliya || "-"}</td>

              <td>{item.amount_payed || "0,00 DA"}</td>

              <td>{item.payment_method}</td>
              <td>
                {item.proof ? (
                  <ActionButton>
                    <Button
                      onClick={() =>
                        window.open(
                          `${
                            process.env.REACT_APP_DOMAIN_BACKEND +
                            item.proof.url
                          }`,
                          "_blank",
                          "width=600,height=400"
                        )
                      }
                    >
                      <FaRegEye /> <span>Show</span>
                    </Button>
                    <FileUploader
                      getUploadedFile={(e) => updateProof(e, item.id)}
                      getFullMedia={true}
                      emptyLecture={true}
                    />
                  </ActionButton>
                ) : (
                  <ActionButton>
                    <FileUploader
                      getUploadedFile={(e) => updateProof(e, item.id)}
                      getFullMedia={true}
                      emptyLecture={true}
                    />{" "}
                  </ActionButton>
                )}
              </td>
              <td>
                {item.isPayed ? (
                  <Badge bg="success">Confirmed</Badge>
                ) : (
                  <Badge bg="warning">Pending</Badge>
                )}

                {/* <Switch
                  checked={}
                  // disabled={item.payment_method !== "Cash"}
                  onChange={() => updatePayement(item)}
                  // checkedChildren="开"
                  // unCheckedChildren="关"
                /> */}
              </td>
              <td>
                {item?.course?.organization?.name ? (
                  <>
                    {!item.certificate_url ? (
                      <CertificateGenerator />
                    ) : (
                      <Button>Show</Button>
                    )}
                  </>
                ) : !item.certificate_url ? (
                  <div>Auto certificate</div>
                ) : (
                  <Button>Show</Button>
                )}
              </td>
              <td>
                <Moment format="DD/MM/YYYY hh:mm">{item.createdAt}</Moment>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {data && (
        <Pagination
          handlePageClick={(page) =>
            navigate(`/dashboard/classes?page=${page.selected + 1}`)
          }
          pageCount={data?.totalPages}
          currentPage={page > 0 ? page - 1 : 0}
        />
      )}
    </>
  );
}

const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
    padding: 40,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  header: {
    fontSize: 20,
    marginBottom: 20,
  },
  text: {
    fontSize: 14,
    marginBottom: 10,
  },
});

const CertificateGenerator = () => {
  return (
    <div>
      <Button className="e-btn" style={{ width: "120px" }}>
        Generate
      </Button>
    </div>
  );
};
