import React, { useRef } from "react";
import { Button } from "react-bootstrap";
import { Avatar } from "./avatar";
import { BsUpload } from "react-icons/bs";
import styled from "styled-components";
const ButtonUploader = styled(Button)`
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.356);
  border-radius: 2px;

  width: 100%;
  height: 100%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.356);
  }
  &:focus,
  &:active {
    background-color: rgba(0, 0, 0, 0.356) !important;
    outline: none; /* Remove the default focus outline - be cautious about accessibility */
  }
`;
const StyledAvatarUploader = styled.div`
  display: block;
  position: relative;
  width: 100px;
  height: 100px;
`;
export default function AvatarUploader({ getFile, avatarUrl }) {
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <StyledAvatarUploader
      onClick={handleButtonClick}
      style={{ margin: "auto" }}
    >
      <div style={{ display: "block" }}>
        {avatarUrl && (
          <img
            src={avatarUrl}
            width="100%"
            style={{ position: "absolute", borderRadius: "100%" }}
          />
        )}

        <ButtonUploader>
          <BsUpload />
        </ButtonUploader>
      </div>
      {/* <Avatar src="https://question-answer-khalil.s3.eu-west-3.amazonaws.com/2023-11-26T10:21:13.340Z-converted_image.webp" /> */}
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={(e) => getFile(e.target.files[0])}
      />
    </StyledAvatarUploader>
  );
}
