import styled from "styled-components";
import { border, color, space, layout, compose } from "styled-system";
import Box from "./Box";

const Card = styled(Box)(
  ({ theme, hoverEffect = false, boxShadow = "small" }) => ({
    backgroundColor: theme.colors.body.paper,
    boxShadow: theme.shadows[boxShadow] || "",
    ...(hoverEffect && { ":hover": { boxShadow: theme.shadows.large } }),
  }),
  compose(border, color, space, layout)
);

export default Card;
