import React from "react";

import { Container } from "react-bootstrap";
import SupportNotLogged from "../../../components/support/SupportNotLogged";
import Wrapper from "../../../layout/wrapper";
import Header from "../../../layout/headers/header";
import SupportLogged from "../../../components/support/SupportLogged";
import CreateTcicket from "../../../components/support/CreateTcicket";

// import SupportLogged from "components/support/SupportLogged";

export default function Support() {
  const isLogged = true;
  return (
    <>
      <Wrapper>
        {/* <SEO pageTitle={"CoursePayment"} /> */}
        <Header />

        {isLogged ? <SupportLogged /> : <SupportNotLogged />}
        <CreateTcicket />
      </Wrapper>
    </>
  );
}
