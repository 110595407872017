import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Breadcrumb = ({ pages, title, subtitle, img, withoutImage }) => {
  if (withoutImage)
    return (
      <>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="page__tit le -wrapper mt-110">
                  {title && (
                    <h3 className="page__title" style={{ color: "black" }}>
                      {title}
                    </h3>
                  )}
                  <BreadcrumbList aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      {pages?.map((page, index) => (
                        <li key={page.name}>
                          <Link to={`/${page.path}`}>{page.name}</Link>
                        </li>
                      ))}
                      {subtitle && <li aria-current="page">{subtitle}</li>}
                    </ol>
                  </BreadcrumbList>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  return (
    <>
      <section
        className="page__title-area page__title-height page__title-overlay d-flex align-items-center"
        style={{
          background: `url(https://images.unsplash.com/photo-1499750310107-5fef28a66643?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="page__title-wrapper mt-110">
                <h3 className="page__title">{title}</h3>
                {/* {title && (
                  <h3 className="page__title" style={{ color: "black" }}>
                    {title}
                  </h3>
                )} */}
                <BreadcrumbList aria-label="breadcrumb" color="white">
                  <ol>
                    {pages?.map((page, index) => (
                      <li key={page.name}>
                        <Link to={`/${page.path}`}>{page.name}</Link>
                      </li>
                    ))}
                    {subtitle && <li aria-current="page">{subtitle}</li>}
                  </ol>
                </BreadcrumbList>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Breadcrumb;
const BreadcrumbList = styled.nav`
  ol {
    list-style: none;
    padding: 0;
    margin: 0;
    color: ${({ color }) => (color ? color : "black")};
  }

  li {
    display: inline;
    margin-right: 5px; /* Adjust as needed */
  }

  li:not(:first-child)::before {
    content: "•"; /* Custom bullet */
    margin-right: 5px; /* Adjust as needed */
  }

  a {
    text-decoration: none;
    color: ${({ color }) => (color ? color : "black")};
  }
`;
