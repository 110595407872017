import React, { useState } from "react";
import { Row, Col, Button, Table } from "react-bootstrap";
import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";
import CreateCourseForm from "../../../components/common/forms/course-form";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "axios";
import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import Pagination from "../../../components/common/pagination/pagination";
import ContentDashboard from "../../../layout/layer/Layer";
import { useTranslation } from "react-i18next";
import { ActionButton } from "../../../components/costomComponents/ChapiterTable";
import { driver } from "driver.js";

export default function CoursesDashboard() {
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!localStorage.getItem("course-guide")) {
      const driverObj = driver({
        showProgress: true,
        steps: [
          {
            element: "#new-course",
            popover: {
              title: "Create a New Course",
              description:
                "Click here to start creating a new course for your platform.",
            },
          },
        ],
      });

      driverObj?.drive();
      localStorage.setItem("course-guide", true);
    }
  }, []);
  return (
    <Wrapper>
      <Header />
      <section className=" po-rel-z1 pt-130 pb-145">
        <SidebarTeacher />
        <ContentDashboard>
          <h2>{t("my courses")}</h2>
          <Button
            as={Link}
            to="/dashboard/course"
            className="e-btn"
            style={{ margin: "10px 0px" }}
            id="new-course"
          >
            {t("create course")}
          </Button>

          <CourseTable />
        </ContentDashboard>
      </section>
    </Wrapper>
  );
}

function CourseTable() {
  const [list, setList] = useState(false);
  const navigate = useNavigate();
  const handleEdit = () => {};
  const { t, i18n } = useTranslation();
  const handleDelete = () => {};
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["my-courses", page],
    () => fetchCourses(page)
  );

  async function fetchCourses(page) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/my-courses?page=${page || 1}`,
      config
    );
    return response.data;
  }
  React.useEffect(() => {
    if (
      !localStorage.getItem("courses-table-guide") &&
      data?.courses.length > 0
    ) {
      const driverObj = driver({
        showProgress: true,
        steps: [
          {
            element: "#courses-table",
            popover: {
              title: "Course List",
              description:
                "View and manage your list of courses. Use the action buttons to perform different tasks.",
            },
          },
          {
            element: "#course-view",
            popover: {
              title: "View Course Details",
              description:
                "Click here to view detailed information about a specific course.",
            },
          },
          {
            element: "#course-edit",
            popover: {
              title: "Edit Course",
              description:
                "Click here to edit the details of a specific course.",
            },
          },
          {
            element: "#course-delete",
            popover: {
              title: "Delete Course",
              description:
                "Click here to delete a course from your list. Be cautious, as this action cannot be undone.",
            },
          },
        ],
      });

      driverObj?.drive();
      localStorage.setItem("courses-table-guide", true);
    }
  }, [data]);
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Table responsive id="courses-table">
        <thead>
          <tr>
            <th>#</th>
            <th>{t("name")}</th>
            <th>{t("total_student")}</th>
            <th>{t("total_classes")}</th>
            <th>{t("total_lectures")}</th>
            <th style={{ textAlign: "right" }}>
              <div style={{ marginRight: "20px" }}>{t("actions")}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.courses?.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>
                <b>{item.name}</b>
              </td>
              <td>{item.total_students}</td>
              <td>{item.total_classes}</td>
              <td>{item.total_lectures}</td>

              <td>
                <ActionButton>
                  <Button
                    onClick={() => navigate(`/dashboard/my-course/${item.id}`)}
                    variant="success"
                    style={{ margin: "0px 5px" }}
                    id="course-view"
                  >
                    <FaRegEye /> <span>{t("view")}</span>
                  </Button>
                  <Button
                    onClick={() =>
                      navigate(`/dashboard/course?course-id=${item.id}`)
                    }
                    variant="warning"
                    style={{ margin: "0px 5px" }}
                    id="course-edit"
                  >
                    <MdOutlineEdit />
                    <span>{t("edit")}</span>
                  </Button>
                  <Button
                    onClick={() => handleDelete(item.id)}
                    variant="danger"
                    id="course-delete"
                  >
                    <FaRegTrashAlt
                    // Replace handleDelete with your delete function
                    />{" "}
                    <span>{t("delete")}</span>
                  </Button>
                </ActionButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {data && (
        <Pagination
          handlePageClick={(page) =>
            navigate(`/dashboard/courses?page=${page.selected + 1}`)
          }
          pageCount={data?.totalPages}
          currentPage={page > 0 ? page - 1 : 0}
        />
      )}
    </>
  );
}
{
  /* <MdOutlineEdit
  onClick={() => navigate(`/dashboard/course?course-id=${item.id}`)} // Replace handleEdit with your edit function
  style={{ cursor: "pointer", marginRight: "10px" }}
/>; */
}
