import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  ticket: null,
  isOpen: false,
  title: "",
};
export const ticketSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    onSettickets: (state, action) => {
      state.ticket = action.payload.tickets;
    },
    onOpenTicket: (state) => {
      state.ticket = [];
      state.isOpen = true;
      state.title = "New Tciket";
    },
    onCloseModel: (state) => {
      state.ticket = [];
      state.isOpen = false;
      state.title = "";
    },
  },
});

// Action creators are generated for each case reducer function
export const { onSettickets, onCloseModel, onOpenTicket } = ticketSlice.actions;
export const ticketState = (state) => state.tickets;

export default ticketSlice.reducer;
