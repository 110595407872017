import InstructorDetailsMain from "../../components/instructor-details";
import SEO from "../../components/seo";
import Wrapper from "../../layout/wrapper";

const InstructorProfile = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Instructor Details"} />
      <InstructorDetailsMain />
    </Wrapper>
  );
};

export default InstructorProfile;
