"use client";
import styled from "styled-components";
import {
  border,
  color,
  compose,
  flex,
  flexbox,
  layout,
  position,
  space,
  typography,
} from "styled-system";

const Box = styled.div(
  ({ shadow = 0, cursor = "unset", transition, theme }) => ({
    cursor: cursor,
    transition: transition,
    boxShadow: theme.shadows,
  }),
  compose(layout, space, color, position, flexbox, flex, border, typography)
);

export default Box;
