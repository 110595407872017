import React from 'react';

const Academics = () => {
  return (
    <svg viewBox="0 0 512 512">
      <g>
        <path className="st0" d="M91.5,96c0-23.1,12.7-43.2,31.5-53.7c-8.9-5-19.1-7.8-30-7.8C59,34.5,31.5,62,31.5,96S59,157.5,93,157.5   c10.9,0,21.1-2.8,30-7.8C104.2,139.2,91.5,119.1,91.5,96z" />
        <path className="st1" d="M158,226v178.6c0,22.6-6.6,44.5-19.2,63.3c-0.1,0.1-0.1,0.1-0.2,0.2l-24.2,35.2c-3.7,5.4-9.9,8.7-16.5,8.7   s-12.8-3.2-16.5-8.7l-24.2-35.2c-0.1-0.1-0.1-0.1-0.2-0.2c-12.4-18.9-19-40.7-19-63.3V229c0-11,9-20,20-20s20,9,20,20v175.6   c0,14.6,4.3,28.8,12.4,41l7.6,11.1l7.6-11.1c8.1-12.2,12.4-26.4,12.4-41V226c0-11,9-20,20-20S158,215,158,226L158,226z M503.3,81.5   l-35.2-24.2c-0.1-0.1-0.1-0.1-0.2-0.2C449,44.6,427.2,38,404.6,38H229c-11,0-20,9-20,20s9,20,20,20h175.6c14.6,0,28.8,4.3,41,12.4   l11.1,7.6l-11.1,7.6c-12.2,8.1-26.4,12.4-41,12.4H225c-11,0-20,9-20,20s9,20,20,20h179.6c22.6,0,44.5-6.6,63.3-19.2   c0.1-0.1,0.1-0.1,0.2-0.2l35.2-24.2c5.4-3.7,8.7-9.9,8.7-16.5S508.8,85.2,503.3,81.5z M176,94.5c0,44.9-36.6,81.5-81.5,81.5   S13,139.4,13,94.5c0-15.2,4.2-29.5,11.5-41.7L5.9,34.1C-2,26.3-2,13.7,5.9,5.9s20.5-7.8,28.3,0l18.7,18.7C65,17.2,79.3,13,94.5,13   C139.4,13,176,49.6,176,94.5z M136,94.5C136,71.6,117.4,53,94.5,53S53,71.6,53,94.5S71.6,136,94.5,136S136,117.4,136,94.5z" />
        <path className="st2" d="M198,512c-11,0-20-9-20-20s9-20,20-20c151.1,0,274-122.9,274-274c0-11,9-20,20-20s20,9,20,20   c0,83.9-32.7,162.7-92,222S281.9,512,198,512z" />
      </g>
    </svg>
  );
};

export default Academics;