// App.js

import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { FaSchool } from "react-icons/fa";
import { SiCreatereactapp } from "react-icons/si";
import { useLocation } from "react-router-dom";
import { IoIosBookmarks } from "react-icons/io";
import { IoWalletOutline } from "react-icons/io5";
import { CiHome } from "react-icons/ci";
import styled from "styled-components";
import { Spin as Hamburger } from "hamburger-react";
import { CiSettings } from "react-icons/ci";
const Sidebar = styled.div`
  position: fixed;

  top: 0px;
  left: 0;
  bottom: 0;
  width: 360px;
  padding: 10px;
  transition: left 0.3s ease; /* Define transition property */
  background-color: white;
  box-shadow: ${({ isOpen }) =>
    isOpen
      ? "-5px 0 15px rgba(0, 0, 0, 0.3)"
      : "none"}; /* Add shadow on the right when sidebar is open */

  @media (max-width: 900px) {
    left: ${({ isOpen }) =>
      isOpen ? "0" : "-380px"}; /* Toggle sidebar based on isOpen state */
  }
`;

const NavLink = styled(Link)`
  display: block;
  margin-bottom: 10px;
  padding-top: 15px;

  &:last-child {
    margin-bottom: 0;
  }

  &.active {
    /* Your active styles here */
    /* Example: background-color: #f0f0f0; */
  }
`;

const ButtonMenu = styled.div`
  z-index: 21;
  position: fixed;
  top: 100px;
  left: 0px;
  bottom: 0;
  width: 360px;
  padding: 10px;
  display: none;
  transition: left 0.3s ease; /* Define transition property */
  @media (max-width: 900px) {
    display: block;
    top: 115px;
    left: ${({ isOpen }) =>
      isOpen ? "150px" : "10px"}; /* Toggle sidebar based on isOpen state */
  }
`;
const SidebarOrganization = ({ active }) => {
  const location = useLocation();
  const path = location.pathname; // This gives you the current path

  // Extracting the part you need from the URL
  const parts = path.split("/");
  const currentPage = parts[parts.length - 1]; // Getting the last part of the URL
  const [isOpen, setOpen] = React.useState(false);
  const fetchOgrnazation = () => {
    // FETCH MY ORGANIZATION
  };
  return (
    <>
      <ButtonMenu isOpen={isOpen}>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </ButtonMenu>
      <Sidebar isOpen={isOpen}>
        <Nav
          variant="pills"
          className="flex-column"
          style={{ paddingTop: "170px" }}
        >
          {/* Your Nav links here */}
          {/* Example: */}

          <Nav.Item>
            <Nav.Item>
              <Link
                to="/dashboard/"
                className={`nav-link ${currentPage === "" ? "active" : ""}`}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
              >
                <CiHome style={{ marginRight: "10px" }} /> Home
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="/dashboard/"
                className={`nav-link ${currentPage === "" ? "active" : ""}`}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
              >
                <CiHome style={{ marginRight: "10px" }} /> admins
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="/dashboard/"
                className={`nav-link ${currentPage === "" ? "active" : ""}`}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
              >
                <CiHome style={{ marginRight: "10px" }} /> Instructors
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                to="/dashboard/create-course"
                className={`nav-link ${
                  currentPage === "create-course" ? "active" : ""
                }`}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
              >
                <SiCreatereactapp style={{ marginRight: "10px" }} /> Create
                Course
              </Link>
            </Nav.Item>
            <Link
              to="/dashboard/courses"
              className={`nav-link ${
                currentPage === "courses" ? "active" : ""
              }`}
              style={{ marginBottom: "10px", paddingTop: "15px" }}
            >
              <IoIosBookmarks style={{ marginRight: "10px" }} /> Courses
            </Link>
          </Nav.Item>

          <Nav.Item>
            <Link
              to="/dashboard/classes"
              className={`nav-link ${
                currentPage === "classes" ? "active" : ""
              }`}
              style={{ marginBottom: "10px", paddingTop: "15px" }}
            >
              <FaSchool style={{ marginRight: "10px" }} /> Classes
            </Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Link
              to="/dashboard/setings"
              className={`nav-link ${
                currentPage === "settings" ? "active" : ""
              }`}
              style={{ marginBottom: "10px", paddingTop: "15px" }}
            >
              <CiSettings style={{ marginRight: "10px" }} /> Settings
            </Link>
          </Nav.Item> */}

          {/* Other NavLinks */}
        </Nav>
      </Sidebar>
    </>
  );
};

export default SidebarOrganization;
