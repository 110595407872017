import React, { useState } from "react";

import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";

import { useQuery } from "react-query";

import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";

import ContentDashboard from "../../../layout/layer/Layer";
import { useTranslation } from "react-i18next";
import { ActionButton } from "../../../components/costomComponents/ChapiterTable";
import { driver } from "driver.js";
import InboxCoursehub from "../../../components/messages/InboxCoursehub";
import MessagesCoursehub from "../../../components/messages/MessagesCoursehub";
import { Row, Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
export default function ChattDashboard() {
  const { t } = useTranslation();
  const { course_hub_id } = useParams();
  return (
    <Wrapper>
      <Header />
      <section className=" po-rel-z1 pt-30 pb-145">
        <SidebarTeacher />
        <ContentDashboard>
          <h2>{t("Messages")}</h2>
          <Row>
            <Col md={12}>
              <MessagesCoursehub course_hub_id={course_hub_id} />
            </Col>
          </Row>
        </ContentDashboard>
      </section>
    </Wrapper>
  );
}
