import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { closeViewer } from "../../redux/features/image-slice";
import { IoCloseCircleOutline } from "react-icons/io5";

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
  backdrop-filter: blur(5px); /* Apply a blur effect */
  z-index: 9999; /* Ensure it's below the Viewer */
`;

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Viewer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 500px;
  height: 500px;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: transparent;
  border: none;
  font-size: 16px;
  cursor: pointer;
  z-index: 10001;
`;

export default function ImageViewer() {
  const dispatch = useDispatch();
  const { image, isOpen } = useSelector((state) => state.image);

  const handleClose = () => {
    dispatch(closeViewer());
  };

  const handleBackgroundClick = (e) => {
    // Check if the click is outside of the Viewer
    if (e.target === e.currentTarget) {
      handleClose(); // Close the viewer
    }
  };

  if (!isOpen) return null;

  return (
    <BackgroundOverlay onClick={handleBackgroundClick}>
      <ModalContainer>
        <Viewer>
          <CloseButton onClick={handleClose}>
            <IoCloseCircleOutline style={{}} size={35} />
          </CloseButton>
          <Img src={image} />{" "}
        </Viewer>
      </ModalContainer>
    </BackgroundOverlay>
  );
}
