import { useFormik, Field } from "formik";
import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react"; // Import useState hook

import ErrorMsg from "./error-msg";
import { registerSchema } from "./validation-schema";
import axios from "axios";
import Swal from "sweetalert2";

import {
  Container,
  Row,
  Col,
  FormGroup,
  FormLabel,
  Button,
  FormCheck,
  FormControl,
  Form,
  Alert,
} from "react-bootstrap";
import wilia from "../../../pages/dashboard/payment/wiliya.json";
import { useDispatch } from "react-redux";
import { auth_user } from "../../../redux/features/user-slice";
import { useTranslation } from "react-i18next";

const RegisterForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isExist, setisExist] = useState();
  const [loading, setloading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const [isExistUsername, setisExistUsername] = useState();
  const [isOrganizationNameEnabled, setIsOrganizationNameEnabled] =
    useState(false); // State for toggle switch

  const handleOnSubmit = (values, { resetForm }) => {
    if (
      isOrganizationNameEnabled &&
      values.organizationName &&
      values.organizationName?.length > 2
    ) {
      createAccount(values);
    } else {
      createAccount(values);
    }
    // resetForm()
  };

  const { handleChange, handleSubmit, handleBlur, errors, values, touched } =
    useFormik({
      initialValues: {
        name: "",
        email: "",
        password: "",
        username: "",
        wilaya: "",
        address: "",
        phone: "",
        passwordConfirmation: "",
        organizationName: "", // Add organizationName field to initialValues
      },
      validationSchema: registerSchema,
      onSubmit: handleOnSubmit,
    });

  // Update the handleChange function to handle fullname changes
  const updatedHandleChange = (event) => {
    const { name, value } = event.target;

    // If the fullname field is being changed, update the username accordingly
    if (name === "name") {
      // Logic to update the username based on the fullname value
      const usernameFromFullname = value.replace(/\s+/g, "").toLowerCase(); // Example logic to create username from fullname

      // Set the updated values for fullname and username fields
      handleChange({
        target: {
          name: "name",
          value,
        },
      });

      handleChange({
        target: {
          name: "username",
          value: usernameFromFullname,
        },
      });
    } else {
      // For other fields, handle changes as usual
      handleChange(event);
    }
  };
  const handleToggleChange = () => {
    setIsOrganizationNameEnabled(!isOrganizationNameEnabled); // Toggle organization name field
  };
  function checkorganization(organizationName) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/check-organization/${organizationName}`
      )
      .then((res) => {
        setisExist(res.data.isExist);
      });
  }
  function createOrganization(organization) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };
    const data = {
      name: organization,
    };

    axios
      .post(`${process.env.REACT_APP_API_URL}/registration`, data, config)
      .then((res) => {
        //should update data of user
        navigate("/");
      });
  }

  function createAccount({
    username,
    name,
    email,
    password,
    phone,
    wilaya,
    address,
  }) {
    setloading(true);
    const data = {
      username: username,
      fullname: name,
      email: email,
      password: password,
      phone: phone,
      address: address,
      wilaya: wilaya,
    };
    axios
      .post(` ${process.env.REACT_APP_API_URL}/auth/local/register`, data)
      .then(async (res) => {
        localStorage.setItem("jwt", res.data.jwt);
        localStorage.setItem("username", res.data.user.username);
        localStorage.setItem("fullname", res.data.user.fullname);
        //! NOTE: should update data of user
        dispatch(
          auth_user({
            user: {
              fullname: res.data.user.fullname,
              username: res.data.user.username,
              isSignedIn: true,
              avatar:
                process.env.REACT_APP_DOMAIN_BACKEND + res.data.user.avatar,
            },
          })
        );

        // );

        if (!isOrganizationNameEnabled && !values.organizationName) {
          setloading(false);
          Swal.fire({
            icon: "success",
            title: "success!",
            text: "Your compte has been created.",
            showConfirmButton: false,
            timer: 1000, // Time in milliseconds (1 second = 1000 milliseconds)
          }).then(() => {
            navigate("/dashboard");
          });
        } else {
          createOrganization(values.organizationName);
          setloading(false);
        }
      })
      .catch((error) => {
        // Handle errors from the server
        setloading(false);

        window.scrollTo(0, 0);
        // Check if the error response contains a message
        const errorMessage =
          error.response &&
          error.response.data &&
          error.response.data.error.message;
        if (
          error.response.data.error.message ===
          "Email or Username are already taken"
        ) {
          setErrorMsg(t("errors.usernameOrEmailTaken"));
        }
        // Display error message to the user using Swal.fire
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: errorMessage || "An error occurred while creating the account.",
          setTimeout: 1000,
        });
      });
  }
  function checkorUsername(username) {
    axios
      .get(`${process.env.REACT_APP_API_URL}/check-username/${username}`)
      .then((res) => {
        setisExistUsername(res.data.isExist);
      });
  }
  useEffect(() => {
    if (isOrganizationNameEnabled) {
      const delay = setTimeout(() => {
        checkorganization(values.organizationName);
      }, 1000);

      return () => clearTimeout(delay);
    } else {
      handleChange({
        target: {
          name: "organizationName",
          value: "",
        },
      });
    }
  }, [values.organizationName, isOrganizationNameEnabled]);
  useEffect(() => {
    const delay = setTimeout(() => {
      checkorUsername(values.username);
    }, 1000);

    return () => clearTimeout(delay);
  }, [values.username]);

  return (
    <>
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
      <form onSubmit={handleSubmit}>
        <div className="sign__input-wrapper mb-25">
          <h5>{t("fullname")}</h5>
          <div className="sign__input">
            <input
              value={values.name}
              onChange={updatedHandleChange}
              onBlur={handleBlur}
              type="text"
              placeholder={t("fullname")}
              name="name"
            />
            <i className="fal fa-user"></i>
          </div>
          {touched.name && <ErrorMsg error={errors.name} />}
        </div>
        <div className="sign__input-wrapper mb-25">
          <h5>{t("username")}</h5>
          <div className="sign__input">
            <input
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              placeholder="Username"
              name="username"
            />
            <i className="fal fa-user"></i>
          </div>
          {isExistUsername && <ErrorMsg error="Username is taken" />}
          {touched.username && <ErrorMsg error={errors.username} />}
        </div>
        <div className="sign__input-wrapper mb-25">
          <h5>{t("email")}</h5>
          <div className="sign__input">
            <input
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              type="email"
              placeholder={t("email")}
              name="email"
            />
            <i className="fal fa-envelope"></i>
          </div>
          {touched.email && <ErrorMsg error={errors.email} />}
        </div>
        <div
          className="sign__input-wrapper mb-25"
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
          }}
        >
          <div>
            {/* Wilaya */}
            <FormGroup controlId="wilaya">
              <FormLabel
                column
                sm={2}
                style={{ margin: "0", padding: "4px 0px" }}
              >
                {t("wilaya")}
              </FormLabel>

              <Form.Select
                aria-label="Default select example"
                name="wilaya"
                onChange={handleChange}
              >
                <option value="" disabled>
                  {t("welect wilaya")}
                </option>
                {wilia?.map((item) => {
                  return (
                    <option value={item.value} key={item.value}>
                      {item.label}
                    </option>
                  );
                })}
              </Form.Select>

              {touched.wilaya && <ErrorMsg error={errors.wilaya} />}
            </FormGroup>

            {/* <h5>Address</h5>
          <div className="sign__input">
            <input
              value={values.address}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              placeholder="address"
              name="address"
            />
            <i className="fal fa-location"></i>
          </div>
          {touched.address && <ErrorMsg error={errors.address} />} */}
          </div>
          <div>
            <h5> {t("address")}</h5>
            <div className="sign__input">
              <input
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder={t("address")}
                name="address"
              />
              <i className="fal fa-location"></i>
            </div>
            {touched.address && <ErrorMsg error={errors.address} />}
          </div>
        </div>
        <div className="sign__input-wrapper mb-25">
          <h5>{t("phone")}</h5>
          <div className="sign__input">
            <input
              value={values.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              placeholder={t("phone")}
              name="phone"
            />
            <i className="fal fa-phone"></i>
          </div>

          {touched.phone && <ErrorMsg error={errors.phone} />}
        </div>
        <div className="sign__input-wrapper mb-25">
          <h5>{t("password")}</h5>
          <div className="sign__input">
            <input
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              type="password"
              placeholder={t("password")}
              name="password"
            />
            <i className="fal fa-lock"></i>
          </div>
          {touched.password && <ErrorMsg error={errors.password} />}
        </div>

        <div className="sign__input-wrapper mb-10">
          <h5>{t("re-password")}</h5>
          <div className="sign__input">
            <input
              value={values.passwordConfirmation}
              onChange={handleChange}
              onBlur={handleBlur}
              type="password"
              placeholder={t("re-password")}
              name="passwordConfirmation"
            />
            <i className="fal fa-lock"></i>
          </div>
          {touched.passwordConfirmation && (
            <ErrorMsg error={errors.passwordConfirmation} />
          )}
        </div>

        {/* <div className="sign__action d-flex justify-content-between mb-30">
          <div className="sign__agree d-flex align-items-center">
            <input
              className="m-check-input"
              type="checkbox"
              id="m-agree"
              onChange={handleToggleChange} // Call handleToggleChange on change
            />
            <label className="m-check-label" htmlFor="m-agree">
              Create Organization account
            </label>
          </div>
        </div> */}
        {isOrganizationNameEnabled && ( // Render organization name field conditionally
          <div className="sign__input-wrapper mb-25">
            <h5>Organization Name</h5>
            <div className="sign__input">
              <input
                value={values.organizationName}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Organization name"
                name="organizationName"
              />
              <i className="fal fa-building"></i>
            </div>
            {isExist && <ErrorMsg error={"orgnazition is already exist"} />}
            {isOrganizationNameEnabled && isExist && (
              <ErrorMsg error={"Organization already exists"} />
            )}
          </div>
        )}
        <Button type="submit" disabled={loading} style={{ width: "100%" }}>
          {loading ? <span class="loader"></span> : t("sign up")}
        </Button>
        {/* <button className="e-btn w-100" disabled={loading}>
        <span></span> {loading ? "Registration" : "Sign Up"}
      </button> */}
        <div className="sign__new text-center mt-20">
          <p>
            {t("already in")} Edu.algerspace?{" "}
            <Link to="/sign-in">{t("sign in")}</Link>
          </p>
        </div>
      </form>
    </>
  );
};

export default RegisterForm;
