import React from "react";
// import styled from "styled-components";
// // import { Link } from "react-router-dom";
// const H2 = styled.div``;
import { Row, Col, Container } from "react-bootstrap";
import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";
import CreateCourseForm from "../../../components/common/forms/course-form";
import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import ContentDashboard from "../../../layout/layer/Layer";
import { useSearchParams, useNavigate } from "react-router-dom";
import { FaLongArrowAltLeft, FaLongArrowAltRight } from "react-icons/fa";
import { useTranslation } from "react-i18next";
const CreateCourse = () => {
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Header />
      <section className=" po-rel-z1 pt-130 pb-145">
        <SidebarTeacher />
        <ContentDashboard>
          <div
            onClick={() => navigate(-1)}
            style={{
              fontWeight: "bold",
              fontSize: "18px",
              cursor: "pointer",
              color: "#2386C8",
            }}
          >
            {t("lang") === "ar" ? (
              <FaLongArrowAltRight />
            ) : (
              <FaLongArrowAltLeft />
            )}

            {t("go back")}
          </div>{" "}
          <h2>
            {!search.get("course-id") ? t("create new") : t("edit")}{" "}
            {t("course")}
          </h2>
          <CreateCourseForm />
        </ContentDashboard>
      </section>
    </Wrapper>
  );
};
export default CreateCourse;
