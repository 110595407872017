import styled from "styled-components";
import { compose, flexbox } from "styled-system";

const mediaSize = {
  xs: 0,
  sm: 426,
  md: 769,
  lg: 1025,
  xl: 1441,
};

const StyledGrid = styled.div((props) => {
  const {
    container,
    item,
    spacing,
    horizontal_spacing,
    vertical_spacing,
    xl,
    lg,
    md,
    sm,
    xs,
    containerHeight,
  } = props;

  let mediaProps = { xl, lg, md, sm, xs };
  let style = {};

  if (container) {
    style = {
      display: "flex",
      flexWrap: "wrap",
      height: containerHeight,
      margin: spacing ? `-${(spacing / 2) * 0.25}rem` : "unset",
    };

    if (horizontal_spacing) {
      style.marginLeft = `-${(horizontal_spacing / 2) * 0.25}rem`;
      style.marginRight = `-${(horizontal_spacing / 2) * 0.25}rem`;
    }
    if (vertical_spacing) {
      style.marginTop = `-${(vertical_spacing / 2) * 0.25}rem`;
      style.marginBottom = `-${(vertical_spacing / 2) * 0.25}rem`;
    }
  } else if (item) {
    if (spacing) style.padding = `${(spacing / 2) * 0.25}rem`;

    if (horizontal_spacing) {
      style.paddingLeft = `${(horizontal_spacing / 2) * 0.25}rem`;
      style.paddingRight = `${(horizontal_spacing / 2) * 0.25}rem`;
    }

    if (vertical_spacing) {
      style.paddingTop = `${(vertical_spacing / 2) * 0.25}rem`;
      style.paddingBottom = `${(vertical_spacing / 2) * 0.25}rem`;
    }

    for (const key in mediaSize) {
      if (mediaProps[key]) {
        style[`@media only screen and (min-width: ${mediaSize[key]}px)`] = {
          width: `${(mediaProps[key] / 12) * 100}%`,
        };
      }
    }
  }

  return style;
}, compose(flexbox));

export default StyledGrid;
