// App.js

import React from "react";
import { Link } from "react-router-dom";
import { Badge, Nav } from "react-bootstrap";
import { FaSchool } from "react-icons/fa";
import { SiCreatereactapp } from "react-icons/si";
import { useLocation } from "react-router-dom";
import { IoIosBookmarks } from "react-icons/io";
import { IoWalletOutline } from "react-icons/io5";
import { CiHome } from "react-icons/ci";
import { FiHeadphones, FiHome } from "react-icons/fi";
import styled from "styled-components";
import { Spin as Hamburger } from "hamburger-react";
import { CiSettings } from "react-icons/ci";
import { FiSettings } from "react-icons/fi";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { FiBookOpen } from "react-icons/fi";
import { FiArchive } from "react-icons/fi";
import { FiUser } from "react-icons/fi";
import { AiOutlineSafety } from "react-icons/ai";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FiMessageCircle } from "react-icons/fi";
import { H5 } from "../Typography";
const SidebarDesktop = styled.div`
  display: block;
  @media (max-width: 1199px) {
    display: none;
  }
`;
const SidebarTeacher = ({ active }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const path = location.pathname; // This gives you the current path
  const { user } = useSelector((state) => state.user);

  // Extracting the part you need from the URL
  const parts = path.split("/");
  const currentPage = parts[parts.length - 1]; // Getting the last part of the URL
  const [isOpen, setOpen] = React.useState(false);
  const fetchOgrnazation = () => {
    // FETCH MY ORGANIZATION
  };

  const { total_count } = useSelector((state) => state.notification);
  return (
    <SidebarDesktop>
      {/* <ButtonMenu isOpen={isOpen}>
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </ButtonMenu> */}
      <Sidebar
        isOpen={isOpen}
        rtl={!!localStorage.getItem("lang")}
        id="menu-desktop"
      >
        {user.isSignedIn ? (
          <Nav
            variant="pills"
            className="flex-column"
            style={{ paddingTop: "100px" }}
          >
            <H5 color="white">Dashboard</H5>
            <Nav.Item>
              <NavLink
                to="/dashboard/"
                active={currentPage === ""}
                //className={`nav-link ${currentPage === "" ? "active" : ""}`}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
                id="menu-dashboard"
              >
                <TbBrandGoogleAnalytics style={{ marginRight: "10px" }} />
                {t("dashboard")}{" "}
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/dashboard/settings"
                active={currentPage === "settings"}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
                id="menu-account"
              >
                <FiUser style={{ marginRight: "10px" }} /> {t("account")}
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/dashboard/messages"
                active={
                  currentPage === "messages" || currentPage === "messages"
                }
                style={{ marginBottom: "10px", paddingTop: "15px" }}
                id="menu-courses"
              >
                <FiMessageCircle style={{ marginRight: "10px" }} />{" "}
                {t("messages")}
                <Badge bg="danger" style={{ float: "right" }}>
                  New
                </Badge>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/dashboard/notifications"
                active={
                  currentPage === "notifications" ||
                  currentPage === "notifications"
                }
                style={{ marginBottom: "10px", paddingTop: "15px" }}
                id="menu-courses"
              >
                <FiMessageCircle style={{ marginRight: "10px" }} />{" "}
                {t("notifications")}
                {total_count > 0 && (
                  <Badge bg="danger" style={{ float: "right" }}>
                    {total_count > 99 ? "99+" : total_count}
                  </Badge>
                )}
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/support"
                active={currentPage === "support"}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
                id="menu-account"
              >
                <FiHeadphones style={{ marginRight: "10px" }} /> {t("support")}
              </NavLink>
            </Nav.Item>
            {true && (
              <>
                <H5 color="white">Teacher</H5> <hr />
                {/* Your Nav links here */}
                {/* Example: */}
                <Nav.Item>
                  <NavLink
                    to="/dashboard/courses"
                    active={
                      currentPage === "courses" || currentPage === "course"
                    }
                    style={{ marginBottom: "10px", paddingTop: "15px" }}
                    id="menu-courses"
                  >
                    <FiArchive style={{ marginRight: "10px" }} />{" "}
                    {t("my courses")}
                  </NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink
                    to="/dashboard/account-verification"
                    active={currentPage === "verify-account"}
                    style={{ marginBottom: "10px", paddingTop: "15px" }}
                    id="menu-verfiy-account"
                  >
                    <AiOutlineSafety style={{ marginRight: "10px" }} />
                    {t("verfiy account")}
                  </NavLink>
                </Nav.Item>
              </>
            )}
            <H5 color="white">Student</H5> <hr />
            <Nav.Item>
              <NavLink
                to="/dashboard/my-learning"
                active={currentPage === "my-learning"}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
                id="menu-my-learning"
              >
                <FiBookOpen style={{ marginRight: "10px" }} />{" "}
                {t("my learning")}
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/dashboard/payment"
                active={currentPage === "verify-account"}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
                id="menu-payment"
              >
                <FaMoneyBillTransfer style={{ marginRight: "10px" }} />
                {t("payment")}
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="#"
                className={`nav-link ${
                  currentPage === "icome" ? "active" : ""
                }`}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
                id="menu-income"
              >
                <IoWalletOutline style={{ marginRight: "10px" }} />{" "}
                {t("income")}
              </NavLink>
            </Nav.Item>
            {user.isTeacher && (
              <Nav.Item>
                <NavLink
                  to="#"
                  className={`nav-link ${
                    currentPage === "event" ? "active" : ""
                  }`}
                  style={{ marginBottom: "10px", paddingTop: "15px" }}
                  id="menu-income"
                >
                  <IoWalletOutline style={{ marginRight: "10px" }} />{" "}
                  {t("event")}
                </NavLink>
              </Nav.Item>
            )}
            <Nav.Item>
              <NavLink
                to="#"
                className={`nav-link ${
                  currentPage === "podcast" ? "active" : ""
                }`}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
                id="menu-income"
              >
                <IoWalletOutline style={{ marginRight: "10px" }} />
                {t("podcast")}
              </NavLink>
            </Nav.Item>
            {/* Other NavLinks */}
          </Nav>
        ) : (
          <Nav>
            <Nav.Item>
              <NavLink
                to="/"
                active={currentPage === ""}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
              >
                <FiUser style={{ marginRight: "10px" }} /> {t("home")}
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/page/courses"
                active={currentPage === ""}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
                id="menu-courses"
              >
                <FiBookOpen style={{ marginRight: "10px" }} /> {t("courses")}
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/about"
                active={currentPage === ""}
                style={{ marginBottom: "10px", paddingTop: "15px" }}
              >
                {/* <fiExol style={{ marginRight: "10px" }} />  */}
                {t("about")}
              </NavLink>
            </Nav.Item>
          </Nav>
        )}
      </Sidebar>
    </SidebarDesktop>
  );
};

export default SidebarTeacher;

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 360px;
  padding: 10px;
  transition: ${({ rtl }) => (rtl ? "right" : "left")} 0.3s ease;
  background-color: #2386c8;
  left: ${({ rtl }) => (rtl ? "0" : "0")};
  right: ${({ rtl }) => (rtl ? "380px" : "0")};
  max-height: 100vh; /* Constrain to viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  box-shadow: ${({ isOpen, rtl }) =>
    isOpen
      ? rtl
        ? "15px 0 15px rgba(0, 0, 0, 0.3)"
        : "-5px 0 15px rgba(0, 0, 0, 0.3)"
      : "none"};

  @media (max-width: 1199px) {
    left: ${({ rtl, isOpen }) => (rtl ? "auto" : isOpen ? "0" : "-380px")};
    right: ${({ rtl, isOpen }) => (rtl ? (isOpen ? "0" : "-380px") : "auto")};
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none; /* Hide scrollbar */
  }
`;

const NavLink = styled(Link)`
  display: block;
  margin-bottom: 10px;
  background-color: white;
  padding: 15px;
  text-decoration: none;
  background: 0 0;
  border-radius: 4px;
  border: 0;
  color: white;
  font-weight: bold;
  background-color: ${({ active }) => (active ? "#41acf3" : "transparent")};
  transi &:hover {
    color: white !important;
  }
`;

const ButtonMenu = styled.div`
  z-index: 21;
  position: fixed;
  top: 100px;
  left: 0px;
  bottom: 0;
  width: 360px;
  padding: 10px;
  display: none;
  transition: left 0.3s ease; /* Define transition property */
  @media (max-width: 1199px) {
    display: block;
    top: 115px;
    left: ${({ isOpen }) =>
      isOpen ? "150px" : "10px"}; /* Toggle sidebar based on isOpen state */
  }
`;
