import React from "react";
// import styled from "styled-components";
// // import { Link } from "react-router-dom";
// const H2 = styled.div``;

import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";

import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import ContentDashboard from "../../../layout/layer/Layer";
import MyLearningContent from "../../../components/common/course/MyLearningList";

const MyLearning = () => {
  return (
    <Wrapper>
      <Header />
      <section className=" po-rel-z1 pt-130 pb-145">
        <SidebarTeacher />
        <ContentDashboard>
          <h2>My Learning</h2>

          <MyLearningContent />
        </ContentDashboard>
      </section>
    </Wrapper>
  );
};
export default MyLearning;
