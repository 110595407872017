import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react"; // Import useState hook

import ErrorMsg from "./error-msg";
import { AccountSchema } from "./validation-schema";
import axios from "axios";
import AvatarUploader from "../../../components/common/avatar/upload.avatar";
import wilia from "../../../pages/dashboard/payment/wiliya.json";

import {
  Alert,
  Row,
  Col,
  FormControl,
  Form,
  FormGroup,
  FormLabel,
} from "react-bootstrap";

const AccountForm = () => {
  const navigate = useNavigate();
  const [isExist, setisExist] = useState();
  const [user, setuser] = useState(null);
  const [image, setImage] = useState("");
  const [isVerified, setisVerified] = useState(false);
  const [isExistUsername, setisExistUsername] = useState();
  const [isOrganizationNameEnabled, setIsOrganizationNameEnabled] =
    useState(false); // State for toggle switch

  function getUserAccount() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };

    axios
      .get(`${process.env.REACT_APP_API_URL}/account`, config)
      .then((res) => {
        setFieldValue("name", res.data.user.fullname);
        setFieldValue("email", res.data.user.email);
        setFieldValue("username", res.data.user.username);
        setFieldValue("phone", res.data.user.phone);
        setFieldValue("avatar", res.data.user.avatar);
        setFieldValue("address", res.data.user.address);
        setFieldValue("wilaya", res.data.user.wilaya);
        setFieldValue("x", res.data.user.social_media.x);
        setFieldValue("instagram", res.data.user.social_media.instagram);
        setFieldValue("facebook", res.data.user.social_media.facebook);
        setFieldValue("linkedin", res.data.user.social_media.linkedin);

        setFieldValue("short_bio", res.data.user.short_bio);
        setImage(res.data.user.avatar.url);
        setuser(res.data.user.username);
      });
  }

  useEffect(() => {
    getUserAccount();
  }, []);
  const handleOnSubmit = (values, { resetForm }) => {
    if (
      isOrganizationNameEnabled &&
      values.organizationName &&
      values.organizationName?.length > 2
    ) {
      handleUpdateAccount(values);
    } else {
      handleUpdateAccount(values);
    }
    // resetForm()
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    values,
    touched,
    setFieldValue,
    setFieldError,
  } = useFormik({
    initialValues: {
      name: "",
      email: "",
      username: "",
      phone: "",
      wilaya: "",
      address: "",
      avatar: "",
      short_bio: "",
      x: "",
      facebook: "",
      instagram: "",
      linkedin: "",
      //password: "",
      // organizationName: "", // Add organizationName field to initialValues
    },
    validationSchema: AccountSchema,
    onSubmit: handleOnSubmit,
  });

  // Update the handleChange function to handle fullname changes
  const updatedHandleChange = (event) => {
    const { name, value } = event.target;

    // If the fullname field is being changed, update the username accordingly
    if (name === "name") {
      // Logic to update the username based on the fullname value
      const usernameFromFullname = value.replace(/\s+/g, "").toLowerCase(); // Example logic to create username from fullname

      // Set the updated values for fullname and username fields
      handleChange({
        target: {
          name: "name",
          value,
        },
      });

      handleChange({
        target: {
          name: "username",
          value: usernameFromFullname,
        },
      });
    } else {
      // For other fields, handle changes as usual
      handleChange(event);
    }
  };

  function checkorganization(organizationName) {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/check-organization/${organizationName}`
      )
      .then((res) => {
        setisExist(res.data.isExist);
      });
  }

  function handleUpdateAccount({
    username,
    name,
    email,
    phone,
    avatar,
    short_bio,
    x,
    facebook,
    instagram,
    linkedin,
  }) {
    const data = {
      username: username,
      fullname: name,
      email: email,
      phone,
      avatar,
      short_bio,
      x,
      facebook,
      instagram,
      linkedin,
    };
    const config = {
      headers: {
        //"Content-Type": selectedFile.type,

        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };
    axios
      .put(` ${process.env.REACT_APP_API_URL}/account`, data, config)
      .then((res) => {
        // window.localStorage.setItem("jwt", res.data.jwt);
        console.log(res);
      });
  }
  function checkorUsername(username) {
    if (user !== username) {
      axios
        .get(`${process.env.REACT_APP_API_URL}/check-username/${username}`)
        .then((res) => {
          setisExistUsername(res.data.isExist);
        });
    }
  }
  useEffect(() => {
    if (isOrganizationNameEnabled) {
      const delay = setTimeout(() => {
        checkorganization(values.organizationName);
      }, 1000);

      return () => clearTimeout(delay);
    } else {
      handleChange({
        target: {
          name: "organizationName",
          value: "",
        },
      });
    }
  }, [values.organizationName, isOrganizationNameEnabled]);
  useEffect(() => {
    const delay = setTimeout(() => {
      checkorUsername(values.username);
    }, 1000);

    return () => clearTimeout(delay);
  }, [values.username]);

  const updateAvatar = async (file) => {
    // upload image first
    console.log(file);
    const formData = new FormData();
    formData.append("files", file); // Ensure blob is a valid Blob object
    const config = {
      headers: {
        //"Content-Type": selectedFile.type,
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };
    const result = await axios.post(
      `${process.env.REACT_APP_API_URL}/upload/`,
      formData,
      config
    );
    localStorage.setItem("avatar", result.data[0].url);
    console.log(result.data[0].url);
    setImage(result.data[0].url);
    const option = {
      headers: {
        //"Content-Type": selectedFile.type,
        // "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };
    await axios
      .put(
        `${process.env.REACT_APP_API_URL}/account-avatar`,
        { avatar: result.data },
        option
      )
      .then((res) => {
        // window.localStorage.setItem("jwt", res.data.jwt);
        console.log(res);
      });

    alert("Course has upadated");
    // navigate(`/dashboard/my-course/${res.data.id}`);
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="sign__input-wrapper mb-25">
        <Alert variant={isVerified ? "success" : "warning"}>
          {isVerified ? (
            "Your Account Is verifed"
          ) : (
            <div>
              You can verify your account{" "}
              <Link
                to="/dashboard/account-verification"
                style={{ fontWeight: "bold" }}
              >
                Verifiy account
              </Link>
            </div>
          )}
        </Alert>
        <AvatarUploader
          getFile={updateAvatar}
          avatarUrl={process.env.REACT_APP_DOMAIN_BACKEND + image}
        />
        <h5>Full Name</h5>
        <div className="sign__input">
          <input
            value={values.name}
            onChange={updatedHandleChange}
            onBlur={handleBlur}
            type="text"
            placeholder="Full name"
            name="name"
            disabled
          />
          <i className="fal fa-user"></i>
        </div>
        {touched.name && <ErrorMsg error={errors.name} />}
      </div>
      <div className="sign__input-wrapper mb-25">
        <h5>short_bio</h5>
        <div className="sign__input">
          <textarea
            value={values.short_bio}
            onChange={updatedHandleChange}
            onBlur={handleBlur}
            type="text"
            className="form-control"
            placeholder="Full name"
            name="short_bio"
          />
          <i className="fal fa-user"></i>
        </div>
        {touched.short_bio && <ErrorMsg error={errors.short_bio} />}
      </div>

      <div className="sign__input-wrapper mb-25">
        <h5>Email</h5>
        <div className="sign__input">
          <input
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            type="email"
            placeholder="e-mail address"
            name="email"
          />
          <i className="fal fa-envelope"></i>
        </div>
        {touched.email && <ErrorMsg error={errors.email} />}
      </div>
      <Row>
        <Col md={6}>
          <div className="sign__input-wrapper mb-25">
            <h5>Username</h5>
            <div className="sign__input">
              <input
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="Username"
                name="username"
                disabled
              />
              <i className="fal fa-user"></i>
            </div>
            {isExistUsername && <ErrorMsg error="Username is taken" />}
            {touched.username && <ErrorMsg error={errors.username} />}
          </div>
        </Col>{" "}
        <Col md={6}>
          <div className="sign__input-wrapper mb-25">
            <h5>Phone</h5>
            <div className="sign__input">
              <input
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="phone"
                name="phone"
              />
              <i className="fal fa-user"></i>
            </div>
            {touched.phone && <ErrorMsg error={errors.phone} />}
          </div>
        </Col>
        <Col>
          {/* Wilaya */}
          <FormGroup controlId="wilaya">
            <FormLabel
              column
              sm={2}
              style={{ margin: "0", padding: "4px 0px" }}
            >
              Wilaya
            </FormLabel>

            <Form.Select
              aria-label="Default select example"
              name="wilaya"
              onChange={handleChange}
            >
              <option value="" disabled>
                Select Wilaya
              </option>
              {wilia?.map((item) => {
                return (
                  <option value={item.value} key={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </Form.Select>

            <FormControl.Feedback type="invalid">
              {errors.wilaya}
            </FormControl.Feedback>
          </FormGroup>
        </Col>
        <Col>
          <div>
            <h5>Address</h5>
            <div className="sign__input">
              <input
                value={values.address}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="address"
                name="address"
              />
              <i className="fal fa-location"></i>
            </div>
            {touched.address && <ErrorMsg error={errors.address} />}
          </div>
        </Col>
        <h4>Social Media</h4>
        <hr />
        <Col md={6}>
          <div className="sign__input-wrapper mb-25">
            <h5>Facebook</h5>
            <div className="sign__input">
              <input
                value={values.facebook}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="e-mail address"
                name="facebook"
              />
              <i className="fal fa-facebook"></i>
            </div>
            {touched.facebook && <ErrorMsg error={errors.facebook} />}
          </div>
        </Col>{" "}
        <Col md={6}>
          <div className="sign__input-wrapper mb-25">
            <h5>Instagram</h5>
            <div className="sign__input">
              <input
                value={values.instagram}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="e-mail address"
                name="instagram"
              />
              <i className="fal fa-instagram"></i>
            </div>
            {touched.instagram && <ErrorMsg error={errors.instagram} />}
          </div>
        </Col>
        <Col md={6}>
          <div className="sign__input-wrapper mb-25">
            <h5>X</h5>
            <div className="sign__input">
              <input
                value={values.x}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="e-mail address"
                name="x"
              />
              <i className="fal fa-x"></i>
            </div>
            {touched.x && <ErrorMsg error={errors.x} />}
          </div>
        </Col>
        <Col md={6}>
          <div className="sign__input-wrapper mb-25">
            <h5>Linkedin</h5>
            <div className="sign__input">
              <input
                value={values.linkedin}
                onChange={handleChange}
                onBlur={handleBlur}
                type="text"
                placeholder="e-mail address"
                name="linkedin"
              />
              <i className="fal fa-linkedin"></i>
            </div>
            {touched.linkedin && <ErrorMsg error={errors.linkedin} />}
          </div>
        </Col>
      </Row>
      {/* <div className="sign__input-wrapper mb-25">
        <h5>Password</h5>
        <div className="sign__input">
          <input
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            placeholder="Password"
            name="password"
          />
          <i className="fal fa-lock"></i>
        </div>
        {touched.password && <ErrorMsg error={errors.password} />}
      </div>

      <div className="sign__input-wrapper mb-10">
        <h5>Re-Password</h5>
        <div className="sign__input">
          <input
            value={values.passwordConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            type="password"
            placeholder="Re-Password"
            name="passwordConfirmation"
          />
          <i className="fal fa-lock"></i>
        </div>
        {touched.passwordConfirmation && (
          <ErrorMsg error={errors.passwordConfirmation} />
        )}
      </div> */}

      {/* <div className="sign__action d-flex justify-content-between mb-30">
        <div className="sign__agree d-flex align-items-center">
          <input
            className="m-check-input"
            type="checkbox"
            id="m-agree"
            onChange={handleToggleChange} // Call handleToggleChange on change
          />
          <label className="m-check-label" htmlFor="m-agree">
            Create Organization account
          </label>
        </div>
      </div>
      {isOrganizationNameEnabled && ( // Render organization name field conditionally
        <div className="sign__input-wrapper mb-25">
          <h5>Organization Name</h5>
          <div className="sign__input">
            <input
              value={values.organizationName}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
              placeholder="Organization name"
              name="organizationName"
            />
            <i className="fal fa-building"></i>
          </div>
          {isExist && <ErrorMsg error={"orgnazition is already exist"} />}
          {isOrganizationNameEnabled && isExist && (
            <ErrorMsg error={"Organization already exists"} />
          )}
        </div>
      )} */}
      <button className="e-btn w-100">
        <span></span> Update
      </button>
    </form>
  );
};

export default AccountForm;
