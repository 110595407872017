import React, { useEffect, useState } from "react";
import SEO from "../../../components/seo";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import axios from "axios";
import ErrorMsg from "../../../components/common/forms/error-msg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Row, Col, ListGroup, Table, Button, Modal } from "react-bootstrap";
import { MdOutlineEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import ChapiterTable, {
  ActionButton,
} from "../../../components/costomComponents/ChapiterTable";
import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";
import ContentDashboard from "../../../layout/layer/Layer";
import { useQuery } from "react-query";
import Moment from "react-moment";
import { FaRegTrashAlt } from "react-icons/fa";
import {
  FormGroup,
  FormLabel,
  Form,
  Tabs,
  Tab,
  FormControl,
} from "react-bootstrap";
import Switch from "rc-switch";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";

import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { driver } from "driver.js";

const isExpired = (date) => {
  const currentDate = new Date();
  return currentDate > new Date(date);
};

const CourseDashboard = () => {
  const [course, setCourse] = React.useState(null);
  const { t } = useTranslation();
  React.useEffect(() => {
    if (!localStorage.getItem("course-details-guide")) {
      const driverObj = driver({
        showProgress: true,
        steps: [
          {
            element: "#new-chapiter",
            popover: {
              title: "Add New Chapter",
              description:
                "Click here to add a new chapter to the course. Define the structure and content of your course.",
            },
          },
          {
            element: "#new-class",
            popover: {
              title: "Create a New Class",
              description:
                "Click here to create a new class. Specify the class name, schedule, and associated course.",
            },
          },
        ],
      });
      driverObj?.drive();
      localStorage.setItem("course-details-guide", true);
    }
  }, []);
  return (
    <Wrapper>
      <SEO pageTitle={"Course"} />
      <Header />
      <section className=" po-rel-z1 pt-10 pb-145">
        <SidebarTeacher />
        <ContentDashboard>
          <Tabs
            defaultActiveKey="course"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="course" title="Course">
              <CourseInformation getCourse={(e) => setCourse(e)} />
              <ChapiterTable courseId={course?.id} />
            </Tab>
            <Tab eventKey="classes" title="Classes">
              <ClassSection course={course} />
            </Tab>
          </Tabs>
        </ContentDashboard>
      </section>
    </Wrapper>
  );
};

const CourseInformation = ({ getCourse }) => {
  const { id } = useParams();
  const [course, setCourse] = useState();
  const navigate = useNavigate();
  const fetchMyCourse = () => {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };
    try {
      axios
        .get(`${process.env.REACT_APP_API_URL}/my-course/${id}`, config)
        .then((res) => {
          if (!res.data.course) {
            navigate("/404");
          } else {
            console.log(res.data);
            setCourse(res.data.course);
            getCourse(res.data.course);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchMyCourse();
  }, []);

  return (
    <div>
      <section>
        <div style={{ marginBottom: "30px" }}>
          <div className="row">
            <div className="col-xxl-2 col-xl-2 col-lg-2  ">
              <img
                src={process.env.REACT_APP_DOMAIN_BACKEND + course?.image}
                width="100%"
                style={{ maxHeight: "200px", objectFit: "contain" }}
              />
            </div>
            <div className="col-xxl-10 col-xl-10 col-lg-10">
              <div className="course __wrapper">
                <div className="page__title-content mb-25">
                  <span className="page__title-pre">
                    {course?.category?.name}
                  </span>
                  <h5 className="page__title-3">{course?.name}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

const ClassSection = ({ course }) => {
  const [form, setForm] = React.useState(false);
  const [currentClass, setCurrentClass] = React.useState(null);
  const handleAddedClass = () => {
    setForm(false);
    setCurrentClass(null);
  };
  const onHandleEditClass = (classTarget) => {
    console.log(classTarget);
    setForm(true);
    setCurrentClass(classTarget);
  };
  return (
    <div style={{ marginTop: "70px" }}>
      {form ? (
        <>
          <h2>{currentClass ? "Update" : "Create"} Class</h2>
          <Button
            className="e-btn btn-gray"
            variant="warning"
            style={{ margin: "10px 0px" }}
            onClick={() => handleAddedClass()}
          >
            {t("go back")}
          </Button>

          {/* <SearchCourse getCourse={handleCourse} /> */}
          {course && (
            <CreateClass
              courseId={course.id}
              classToEdit={currentClass}
              classId={currentClass?.id}
              handleAddedClass={handleAddedClass}
            />
          )}
        </>
      ) : (
        <>
          <h2>{t("classes")}</h2>
          <Row>
            <Col md="6" style={{ float: "right" }}>
              <Button
                className="e-btn"
                style={{ margin: "10px 0px" }}
                onClick={() => setForm(true)}
                id="new-class"
              >
                {t("add class")}
              </Button>
            </Col>

            <Col md="6" style={{ float: "left" }}>
              <input
                type="text"
                class="form-control"
                placeholder="Search by course"
              />
            </Col>
          </Row>
          <ClassTable onHandleEditClass={onHandleEditClass} />
        </>
      )}
    </div>
  );
};

const CreateClass = ({ courseId, handleAddedClass, classToEdit, classId }) => {
  const formik = useFormik({
    initialValues: {
      name: null,
      startDate: new Date(),
      startClassDate: new Date(),
      endDate: "",
      deadline: false,
      limitMembers: false,
      members: 1,
    },
    validationSchema: Yup.object().shape({
      startDate: Yup.date().required("Start Date is required"),
      startClassDate: Yup.date().required("Start Date is required"),
      endDate: Yup.date().when("deadline", {
        is: true,
        then: Yup.date().required("End Date is required"),
        otherwise: Yup.date().notRequired(),
      }),
      members: Yup.number().when("limitMembers", {
        is: true,
        then: Yup.number()
          .min(1, "Members should be more than 0")
          .required("Members are required"),
        otherwise: Yup.number().notRequired(),
      }),
    }),
    onSubmit: async (values) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        };
        if (classId && classToEdit) {
          const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/class/${classId}`,
            { ...values, courseId },
            config
          ); // Replace 'your_api_endpoint' with your actual API URL
        } else {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/class`,
            { ...values, courseId },
            config
          ); // Replace 'your_api_endpoint' with your actual API URL
        }

        // Display success message using SweetAlert upon successful request
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Your data has been submitted successfully.",
        }).then(() => {
          handleAddedClass();
        });
      } catch (error) {
        // Handle error case
        console.error("Error submitting data:", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    },
  });

  // If classToEdit is provided, populate the form with its data
  React.useEffect(() => {
    if (classToEdit) {
      formik.setFieldValue("name", classToEdit.name);
      formik.setFieldValue("startDate", new Date(classToEdit.startDate));
      // formik.setFieldValue("startClassDate", classToEdit.name);
      formik.setFieldValue(
        "startClassDate",
        new Date(classToEdit.startClassDate)
      );
      formik.setFieldValue("endDate", new Date(classToEdit.endDate));
      formik.setFieldValue("deadline", !!classToEdit.endDate);
      formik.setFieldValue("limitMembers", !!classToEdit.member);
      formik.setFieldValue("members", classToEdit.member);
    } else {
      formik.resetForm();
    }
  }, [classToEdit]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col md="12">
            <FormGroup>
              <FormLabel>Name of class (optional)</FormLabel>
              <FormControl
                name="name"
                type="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                isInvalid={formik.errors.name && formik.touched.name}
              />
              <FormControl.Feedback type="invalid">
                {formik.errors.name}
              </FormControl.Feedback>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <FormLabel>Start Date (Registration)*</FormLabel>
              <DatePicker
                name="startDate"
                selected={formik.values.startDate}
                onChange={(date) => {
                  formik.setFieldValue("startDate", date);
                  formik.setFieldValue("startClassDate", date);
                  if (formik.values.deadline) {
                    let newEndDate = new Date(
                      date.getTime() + 24 * 60 * 60 * 1000
                    ); // Set endDate as startDate + 1 day
                    // Check if endDate is less than startDate, update it to startDate if necessary
                    if (
                      formik.values.endDate &&
                      newEndDate < formik.values.endDate
                    ) {
                      newEndDate = date;
                    }
                    formik.setFieldValue("endDate", newEndDate);
                  }
                }}
                dateFormat="dd MMMM yyyy"
                className="form-control"
                style={{ width: "100%" }} // Add any required class for styling
              />
              <FormControl.Feedback type="invalid">
                {formik.errors.startDate}
              </FormControl.Feedback>{" "}
              <small className="text-muted">
                This is the date when the class will become available for
                enrollment.
              </small>
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <FormLabel>Class Start on*</FormLabel>
              <DatePicker
                name="startClassDate"
                selected={formik.values.startClassDate}
                onChange={(date) => {
                  formik.setFieldValue("startClassDate", date);
                }}
                dateFormat="dd MMMM yyyy"
                className="form-control" // Add any required class for styling
              />
              <FormControl.Feedback type="invalid">
                {formik.errors.startClassDate}
              </FormControl.Feedback>{" "}
              <small className="text-muted">
                The attachement course will be availble for students
              </small>
            </FormGroup>
          </Col>
          <Col md="6">
            <Switch
              checked={formik.values.deadline}
              // disabled={item.payment_method !== "Cash"}
              onChange={(e) => {
                formik.setFieldValue("deadline", e);
                if (e) {
                  const newEndDate = new Date(
                    formik.values.startDate.getTime() + 24 * 60 * 60 * 1000
                  );
                  if (
                    formik.values.endDate &&
                    formik.values.startDate > formik.values.endDate
                  ) {
                    formik.setFieldValue("endDate", newEndDate);
                  }
                } else {
                  formik.setFieldValue("endDate", null);
                }
              }}
            />
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              {" "}
              Enrolled deadline
            </span>
            {/* 
            <Form.Check
              name="deadline"
              type="checkbox"
              label={``}
              style={{
                borderRadius: "2px",
              }}
              onChange={(e) => {
                formik.handleChange(e);
                if (e.target.checked) {
                  const newEndDate = new Date(
                    formik.values.startDate.getTime() + 24 * 60 * 60 * 1000
                  );
                  if (
                    formik.values.endDate &&
                    formik.values.startDate > formik.values.endDate
                  ) {
                    formik.setFieldValue("endDate", newEndDate);
                  }
                }
              }}
              checked={formik.values.deadline}
            /> */}

            <FormGroup>
              <FormLabel>End Date</FormLabel>

              <DatePicker
                name="endDate"
                selected={formik.values.endDate}
                onChange={(date) => formik.setFieldValue("endDate", date)}
                minDate={
                  formik.values.startDate
                    ? new Date(
                        formik.values.startDate.getTime() + 24 * 60 * 60 * 1000
                      )
                    : null
                }
                dateFormat="dd MMMM yyyy"
                disabled={!formik.values.deadline}
                className="form-control" // Add any required class for styling
              />

              <FormControl.Feedback type="invalid">
                {formik.errors.endDate}
              </FormControl.Feedback>
            </FormGroup>
          </Col>

          <Col md="6">
            <Switch
              checked={formik.values.limitMembers}
              // disabled={item.payment_method !== "Cash"}
              onChange={(e) => {
                formik.setFieldValue("limitMembers", e);
                if (e) {
                  const newEndDate = new Date(
                    formik.values.startDate.getTime() + 24 * 60 * 60 * 1000
                  );
                  if (
                    formik.values.endDate &&
                    formik.values.startDate > formik.values.endDate
                  ) {
                    formik.setFieldValue("endDate", newEndDate);
                  }
                }
              }}
            />
            <span style={{ marginLeft: "10px", fontWeight: "bold" }}>
              {" "}
              Limit student for this class
            </span>
            {formik.values.limitMembers && (
              <FormGroup>
                <FormLabel>Members *</FormLabel>
                <FormControl
                  name="members"
                  type="number"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Number"
                  isInvalid={formik.errors.members && formik.touched.members}
                  disabled={!formik.values.limitMembers}
                  value={formik.values.members}
                />
                <FormControl.Feedback type="invalid">
                  {formik.errors.members}
                </FormControl.Feedback>
              </FormGroup>
            )}
          </Col>
        </Row>
        <Button type="submit" className="e-btn" style={{ marginTop: "20px" }}>
          Submit
        </Button>
      </form>
    </>
  );
};

const validationSchema = Yup.object().shape({
  startDate: Yup.date().required("Start Date is required"),
  startClassDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().when("deadline", {
    is: true,
    then: Yup.date().required("End Date is required"),
    otherwise: Yup.date().notRequired(),
  }),
  members: Yup.number().when("limitMembers", {
    is: true,
    then: Yup.number()
      .min(1, "Members should be more than 0")
      .required("Members are required"),
    otherwise: Yup.number().notRequired(),
  }),
});

function ClassTable({ onHandleEditClass }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");
  const { isLoading, isError, data, error, refetch } = useQuery(
    ["my-classes", page],
    () => fetchClasses(page)
  );

  async function fetchClasses(page) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };

    const response = await axios.get(
      `${
        process.env.REACT_APP_API_URL
      }/my-classes-by-course?courseId=${id}&page=${page || 1}`,
      config
    );
    return response.data;
  }

  const onhdanleDeleteClass = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this class!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // User clicked the confirm button
        // Perform the deletion action here
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          };

          const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/class/${item.id}/${item.course.id}`,
            config
          );

          if (response.status === 200) {
            // Successful deletion
            Swal.fire({
              icon: "success",
              title: "Deleted!",
              text: "Your class has been deleted.",
            });
            refetch();
          } else {
            // Handle other response statuses if needed
            Swal.fire({
              icon: "error",
              title: "Error",
              text: "Failed to delete class. Please try again later.",
            });
          }
        } catch (error) {
          // Handle errors
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while deleting the class. Please try again later.",
          });
          console.error("Delete class error:", error);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // User clicked the cancel button or closed the modal
        Swal.fire({
          icon: "error",
          title: "Cancelled",
          text: "Your class is safe :)",
        });
      }
    });
  };

  if (isLoading) {
    return <div> {t("go back")}Loading...</div>;
  }

  if (isError) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th> {t("go back")}</th>
            <th> {t("owner")}</th>
            <th> {t("class")}</th>
            <th> {t("startRegistration")}</th>
            <th> {t("endRegistration")}</th>
            <th> {t("courseAvailible")}</th>
            <th> {t("classMembers")}</th>
            <th style={{ textAlign: "right" }}>
              <div style={{ marginRight: "20px" }}> {t("actions")}</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.classes?.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{item?.course?.name}</td>
              <td>{item?.course?.organization?.name || "Freelance"}</td>
              <td>{item?.name || "-"}</td>
              <td className={isExpired(item.startDate) ? "gray" : "green"}>
                <Moment format="DD MMMM YYYY">{item.startDate}</Moment>
              </td>
              <td className={isExpired(item.endDate) ? "gray" : "green"}>
                {item.endDate ? (
                  <Moment format="DD MMMM YYYY">{item.endDate}</Moment>
                ) : (
                  "-"
                )}
              </td>
              <td className={isExpired(item.startClassDate) ? "gray" : "green"}>
                {item.startClassDate ? (
                  <Moment format="DD MMMM YYYY">{item.startClassDate}</Moment>
                ) : (
                  "-"
                )}
              </td>
              <td>{item.member ? item.member : "Unlimited"}</td>

              <td style={{ width: "500px" }}>
                <ActionButton>
                  <Button
                    onClick={() => navigate(`/dashboard/class/${item.id}`)}
                    variant="success"
                    style={{ margin: "0px 5px" }}
                  >
                    <FaRegEye /> <span>{t("view")}</span>
                  </Button>
                  <Button
                    onClick={() => onHandleEditClass(item)}
                    variant="warning"
                    style={{ margin: "0px 5px" }}
                  >
                    <MdOutlineEdit />
                    <span>{t("edit")}</span>
                  </Button>
                  <Button
                    onClick={() => onhdanleDeleteClass(item)}
                    variant="danger"
                  >
                    <FaRegTrashAlt
                    // Replace handleDelete with your delete function
                    />{" "}
                    <span>{t("delete")}</span>
                  </Button>
                  {/* <MdOutlineEdit
                    onClick={() => onHandleEditClass(item)} // Replace handleEdit with your edit function
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  />
                  <FaRegTrashAlt
                    onClick={() => onhdanleDeleteClass(item)} // Replace handleDelete with your delete function
                    style={{ cursor: "pointer" }}
                  /> */}
                </ActionButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {/* {data && (
        <Pagination
          handlePageClick={(page) =>
            navigate(`/dashboard/classes?page=${page.selected + 1}`)
          }
          pageCount={data?.totalPages}
          currentPage={page > 0 ? page - 1 : 0}
        />
      )} */}
    </>
  );
}
export default CourseDashboard;
