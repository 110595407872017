import React, { useState } from "react";
import { Row, Col, ListGroup, Table, Button, Modal } from "react-bootstrap";

import { FormGroup, FormLabel, FormControl } from "react-bootstrap";
import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";
import { FaRegTrashAlt } from "react-icons/fa";
import Moment from "react-moment";
import { MdOutlineEdit } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import Pagination from "../../../components/common/pagination/pagination";
import ContentDashboard from "../../../layout/layer/Layer";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";

import "react-datepicker/dist/react-datepicker.css";
const isExpired = (date) => {
  const currentDate = new Date();
  return currentDate > new Date(date);
};

export default function ClassesDahboard() {
  const [form, setForm] = React.useState(false);
  const [course, setCourse] = React.useState(null);
  const handleCourse = (course) => {
    console.log(course);
    setCourse(course);
  };
  const handleAddedClass = () => {
    setForm(false);
  };
  return (
    <Wrapper>
      <Header />
      <div className="container-fluid">
        <section className=" po-rel-z1 pt-130 pb-145">
          <SidebarTeacher />
          <ContentDashboard>
            <>
              {form ? (
                <>
                  <h2>New Class</h2>
                  <Button
                    className="e-btn"
                    style={{ margin: "10px 0px" }}
                    onClick={() => setForm(false)}
                  >
                    Go Back
                  </Button>

                  <SearchCourse getCourse={handleCourse} />
                  {course && (
                    <CreateClass
                      courseId={course.id}
                      handleAddedClass={handleAddedClass}
                    />
                  )}
                </>
              ) : (
                <>
                  <h2>My Classes</h2>
                  <Row>
                    <Col md="6" style={{ float: "right" }}>
                      <Button
                        className="e-btn"
                        style={{ margin: "10px 0px" }}
                        onClick={() => setForm(true)}
                      >
                        Add Class
                      </Button>
                    </Col>

                    <Col md="6" style={{ float: "left" }}>
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Search by course"
                      />
                    </Col>
                  </Row>
                  <ClassTable />
                </>
              )}
            </>
          </ContentDashboard>
        </section>
      </div>
    </Wrapper>
  );
}

function ClassTable() {
  const navigate = useNavigate();
  const handleEdit = () => {};

  const handleDelete = () => {};
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page");

  const { isLoading, isError, data, error, refetch } = useQuery(
    ["my-classes", page],
    () => fetchClasses(page)
  );

  async function fetchClasses(page) {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/my-classes?page=${page || 1}`,
      config
    );
    return response.data;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    console.log(error);
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Table responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Course</th>
            <th>Owner</th>
            <th>Formation</th>
            <th>Start registration</th>
            <th>End registration</th>
            <th>Course Availible</th>

            <th>Class Members</th>
            <th style={{ textAlign: "right" }}>
              <div style={{ marginRight: "20px" }}>Actions</div>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.classes?.map((item, index) => (
            <tr>
              <td>{index + 1}</td>
              <td>{item?.course?.name}</td>
              <td>{item?.course?.organization?.name || "Freelance"}</td>
              <td>{item?.name || "-"}</td>
              <td className={isExpired(item.startDate) ? "gray" : "green"}>
                <Moment format="DD MMMM YYYY">{item.startDate}</Moment>
              </td>
              <td className={isExpired(item.endDate) ? "gray" : "green"}>
                {item.endDate ? (
                  <Moment format="DD MMMM YYYY">{item.endDate}</Moment>
                ) : (
                  "-"
                )}
              </td>
              <td className={isExpired(item.startClassDate) ? "gray" : "green"}>
                {item.startClassDate ? (
                  <Moment format="DD MMMM YYYY">{item.startClassDate}</Moment>
                ) : (
                  "-"
                )}
              </td>
              <td>{item.member ? item.member : "Unlimited"}</td>

              <td>
                <div style={{ textAlign: "right", marginRight: "20px" }}>
                  <FaRegEye
                    onClick={() => navigate(`/dashboard/class/${item.id}`)} // Replace handleEdit with your edit function
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  />
                  <MdOutlineEdit
                    onClick={() => handleEdit(item.id)} // Replace handleEdit with your edit function
                    style={{ cursor: "pointer", marginRight: "10px" }}
                  />
                  <FaRegTrashAlt
                    onClick={() => handleDelete(item.id)} // Replace handleDelete with your delete function
                    style={{ cursor: "pointer" }}
                  />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {data && (
        <Pagination
          handlePageClick={(page) =>
            navigate(`/dashboard/classes?page=${page.selected + 1}`)
          }
          pageCount={data?.totalPages}
          currentPage={page > 0 ? page - 1 : 0}
        />
      )}
    </>
  );
}

const validationSchema = Yup.object().shape({
  startDate: Yup.date().required("Start Date is required"),
  startClassDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().when("deadline", {
    is: true,
    then: Yup.date().required("End Date is required"),
    otherwise: Yup.date().notRequired(),
  }),
  members: Yup.number().when("limitMembers", {
    is: true,
    then: Yup.number()
      .min(1, "Members should be more than 0")
      .required("Members are required"),
    otherwise: Yup.number().notRequired(),
  }),
});

const CreateClass = ({ courseId, handleAddedClass }) => {
  const initialValues = {
    name: null,
    startDate: new Date(),
    startClassDate: new Date(),
    endDate: "",
    deadline: false,
    limitMembers: false,
    members: 1,
  };

  const handleSubmit = async (values) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      };
      alert(courseId);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/class`,
        { ...values, courseId },
        config
      ); // Replace 'your_api_endpoint' with your actual API URL
      console.log(response.data); // Log the response data if needed

      // Display success message using SweetAlert upon successful request
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Your data has been submitted successfully.",
      }).then(() => {
        handleAddedClass();
      });
    } catch (error) {
      // Handle error case
      console.error("Error submitting data:", error);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleChange, values, errors, touched, setFieldValue }) => (
          <Form>
            <Row>
              <Col md="12">
                <FormGroup>
                  <FormLabel>Name of class (optional)</FormLabel>
                  <Field
                    name="name"
                    type="name"
                    as={FormControl}
                    isInvalid={errors.name && touched.name}
                  />
                  <FormControl.Feedback type="invalid">
                    {errors.name}
                  </FormControl.Feedback>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <FormLabel>Start Date (Registration)*</FormLabel>
                  <DatePicker
                    name="startDate"
                    selected={values.startDate}
                    onChange={(date) => {
                      setFieldValue("startDate", date);
                      setFieldValue("startClassDate", date);
                      if (values.deadline) {
                        let newEndDate = new Date(
                          date.getTime() + 24 * 60 * 60 * 1000
                        ); // Set endDate as startDate + 1 day
                        // Check if endDate is less than startDate, update it to startDate if necessary
                        if (values.endDate && newEndDate < values.endDate) {
                          newEndDate = date;
                        }
                        setFieldValue("endDate", newEndDate);
                      }
                    }}
                    dateFormat="dd MMMM yyyy"
                    className="form-control"
                    style={{ width: "100%" }} // Add any required class for styling
                  />
                  <FormControl.Feedback type="invalid">
                    {errors.startDate}
                  </FormControl.Feedback>{" "}
                  <small className="text-muted">
                    This is the date when the class will become available for
                    enrollment.
                  </small>
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <FormLabel>Class Start on*</FormLabel>
                  <DatePicker
                    name="startClassDate"
                    selected={values.startClassDate}
                    onChange={(date) => {
                      setFieldValue("startClassDate", date);
                    }}
                    dateFormat="dd MMMM yyyy"
                    className="form-control" // Add any required class for styling
                  />
                  <FormControl.Feedback type="invalid">
                    {errors.startClassDate}
                  </FormControl.Feedback>{" "}
                  <small className="text-muted">
                    The attachement course will be availble for students
                  </small>
                </FormGroup>
              </Col>
              <Col md="6">
                {" "}
                <FormGroup>
                  <FormLabel style={{ marginLeft: "10px", paddingTop: "8px" }}>
                    Enrolled deadline
                  </FormLabel>
                  <Field
                    name="deadline"
                    type="checkbox"
                    style={{ width: "16px", float: "left" }}
                    onChange={(e) => {
                      handleChange(e);
                      if (e.target.checked) {
                        const newEndDate = new Date(
                          values.startDate.getTime() + 24 * 60 * 60 * 1000
                        );
                        if (
                          values.endDate &&
                          values.startDate > values.endDate
                        ) {
                          setFieldValue("endDate", newEndDate);
                        }
                      }
                    }}
                    checked={values.deadline}
                  />
                </FormGroup>
                {values.deadline ? (
                  <FormGroup>
                    <FormLabel>End Date</FormLabel>

                    <DatePicker
                      name="endDate"
                      selected={values.endDate}
                      onChange={(date) => setFieldValue("endDate", date)}
                      minDate={
                        values.startDate
                          ? new Date(
                              values.startDate.getTime() + 24 * 60 * 60 * 1000
                            )
                          : null
                      }
                      dateFormat="dd MMMM yyyy"
                      className="form-control" // Add any required class for styling
                    />

                    <FormControl.Feedback type="invalid">
                      {errors.endDate}
                    </FormControl.Feedback>
                  </FormGroup>
                ) : null}
              </Col>

              <Col md="6">
                <FormGroup>
                  <FormLabel style={{ marginLeft: "10px", paddingTop: "8px" }}>
                    Limit student for this class
                  </FormLabel>
                  <Field
                    name="limitMembers"
                    type="checkbox"
                    style={{ width: "16px", float: "left" }}
                    onChange={handleChange}
                    checked={values.limitMembers}
                  />
                </FormGroup>

                {values.limitMembers && (
                  <FormGroup>
                    <FormLabel>Members *</FormLabel>
                    <Field
                      name="members"
                      type="number"
                      as={FormControl}
                      isInvalid={errors.members && touched.members}
                    />
                    <FormControl.Feedback type="invalid">
                      {errors.members}
                    </FormControl.Feedback>
                  </FormGroup>
                )}
              </Col>
            </Row>
            <Button
              type="submit"
              className="e-btn"
              style={{ marginTop: "20px" }}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
};
function SearchCourse({ getCourse }) {
  const [course, setCourse] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [searchResults, setSearchResults] = React.useState([]);
  React.useEffect(() => {
    let typingTimeout;

    if (searchTerm) {
      setloading(false);
      typingTimeout = setTimeout(() => {
        handleSearch();
      }, 1000); // 2000 milliseconds = 2 seconds
    }

    return () => {
      clearTimeout(typingTimeout);
    };
  }, [searchTerm]);

  const handleSearch = async () => {
    try {
      setloading(true);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      };
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/course-search?searchTerm=${searchTerm}`,
        config
      );
      console.log(response.data);
      setloading(false);

      setSearchResults(response.data.courses);
    } catch (error) {
      // Handle error
    }
  };
  return (
    <Col md="6" style={{ position: "relative" }}>
      {course ? (
        <>
          <h2>Create Class for {course.name}</h2>
          <ListGroup>
            <ListGroup.Item
              className="list-group-item-hover"
              style={{
                backgroundColor: "#F3F4F8",
                position: "relative",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={course.image}
                  alt={course.name}
                  style={{ width: "70px", marginRight: "15px" }}
                />
                <h5 style={{ margin: "0" }}>{course.name}</h5>
              </div>

              <FaRegTrashAlt
                style={{
                  position: "absolute",
                  right: "15px",
                  top: "20px",
                }}
                onClick={() => {
                  getCourse();
                  setCourse(null);
                }}
              />
            </ListGroup.Item>
          </ListGroup>
        </>
      ) : (
        <FormGroup>
          <FormLabel>Search *</FormLabel>
          <FormControl
            name="search"
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <FormControl.Feedback type="invalid">
            {/* Display any error messages here */}
          </FormControl.Feedback>
          {/* Display search results */}
          <div className="search-results">
            {loading && "loading..."}
            <ListGroup
              style={{
                position: "absolute",
                top: "110px",
                left: 0,
                right: 0,
                zIndex: 100,
              }}
            >
              {searchResults.map((result) => (
                <ListGroup.Item
                  key={result.id}
                  className="list-group-item-hover"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#F3F4F8",
                  }}
                  onClick={() => {
                    setSearchResults([]);
                    setCourse(result);
                    getCourse(result);
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={result.image}
                      alt={result.name}
                      style={{ width: "70px", marginRight: "15px" }}
                    />
                    <h5 style={{ margin: "0" }}>{result.name}</h5>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
            {/* 
                    {!showMore && (
                      <Button variant="primary" onClick={handleShowMore}>
                        Show More
                      </Button>
                    )} */}
          </div>
        </FormGroup>
      )}
    </Col>
  );
}
