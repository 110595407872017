import React, { ReactElement } from "react";
// import Steps from "rc-steps";
import { Card, Container, Row, Col, Form, Button } from "react-bootstrap";
import { FiCheck, FiLayers, FiPlus, FiPrinter, FiSend } from "react-icons/fi";
import Moment from "react-moment";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { Avatar } from "../../../../components/common/avatar/avatar";
import styled from "styled-components";
import Wrapper from "../../../../layout/wrapper";
import Header from "../../../../layout/headers/header";
import SidebarTeacher from "../../../../components/common/Sidebar/SidebarTeacher";
import ContentDashboard from "../../../../layout/layer/Layer";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { MdOutlineAdminPanelSettings } from "react-icons/md";

export default function Ticket() {
  const { t } = useTranslation();
  const [ticket, setTicket] = React.useState({
    status: 0,
    owner: {
      fullname: "ismail hocine",
    },
  });
  const [comment, setComment] = React.useState("");
  const [loading, setloading] = React.useState(false);
  const [search] = useSearchParams();

  async function fetchTicket() {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    };

    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/ticket/${search.get("id")}`,
      config
    );
    setTicket(response.data);
  }
  React.useEffect(() => {
    fetchTicket();
  }, []);

  const onCreateComment = async () => {
    if (comment.length === 0) return;
    setloading(true);
    const options = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`, // Attach the token as a Bearer token
      },
    };
    await axios
      .post(
        `${process.env.REACT_APP_API_URL}/ticket/comment/${search.get("id")}`,
        {
          comment,
        },
        options
      )
      .then((res) => {
        const newComment = {
          owner: {
            fullname: window.localStorage.getItem("fullname"),
            id: window.localStorage.getItem("id"),
          },
          comment: comment,
        };
        const updatedTicket = ticket;
        updatedTicket.comments.push(newComment);
        setTicket(updatedTicket);
        setComment("");
        setloading(false);
      })
      .catch((error) => {
        alert("Something wrong");
        setloading(false);
        return;
      });
  };

  //render UI section

  if (!search.get("id")) return <span>yesy</span>;

  return (
    <>
      <Wrapper>
        {/* <SEO pageTitle={"CoursePayment"} /> */}
        <Header />
        <SidebarTeacher />{" "}
        <section className=" po-rel-z1 pt-130 pb-145">
          <ContentDashboard>
            <Row>
              <Col md={4}>
                <Card style={{ backgroundColor: "red !important !important" }}>
                  <Card.Body>
                    <InformationHeaderTicket>
                      <SupportBadge status={ticket?.status}>
                        {ticket?.status}
                      </SupportBadge>
                      <div style={{ marginTop: "10px" }}>#</div>
                    </InformationHeaderTicket>
                    <StyledHR />

                    <WrapperTicket>
                      <AvatarWrapper>
                        <Avatar
                          size={50}
                          src={
                            ticket.owner.avatar
                              ? `${
                                  process.env.REACT_APP_DOMAIN_BACKEND +
                                  ticket.owner.avatar.url
                                }`
                              : "https://avatars.hsoubcdn.com/default?s=128"
                          }
                        />
                      </AvatarWrapper>
                      <ContentWrapper>
                        <OwnerName rtl={t("lang") === "ar"}>
                          {ticket?.owner?.fullname}
                        </OwnerName>
                        <List>
                          <ListItem rtl={t("lang") === "ar"}>
                            <b>
                              <FiLayers size={18} color="#9CA3AF" />
                              {ticket?.owner.title}
                            </b>
                          </ListItem>
                        </List>
                      </ContentWrapper>
                    </WrapperTicket>
                    <List style={{ marginTop: "20px" }}>
                      {/* <ListItem2>
                        <b>Status:</b> Status{ticket?.Status}
                      </ListItem2> */}
                      <ListItem2>
                        <b>Priority:</b> {ticket?.priority}
                      </ListItem2>
                      <ListItem2>
                        <b>Date:</b>{" "}
                        <Moment format="DD/MM/YYYY hh:mm">
                          {ticket?.createdAt || new Date()}
                        </Moment>
                      </ListItem2>
                    </List>
                  </Card.Body>
                </Card>
              </Col>

              <Col md={8}>
                <Card>
                  <Card.Body style={{ position: "relative" }}>
                    {/* <StyledLine /> */}
                    <InformationHeaderTicket>
                      <div>{ticket.subject}</div>
                      <div>
                        <Button className={`support-btn-icon-square-white`}>
                          <FiPrinter size={20} color="#4B5563" />
                        </Button>
                      </div>
                    </InformationHeaderTicket>
                    <StyledHR />

                    <WrapperTicket>
                      <AvatarWrapper>
                        <Avatar
                          size={50}
                          src={
                            ticket.owner.avatar
                              ? `${
                                  process.env.REACT_APP_DOMAIN_BACKEND +
                                  ticket.owner.avatar.url
                                }`
                              : "https://avatars.hsoubcdn.com/default?s=128"
                          }
                        />
                      </AvatarWrapper>
                      <ContentWrapper style={{ margin: "0px 20px" }}>
                        <OwnerName rtl={t("lang") === "ar"}>
                          {ticket?.owner?.fullname}
                        </OwnerName>
                        <p
                          style={{
                            color: "#4B5563",
                            fontSize: "1rem",
                            margin: "0px 9px",
                          }}
                        >
                          {parse(`<div>${ticket?.issue}</div>`)}
                        </p>
                        <div
                          style={{
                            color: "#9CA2AC",
                            fontSize: "12px",
                            margin: "0px 9px",
                          }}
                        >
                          <Moment fromNow>{ticket?.createdAt}</Moment>
                        </div>
                      </ContentWrapper>
                    </WrapperTicket>

                    {ticket?.comments?.map((item) => {
                      if (!item.owner) {
                        return (
                          <WrapperTicket style={{ margin: "30px 0px" }}>
                            <div
                              style={{
                                flex: "0 0 45px",
                                paddingLeft: "20px",
                                textAlign: "center",
                              }}
                            >
                              <div
                                style={{
                                  display: "block",
                                  border: "1px solid #EFEFEF",
                                  padding: "5px",
                                  width: "40px",
                                  height: "40px",
                                  borderRadius: "100%",
                                  marginRight: "17px",
                                  marginTop: "10px",
                                  backgroundColor: "white",
                                }}
                              >
                                <MdOutlineAdminPanelSettings
                                  size={18}
                                  color="#9CA3AF"
                                />
                              </div>
                            </div>
                            <div style={{ flex: 1, paddingLeft: 2 }}>
                              <Card>
                                <Card.Body>
                                  <p style={{ fontSize: "1rem" }}>
                                    {item.comment}
                                    <br />
                                    <span
                                      style={{
                                        color: "#9CA2AC",
                                        fontSize: "12px",
                                      }}
                                    >
                                      <Moment fromNow>{item?.createdAt}</Moment>
                                    </span>
                                  </p>
                                </Card.Body>
                              </Card>
                            </div>
                          </WrapperTicket>
                        );
                      } else {
                        return (
                          <WrapperTicket style={{ margin: "30px 0px" }}>
                            <AvatarWrapper>
                              <Avatar
                                size={50}
                                src={
                                  item.owner.avatar
                                    ? `${
                                        process.env.REACT_APP_DOMAIN_BACKEND +
                                        item.owner.avatar.url
                                      }`
                                    : "https://avatars.hsoubcdn.com/default?s=128"
                                }
                              />
                            </AvatarWrapper>
                            <ContentWrapper style={{ margin: "0px 20px" }}>
                              <OwnerName rtl={t("lang") === "ar"}>
                                {item?.owner?.fullname}
                              </OwnerName>
                              <p
                                style={{
                                  color: "#4B5563",
                                  fontSize: "1rem",
                                  margin: "0px 9px",
                                }}
                              >
                                {parse(`<div>${item?.comment}</div>`)}
                              </p>
                              <div
                                style={{
                                  color: "#9CA2AC",
                                  fontSize: "12px",
                                  margin: "0px 9px",
                                }}
                              >
                                <Moment fromNow>{item?.createdAt}</Moment>
                              </div>
                            </ContentWrapper>
                          </WrapperTicket>
                        );
                      }
                    })}

                    <div style={{ flex: 1, paddingLeft: 2 }}>
                      <Card>
                        <Card.Body>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            style={{ minHeight: "150px !important" }}
                            placeholder="اكتب تعليقك"
                            // className="input-height-meduim"
                            name="newComment"
                            value={comment}
                            onChange={(event) => setComment(event.target.value)}
                          />
                          <Button
                            onClick={() => onCreateComment()}
                            style={{
                              // width: "50px",
                              height: "50px",
                              margin: "20px 0px",
                              // position: "absolute",
                              // zIndex: 2,
                              // bottom: "35px",
                            }}
                            disabled={comment.length === 0}
                          >
                            Submit <FiSend size={24} color="white" />
                          </Button>
                        </Card.Body>
                      </Card>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </ContentDashboard>
        </section>
      </Wrapper>
    </>
  );
}

const InformationHeaderTicket = styled.div`
  height: 35px;
  display: grid;
  grid-template-columns: auto 50px;
`;

const SupportBadge = styled.div`
  text-align: center;
  width: 80px;
  height: 40px;
  border-radius: 10px;
  padding-top: 5px;
  margin-top: 5px;
  color: white;
  background-color: ${(props) =>
    props.status === "open"
      ? "#1bbd8c"
      : props.status === "proccess"
      ? "#9ca2ac"
      : "#f18a90"};
`;
const StyledHR = styled.hr`
  color: #f6f6f6;
`;

const WrapperTicket = styled.div`
  display: flex;
  flex-direction: row;
  /* marginTop: 12px; */
  margin-right: 3px;
  position: relative;
`;

const AvatarWrapper = styled.div`
  flex: 0 0 45px;
  padding-left: 15px;
`;

const ContentWrapper = styled.div`
  flex: 1;
  padding-left: 2px;
`;

const OwnerName = styled.div`
  font-size: 16px;
  color: #258cd7;
  font-weight: bold;
  ${({ rtl }) => rtl && "margin-right: 9px;"}
  ${({ rtl }) => !rtl && "margin-left: 9px;"}
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
`;

const ListItem = styled.li`
  font-size: 12px;
  display: inline;
  margin-right: 0;
  color: #4b5563;
  ${({ rtl }) => rtl && "margin-right: 9px;"}
  ${({ rtl }) => !rtl && "margin-left: 9px;"}
`;
const ListItem2 = styled.li`
  font-size: 16px;
  display: block;
  margin-right: 0;
  color: #4b5563;
  margin-top: 15px;
  ${({ rtl }) => rtl && "margin-right: 9px;"}
  ${({ rtl }) => !rtl && "margin-left: 9px;"}
`;

const StyledLine = styled.div`
  position: absolute;
  width: 2px;
  top: 100px;
  bottom: 100px;
  left: 55px;
  z-index: 100;
  background-color: #fff;
`;
