import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Pagination from "../pagination/pagination";
import {
  add_to_wishlist,
  wishlistItems,
} from "../../../redux/features/wishlist-slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

export default function CourseItem({ course, list, sidebar, smallDisplay }) {
  const dispatch = useDispatch();
  const wishlists = useSelector(wishlistItems);

  return (
    <Col
      md={
        list && !sidebar
          ? "12"
          : list && sidebar
          ? "12"
          : !list && sidebar
          ? "6"
          : "6"
      }
      //   xxl={"12"}
      xl={
        list && !sidebar
          ? "12"
          : list && sidebar
          ? "12"
          : !list && sidebar
          ? "6"
          : smallDisplay
          ? "3"
          : "4"
      }
      lg={
        list && !sidebar
          ? "6"
          : list && sidebar
          ? "12"
          : !list && sidebar
          ? "6"
          : smallDisplay
          ? "3"
          : "4"
      }
    >
      <div className="course__item list white-bg mb-30 fix">
        <Row className="gx-0">
          <Col
            md={list ? "4" : "12"}
            xxl={list ? "4" : "12"}
            xl={list ? "4" : "12"}
            lg={list ? "4" : "12"}
          >
            <div className="course__thumb course__thumb-list w-img p-relative fix">
              <Link to={`/page/course-details/${course?.name}/${course?.id}`}>
                <img
                  src={
                    course?.image
                      ? process.env.REACT_APP_DOMAIN_BACKEND + course?.image
                      : "https://avatars.hsoubcdn.com/default?s=128"
                  }
                  alt=""
                  style={{ objectFit: "contain", height: "200px" }}
                />
              </Link>
              <div
                // onClick={() => dispatch(add_to_wishlist(course))}
                className={`heart_icon ${
                  wishlists.findIndex((i) => i.id === course?.id) >= 0
                    ? "active"
                    : ""
                }`}
              >
                <i className="fas fa-heart"></i>
              </div>
              <div className="course__tag">
                <a href="#" className={course?.color}>
                  {course?.category?.name}
                </a>
              </div>
            </div>
          </Col>
          <Col
            md={list ? "8" : "12"}
            xxl={list ? "8" : "12"}
            xl={list ? "8" : "12"}
            lg={list ? "8" : "12"}
          >
            <div className="course__right">
              <div
                className={`course__content ${
                  list ? "course__content-4" : "course__content-2"
                }`}
              >
                <div className="course__meta d-flex align-items-center">
                  <div className="course__lesson">
                    <span>
                      <i className="far fa-book-alt"></i>
                      {course?.total_lectures} Lesson
                    </span>
                  </div>
                  <div className="course__rating">
                    <span style={{ marginLeft: "10px" }}>
                      <i className="icon_star"></i>
                      {course?.rating} ({course?.total_rating})
                    </span>
                  </div>
                </div>
                <h3
                  className={`course__title ${!list ? "" : "course__title-3"}`}
                >
                  <Link
                    to={`/page/course-details/${course?.name}/${course?.id}`}
                  >
                    {course?.name}
                  </Link>
                </h3>
                {!smallDisplay && (
                  <div className="course__summary">
                    <CourseOverview>{course?.overview}</CourseOverview>
                  </div>
                )}

                <div className="course__teacher d-flex align-items-center">
                  <div className="course__teacher-thumb mr-15">
                    <img
                      src={
                        course?.instructor?.avatar?.url
                          ? process.env.REACT_APP_DOMAIN_BACKEND +
                            course?.instructor?.avatar?.url
                          : "https://avatars.hsoubcdn.com/default?s=128"
                      }
                      alt=""
                    />
                  </div>
                  <h6>
                    <Link to={`/profile/${course?.instructor.username}`}>
                      {course?.instructor.fullname}
                    </Link>
                  </h6>
                </div>
              </div>
              <div
                className={`course__more course__more-2 ${
                  !list ? "course__more-3" : ""
                }
                   d-flex justify-content-between align-items-center`}
              >
                {course?.price === "free" && (
                  <div className="course__status">
                    <span>Free</span>
                  </div>
                )}
                {course?.price !== "free" && (
                  <div className="course__status d-flex ">
                    <span className={course?.color}>
                      DA{" "}
                      {course?.discount_price
                        ? course?.discount_price.toFixed(2)
                        : course?.price.toFixed(2)}
                    </span>
                    <br />
                    {course?.discount_price && (
                      <span className="old-price">
                        DA {course?.price.toFixed(2)}
                      </span>
                    )}
                  </div>
                )}
                <div className="course__btn">
                  <Link
                    to={`/page/course-details/${course?.name}/${course?.id}`}
                    className="link-btn"
                  >
                    {smallDisplay ? "Join" : "Details"}

                    <i className="far fa-arrow-right"></i>
                    <i className="far fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Col>
  );
}
const CourseOverview = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limits the text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Displays ellipsis (...) if the content overflows */
`;
