import React from "react";
import { Link } from "react-router-dom";
import { menu_data } from "../../data";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { TbBrandGoogleAnalytics } from "react-icons/tb";
const NavMenu = () => {
  const { user } = useSelector((state) => state.user);
  const { t } = useTranslation();
  return (
    <>
      <ul>
        {!user.isSignedIn && (
          <li style={{ margin: "0px 10px" }}>
            <Link to={`/`}>{t("home")}</Link>
          </li>
        )}
        {user.isSignedIn && (
          <li style={{ margin: "0px 10px" }}>
            <Link to={`/dashboard`}>{t("dashboard")} </Link>{" "}
          </li>
        )}
        <li style={{ margin: "0px 10px" }}>
          <Link to={`/page/courses`}>{t("courses")} </Link>{" "}
        </li>

        <li style={{ margin: "0px 10px" }}>
          <Link to={`/support`}>{t("support")} </Link>{" "}
        </li>
        {!user.isSignedIn && (
          <li style={{ margin: "0px 10px" }}>
            <Link to={`/about`}>{t("about")} </Link>{" "}
          </li>
        )}
        {/* {user.isSignedIn && (
          <li style={{ margin: "0px 10px" }}>
            <Link to={`/dashboard`}>dashboard</Link>{" "}
          </li>
        )} */}

        {/* <ul className="submenu">
               
                <li key={menu.id}>
                  <Link to={`${menu.link}`}>{menu.title}</Link>
                </li>
            
            </ul> */}

        {/* {menu_data.map((link, index) => (
          <li key={index} className={link.submenu ? "has-dropdown" : ""}>
            <Link to={`${link.link}`} style={{ marginRight: "10px" }}>
              {link.title}
            </Link>
            {link.submenu && (
              <ul className="submenu">
                {link.submenu.map((menu) => (
                  <li key={menu.id}>
                    <Link to={`${menu.link}`}>{menu.title}</Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))} */}
      </ul>
    </>
  );
};

export default NavMenu;
