import React, { useState } from "react";

import SidebarTeacher from "../../../components/common/Sidebar/SidebarTeacher";

import { useSearchParams, useNavigate, Link } from "react-router-dom";

import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";

import ContentDashboard from "../../../layout/layer/Layer";
import { useTranslation } from "react-i18next";

import { Row, Col, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Moment from "react-moment";
import { fetchNotifications } from "../../../components/Notification";
import { setNotifications } from "../../../redux/features/notification-slice";
import { FiClock } from "react-icons/fi";
import { Avatar } from "../../../components/common/avatar/avatar";

export default function NotificationDashboard() {
  const { t } = useTranslation();
  const { notifications } = useSelector((state) => state.notification);
  return (
    <Wrapper>
      <Header />
      <section className=" po-rel-z1 pt-30 pb-145">
        <SidebarTeacher />
        <ContentDashboard>
          <h2>{t("Notifications")}</h2>
          <Row>
            <Col>
              <NotificationList>
                {notifications.map((notification) => (
                  <Link
                    to={`${notification.notif_url}?notif_id=${notification.id}`}
                  >
                    <NotificationItem
                      key={notification.id}
                      seen={notification.seen_status}
                    >
                      <Avatar
                        size={35}
                        avatar={notification?.user?.avatar?.url}
                      />

                      <div>
                        <span
                          style={{
                            color: "#374151",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          {notification?.user?.fullname}:
                        </span>
                        <span
                          style={{ color: "#9CA3AF", fontSize: ".9375rem" }}
                        >
                          {notification.notif_text}
                        </span>
                        <br />
                        <FiClock size={13} />
                        <span style={{ fontSize: "12px" }}>
                          &nbsp;
                          <Moment format="HH:mm YY-MM-DD ">
                            {notification.createdAt}
                          </Moment>
                        </span>
                      </div>
                    </NotificationItem>
                  </Link>
                ))}

                <NextPage />
              </NotificationList>
            </Col>
          </Row>
        </ContentDashboard>
      </section>
    </Wrapper>
  );
}
//styled
const NextPage = () => {
  const { currentPage, totalPages } = useSelector(
    (state) => state.notification
  );
  const dispatch = useDispatch();
  const handleNextPage = async () => {
    if (localStorage.getItem("jwt")) {
      const result = await fetchNotifications(currentPage + 1);

      dispatch(
        setNotifications({
          notifications: result.notifications,
          total_count: result.total_count_Not_seen,
          totalPages: result.totalPages,
          currentPage: result.currentPage,
        })
      );
    }
  };
  if (totalPages === currentPage) return null;
  return <ShowMoreButton onClick={handleNextPage}>Show More</ShowMoreButton>;
};
const NotificationList = styled.div`
  z-index: 1000;
  background-color: white;

  border-radius: 4px;
  padding: 0px;
  font-size: 14px;
`;

const NotificationItem = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  margin: 10px 0;
  padding: 20px;
  color: black;
  font-size: 14px;
  background-color: ${({ seen }) => (seen ? "white" : "#2386c821")};
  border: 1px solid #ccc;
  border-radius: 12px;
`;

const ShowMoreButton = styled(Button)`
  display: block;
  width: 100%;
  margin-top: 10px;
`;
