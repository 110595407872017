import { combineReducers } from "@reduxjs/toolkit";
import cartSlice from "./features/cart-slice";
import courseSlice from "./features/course-slice";
import headerSlice from "./features/header-slice";
import searchSlice from "./features/search-slice";
import wishlistSlice from "./features/wishlist-slice";
import userSlice from "./features/user-slice";
import socketSlice from "./features/socket-slice";
import notificationSlice from "./features/notification-slice";
import recorderSlice from "./features/recorder-slice";
import uploadFilesSlice from "./features/upload-slice";
import imageSlice from "./features/image-slice";
import ticketSlice from "./features/ticket-slice";

const rootReducer = combineReducers({
  courses: courseSlice,
  search: searchSlice,
  cart: cartSlice,
  wishlist: wishlistSlice,
  header: headerSlice,
  user: userSlice,
  socket: socketSlice,
  notification: notificationSlice,
  recorder: recorderSlice,
  upload: uploadFilesSlice,
  image: imageSlice,
  tickets: ticketSlice,
});

export default rootReducer;
