import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";

import ErrorMsg from "./error-msg";
import { loginSchema } from "./validation-schema";
import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { auth_user } from "../../../redux/features/user-slice";
import { useTranslation } from "react-i18next";
import { Alert, Button } from "react-bootstrap";
import Swal from "sweetalert2";

const LoginForm = () => {
  // contact form
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setloading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState(null);
  const handleOnSubmit = (values, { resetForm }) => {
    // alert(`${values.email + "\n" + values.password}`);
    login({ email: values.email, password: values.password });
    setloading(true);
    resetForm();
  };
  function login({ email, password }) {
    const data = {
      identifier: email,
      password: password,
    };
    axios
      .post(` ${process.env.REACT_APP_API_URL}/auth/local/`, data)
      .then((res) => {
        localStorage.setItem("jwt", res.data.jwt);
        localStorage.setItem("username", res.data.user.username);
        localStorage.setItem("fullname", res.data.user.fullname);
        localStorage.setItem("isTeacher", res.data.user.isTeacher);
        dispatch(
          auth_user({
            user: {
              fullname: res.data.user.fullname,
              username: res.data.user.username,
              isTeacher: res.data.user.isTeacher,
              isSignedIn: true,
              avatar:
                process.env.REACT_APP_DOMAIN_BACKEND + res.data.user.avatar,
            },
          })
        );
        setloading(false);
        navigate("/");
      })
      .catch((error) => {
        // Handle errors from the server
        setloading(false);

        window.scrollTo(0, 0);
        // Check if the error response contains a message
        const errorMessage =
          error.response &&
          error.response.data &&
          error.response.data.error.message;
        if (
          error.response.data.error.message === "Invalid identifier or password"
        ) {
          setErrorMsg(t("errors.emailOrPasswordIncorrect"));
        }
        // Display error message to the user using Swal.fire
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: errorMessage || "An error occurred while creating the account.",
          setTimeout: 1000,
        });
      });
  }
  // use formik
  const { handleChange, handleSubmit, handleBlur, errors, values, touched } =
    useFormik({
      initialValues: { email: "", password: "" },
      validationSchema: loginSchema,
      onSubmit: handleOnSubmit,
    });
  return (
    <>
      {" "}
      {errorMsg && <Alert variant="danger">{errorMsg}</Alert>}
      <form onSubmit={handleSubmit}>
        <div className="sign__input-wrapper mb-25">
          <h5>{t("email")}</h5>
          <div className="sign__input">
            <input
              type="text"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={t("email")}
              name="email"
            />
            <i className="fal fa-envelope"></i>
          </div>
          {touched.email && <ErrorMsg error={errors.email} />}
        </div>
        <div className="sign__input-wrapper mb-10">
          <h5>{t("password")}</h5>
          <div className="sign__input">
            <input
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              name="password"
              type="text"
              placeholder={t("password")}
            />
            <i className="fal fa-lock"></i>
          </div>
          {touched.password && <ErrorMsg error={errors.password} />}
        </div>
        <div className="sign__action d-sm-flex justify-content-between mb-30">
          {/* <div className="sign__agree d-flex align-items-center">
          <input className="m-check-input" type="checkbox" id="m-agree" />
          <label className="m-check-label" htmlFor="m-agree">
            Keep me signed in
          </label>
        </div> */}
          <div className="sign__forgot">
            <Link to="/forget-password">{t("forgotPassword")}</Link>
          </div>
        </div>
        <Button type="submit" disabled={loading} style={{ width: "100%" }}>
          {loading ? <span class="loader"></span> : t("sign in")}
        </Button>

        <div className="sign__new text-center mt-20">
          <p>
            {t("newToEduAlgerSpace")} <Link to="/sign-up">{t("sign in")}</Link>
          </p>
        </div>
      </form>{" "}
    </>
  );
};

export default LoginForm;
