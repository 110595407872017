import Breadcrumb from "../../../components/common/breadcrumb/breadcrumb";
import CourseList from "../../../components/common/course/CourseList";

import SEO from "../../../components/seo";
import Footer from "../../../layout/footers/footer";

import Header from "../../../layout/headers/header";
import Wrapper from "../../../layout/wrapper";
import { JaaSMeeting, JitsiMeeting } from "@jitsi/react-sdk";

const MyEnrolledCourse = () => {
  return (
    <Wrapper>
      <SEO pageTitle={"Courses"} />
      <Header header_white={false} />
      <Breadcrumb
        pages={[{ name: "Home", path: "" }]}
        title={"Learning"}
        subtitle="My Learning"
        withoutImage={true}
      />
      {/* <JitsiMeeting
        //domain={YOUR_DOMAIN}
        roomName="PleaseUseAGoodRoomName"
        configOverwrite={{
          startWithAudioMuted: true,
          disableModeratorIndicator: true,
          startScreenSharing: true,
          enableEmailInStats: false,
        }}
        interfaceConfigOverwrite={{
          DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        }}
        userInfo={{
          displayName: "YOUR_USERNAME",
        }}
        onApiReady={(externalApi) => {
          // here you can attach custom event listeners to the Jitsi Meet External API
          // you can also store it locally to execute commands
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = "100vh";
        }}
      /> */}
      <div className="container" style={{ marginTop: "100px" }}>
        <CourseList path="courses/my-learning" subPage="page" sidebar={false} />
      </div>
      <Footer pt="pt-90" />
    </Wrapper>
  );
};

export default MyEnrolledCourse;
