"use client";
import styled from "styled-components";
import { color, layout, space } from "styled-system";

const Divider = styled.div`
  height: 1px;
  ${color}
  ${space}
  ${layout}
`;

export default Divider;
